import { useState } from "react";
import { HKMapComponent } from "../../HKMap/HKMapComponent";
import { HKMapData } from "../../HKMap/HKMapData";
import { HKMKeyData } from "../../HKMap/HKMKeyData";
import { HKMNoteData } from "../../HKMap/HKMNoteData";
import { useNoteName } from "../../Music/Accidentals/Accidentals";
import { NoteButtons } from "../KeyBuilderExercise/NoteButtons";
import '../KeyBuilderExercise/KeyBuilderExercise.css'
import React from "react";
import './MapBuilderExercise.css'
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase";

export class KeyBuilderExerciseState{
    visibleNotes:HKMNoteData[]
    visibleKeys:HKMKeyData[]
    message:string = 'aloha'
    lastAnswer:number = -1
    lastAnswerCorrect:boolean = false
    completed:boolean = false
    constructor(visibleKeys:HKMKeyData[], visibleNotes:HKMNoteData[]){
        this.visibleKeys = visibleKeys
        this.visibleNotes = visibleNotes
    }
}
export class MapBuilderExercise extends React.Component{
    tonic:number = 10
    selectedNoteIndex:number = -1
    rows:number = 3
    columns:number = 4 
    radius:number = 55
    mapData:HKMapData
    state:KeyBuilderExerciseState
    constructor(props:any){
        super(props)
        this.mapData = this.getMapData()
        this.state = this.getNewState()
    }
    getNewState = () => {
        return new KeyBuilderExerciseState(this.getVisibleKeys(), this.getVisibleNotes())
    }
    updateNotes = () => {
        this.setState({visibleNotes:this.getVisibleNotes()})
    }
    onKeyClick = (key:HKMKeyData) => {
        this.mapData.deselectAllKeys()
        if(key.selected || key.open){
            key.open = false 
            key.selected = false
        }
        else{
            key.open = true 
            key.selected = true
        }
        // this.mapData.defineAllNotesForKey(key.rowIndex, key.columnIndex)
        this.mapData.showNotesForOpenKeysOnly()
        this.setState({visibleNotes:this.getVisibleNotes(), visibleKeys:this.getVisibleKeys()})
    }
    onNoteClick = (note:HKMNoteData) => {
        if(!note.defined){
            this.selectedNoteIndex = note.noteIndex
            this.mapData.selectSingleNote(note.noteIndex)
            this.setState({visibleNotes:this.getVisibleNotes(), lastAnswer:-1})
        }
    }

    onWrongAnswer = (tonic:number) => {
        this.setState({lastAnswer:tonic, lastAnswerCorrect:false})
    }
    allNotesDefined = () => {
        let allDefinedNotes:HKMNoteData[] = this.mapData.notes.filter((note:HKMNoteData) => {
            return (note.defined === true)
        })
        return (allDefinedNotes.length === this.mapData.notes.length)
    }
    onRightAnswer = (tonic:number) => {
        let completed:boolean = false
        if(this.allNotesDefined()){
            this.mapData.deselectAllKeys()
            this.mapData.deselectAllNotes()
            completed = true
        }
        this.setState({completed:completed, lastAnswer:tonic, lastAnswerCorrect:true, visibleNotes:this.getVisibleNotes()})
    } 
    onButtonInput = (tonic:number) => {
        const selectedNote:HKMNoteData = this.mapData.notes[this.selectedNoteIndex]
        if(this.selectedNoteIndex === -1){
            return
        }
        if(selectedNote.tonic === tonic){
            selectedNote.defined = true
            selectedNote.selected = true
            this.onRightAnswer(tonic)
        }
        else{
            this.onWrongAnswer(tonic)
        }
    }
    getRandomTonic = () => {
        return Math.floor(Math.random() * 12)
    }
    getMapData = () => {
        let mapData:HKMapData = this.getFreshMapData()
        mapData.defineAllKeysAs(false)
        mapData.defineAllNotesAs(false)
        mapData.setSelectedKeys([[1,1]])
        mapData.defineAllNotesForKey(1,1)
        mapData.openKeys([[1,1]])
        mapData.showNotesForOpenKeysOnly()
        return mapData
    }

    getFreshMapData = () => {
        const tonic:number = this.getRandomTonic()
        let mapData:HKMapData = new HKMapData(this.rows, this.columns, tonic, this.radius, this.onKeyClick, this.onNoteClick)
        return mapData
    }

   getVisibleKeys = () => {return this.mapData.getVisibleKeys()}
   getVisibleNotes = () => {return this.mapData.getVisibleNotes()}

    onReset = (e:React.MouseEvent) => {
        e.stopPropagation()
        this.mapData = this.getMapData()
        this.updateNotes()
    }

    render = () =>{
            const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(this.radius, this.rows, this.columns)
            return <div className='map-builder-exercise centered'>
                <HKMapComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={this.state.visibleKeys} visibleNotes={this.state.visibleNotes}/>
                <NoteButtons onClick={this.onButtonInput}/>
                <Message completed={this.state.completed} correct={this.state.lastAnswerCorrect} tonic={this.state.lastAnswer}/>

                <p> </p>
                <p><button onClick={this.onReset} type='button'>Reset</button></p>
            </div>
    }     
}
export interface MessageProps{
    tonic:number
    correct:boolean
    completed:boolean
}
export function Message(props:any){
    const noteName:string = useNoteName(props.tonic)
    let message:string = ' - '
    if(props.tonic !== -1){
        const isCorrect:string = (props.correct) ? 'correct' : 'incorrect'
        message = noteName + ' is ' + isCorrect + '.'
    } 
    if(props.completed){
        message += "You've completed the HexMap!"
    }
    return <div className='message'>{message}</div>
}
export function HideShowButton(){

}