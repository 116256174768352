import { KeyBuilderBase } from "../../KeyBuilderWalkThrough/KeyBuilderBase"

export interface CenterHexProps{
    selectedNote:number
}
export function CenterHex(props:CenterHexProps){
    const selectedNote:number = (props.selectedNote > 6) ? 0 : props.selectedNote
    let definedNotes:number[] = []
    for(let i = 0; i <= props.selectedNote && i < 7; i++){
        definedNotes.push(i)
    }
    return  <div className='centered center-hex'>
                <KeyBuilderBase selectedNotes={[selectedNote]} definedNotes={definedNotes}/>
            </div> 
}