import { useState } from "react"
import { Music } from "../Music/Music"
import { HKMapComponent } from "./HKMapComponent"
import { HKMapData } from "./HKMapData"
import { HKMapWrapperBase } from "./HKMapWrapperBase"
import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteData } from "./HKMNoteData"

export interface HKMSingleKeyProps{
    tonic?:number
    radius?:number
    selectedNotes?:number[]
    hiddenNotes?:number[]
}
export function HKMSingleKey(props:HKMSingleKeyProps){
    const rows:number = 1
    const columns:number = 1
    const tonic:number = (props.tonic) ? props.tonic : Music.G 
    const radius:number = (props.radius) ? props.radius : 80
    const onKeyClick = (key:HKMKeyData) => {}
    const onNoteClick = (note:HKMNoteData) => {}
    let mapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
    let [visibleKeys, setVisibleKeys] = useState(mapData.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(mapData.getVisibleNotes())
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
            
    return <HKMapComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={visibleKeys} visibleNotes={visibleNotes}/>
}