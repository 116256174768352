import { useEffect, useState } from "react"
import { HKMKeyComponent } from "../../HKMap/HKMKeyComponent"
import { HKMKeyData } from "../../HKMap/HKMKeyData"
import { HKMNoteComp } from "../../HKMap/HKMNoteComp"
import { HKMNoteData } from "../../HKMap/HKMNoteData"
import { HKMapComponentProps } from '../../HKMap/HKMapComponentProps'
import { HKMNoteIntervalComp } from './HKMNoteIntervalComp'
import { HKMIntervalLabelComp } from "./HKMIntervalLabelComp"
import { HKMapComponent } from "../../HKMap/HKMapComponent"


export function HKIntervalComponent(props:HKMapComponentProps){
    let [keys, setKeys] = useState(props.visibleKeys)
    let [notes, setNotes] = useState(props.visibleNotes)
    let noteIntervalComps = notes.map((note, index)=>{
        return <HKMNoteIntervalComp key={index} noteData={note}/>
    })
    let intervalLabelComps = notes.map((note, index)=>{
        return <HKMIntervalLabelComp key={index} noteData={note}/>
    })
    useEffect(()=>{
        setKeys(props.visibleKeys)
        setNotes(props.visibleNotes)
    })
    return  <HKMapComponent visibleKeys={props.visibleKeys} 
                            visibleNotes={[]} 
                            compWidth={props.compWidth} 
                            compHeight={props.compHeight}>
                {noteIntervalComps}
                {intervalLabelComps}
            </HKMapComponent>
}