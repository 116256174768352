import { useState } from "react";
import { AccidentalToggle } from "../Accidentals/AccidentalToggle";
import { Login } from "../Login/Login";
import { HashRouter as Router, Route, Link, Routes, BrowserRouter } from 'react-router-dom'
import { TableOfContents } from "./TableOfContents";
import { CourseState } from "../Course/CourseState"
import { useCourseStore } from "../Course/CourseStore"
import { PositionNumberToggle } from "../PositionNumberToggle/PositionNumberToggle";
import { FiveFiveColumns } from "../Chapters/Layouts/TwoColumn";
import './TopNav.css'
import { FullChannel } from "../Chapters/Layouts/FullChannel";

export function TopNav(){
    const toggleTocIsOpen:Function = useCourseStore((state:CourseState)=>{
        return ()=>{
            state.toggleTocIsOpen(state)
        }
    })    
    const isTocOpen:Boolean = useCourseStore((state:CourseState)=>{
        return state.tocIsOpen
    })
    const onNavClick = (e:React.MouseEvent) => {
        e.stopPropagation()
        toggleTocIsOpen()
    }
    const getClassName = () => {
        let classes:string[] = ['toc-toggle', 'bold', 'row']
        if(isTocOpen){
            classes.push('open')
        }
        return classes.join(' ')
    }

    return  <>
                
                <div className='top-nav'>
                    <div className='top-nav-inner'>
                        <h1>HexKey Musical Geometry <sup className='tm'>TM</sup></h1>
                        <FiveFiveColumns>
                            <div className={getClassName()} onClick={onNavClick}>
                                <FullChannel>Table of Contents</FullChannel>
                                <FullChannel><span className='arrow'></span></FullChannel>
                            </div>
                            <div className="right"><AccidentalToggle/> <Login/></div>
                        </FiveFiveColumns>
                        </div>
                     
                    {/*<PositionNumberToggle/>*/}
                    
                </div>
                <TableOfContents/>
            </>

}