import '../../Chapters/chapters.css'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { Link } from 'react-router-dom'
import { MusicalAlphabet } from '../KeyBuilderExercise/MusicalAlphabet'
import { KeyBuilderBase } from '../../KeyBuilderWalkThrough/KeyBuilderBase'
import { IntervalBase } from '../../KeyBuilderWalkThrough/IntervalBase'
import { SequenceBase } from '../../KeyBuilderWalkThrough/SequenceBase'
import { useNoteName } from '../../Music/Accidentals/Accidentals'
import { ChordBuilderBase } from '../../KeyBuilderWalkThrough/ChordBuilderBase'
import { ChordIntervalBase } from '../../KeyBuilderWalkThrough/ChordIntervalBase'
import { HKMKeyChordHelper } from '../../HKMap/HKMKeyChordHelper'
import { KeyBuilderExercise } from './KeyBuilderExercise'
import { KeyBuilderHelpers } from '../../KeyBuilder'
import { Helpers } from './Helpers'
import { BaseRowBlock } from '../../Chapters/Layouts/TwoColumn'
import { SideBarBlock } from '../../Chapters/Layouts/SideBarBlock'
import { FullChannel } from '../../Chapters/Layouts/FullChannel'

export function KeyBuilderExercisePage(){
    return(
        <div id="key-builder-exercise-page" className="chapterBlock">
            <h2>The HexKey Builder Exercise</h2>
            <FullChannel>
                <p>This exercise will help you develop your key-building skills.  Select a key and then click each position to fill in the correct note. Use the helper patterns to start (if necessary), then practice with the helpers hidden. Building keys is the fundamental skill in HexKey Musical Geometry.</p>
                <p>You'll quickly memorize a particular key, so it's good to switch keys often. The objective of this exercise is to learn the process of building keys. There are some additional off-line exercises below that will help you learn the key building process.</p>
            </FullChannel>
            <BaseRowBlock omitDivider={true}>
                <div className='centered'>
                        <KeyBuilderExercise/>
                </div>
                <SideBarBlock>
                    <Helpers/>
                </SideBarBlock>
            </BaseRowBlock>
            <FullChannel>
                <h3>Additional Exercises:</h3>
                <h4>On Paper</h4>
                <p>Try emulating this exercise using a pen to draw the HexKey structure (or print out a version of the HexKey to use as a template) and then use a pencil to fill in all the notes, then erase them and start over. With each iteration, choose a different key.</p>
                <h4>Visualization</h4>
                <p>Visualize a blank HexKey and then select a tonic note for that key. Go through the positions 1 through 7 and fill in the appropriate note. Then change keys and start over. If you're familiar with Queen's Gambit, that's what we're after here. Once you can do this using only your mind's eye, you'll be well equipped to quickly build any key you don't yet know from memory.</p>
            </FullChannel>
        </div>
    )
}

