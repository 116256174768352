import { useState } from "react"
import { HKMapComponent } from "../HKMap/HKMapComponent"
import { HKMapComponentWithIntervals } from "../HKMap/HKMapComponentWithIntervals"
import { HKMapData } from "../HKMap/HKMapData"
import { HKMapWrapperBase } from "../HKMap/HKMapWrapperBase"
import { HKMKeyData } from "../HKMap/HKMKeyData"
import { HKMNoteData } from "../HKMap/HKMNoteData"
export interface KeyBuilderBaseProps{
    hilitedNotes:number[]
    definedNotes:number[]
    selectedNotes:number[]
    definedArrows:number[]
    selectedArrows:number[]
    radius:number
    position?:number
}
export function ChordBuilderBase(props:KeyBuilderBaseProps){
    const rows:number = 1
    const columns:number = 1
    const tonic:number = 2 
    const radius:number = props.radius
    const onKeyClick = (key:HKMKeyData) => {
    }
    const onNoteClick = (note:HKMNoteData) => {}
    const getMapData = () => {
        let mapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
        mapData.defineAllNotesAs(false)
        mapData.defineAllKeysAs(false)
        props.definedNotes.forEach((noteIndex:number) => {
            mapData.notes[noteIndex].defined = true
        })
        props.selectedNotes.forEach((noteIndex:number) => {
            mapData.notes[noteIndex].selected = true
        })
        props.hilitedNotes.forEach((noteIndex:number) => {
            mapData.notes[noteIndex].hilited = true
        })
        return mapData
    }
    let [map, setMap] = useState(getMapData())
    let [visibleKeys, setVisibleKeys] = useState(map.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(map.getVisibleNotes())
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
            
    return <HKMapComponentWithIntervals 
                compWidth={compWidth} 
                compHeight={compHeight} 
                visibleKeys={visibleKeys} 
                visibleNotes={visibleNotes}
                definedArrows={props.definedArrows}
                selectedArrows={props.selectedArrows}/>
}