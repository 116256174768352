import { useState } from "react"
import { HKMapComponent } from "../../HKMap/HKMapComponent"
import { HKMapData } from "../../HKMap/HKMapData"
import { HKMKeyData } from "../../HKMap/HKMKeyData"
import { HKMNoteData } from "../../HKMap/HKMNoteData"
import { HKIntervalComponent } from "./HKIntervalCompnent"
import './HKInterval.css'
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase"

export function HKMIntervalWrapper(){
    const rows:number = 1
    const columns:number = 1
    const tonic:number = 8 
    const radius:number = 70
    const onKeyClick = (key:HKMKeyData) => {

    }
    const onNoteClick = (note:HKMNoteData) => {

    }

    let mapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
    mapData.openAllKeys()
    mapData.showNotesForOpenKeysOnly()
    let [visibleKeys, setVisibleKeys] = useState(mapData.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(mapData.getVisibleNotes())

    const [width, height] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
            
    return  <div className='interval-component'>
                <HKIntervalComponent compWidth={width} compHeight={height} visibleKeys={visibleKeys} visibleNotes={visibleNotes}/>
            </div>
}