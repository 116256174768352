import { useState } from "react"
import { HexKeyCompass } from "../../Helpers/HexKeyCompass/HexKeyCompass"

export function HexKeyCompassExerciseHelpers(){
    const [show, setShow] = useState(true)
    const getHelpers = () => {
        if(!show) return null
        return  <>
                        <HexKeyCompass/>
                </>
    }
    const toggleHelpers = (e:React.MouseEvent) => {
        e.stopPropagation()
        setShow(!show)
    }
    const getToggleLabel = () => {
        return (show) ? 'Hide' : 'Show'
    }
    return  <div className='simple-map-helpers'>
                <button type='button' onClick={toggleHelpers}>{getToggleLabel()} HexMap Compass</button>
                {getHelpers()}
            </div>
        
        
}