import '../chapters.css'
import { HKMSingleKey } from '../../HKMap/HKMSingleKey'
import { NeighborMap } from '../../HKMap/NeighborMap'
import { HKMSingleKeyTwoChord } from '../../HKMap/HKMSingleKeyTwoChord'
import { HashRouter as Router, Route, Link, Routes, BrowserRouter } from 'react-router-dom'
import { CoursePageNavigationLink, CoursePageProps } from '../../Course/CourseNavigation'
import { ChapterShell } from '../Layouts/ChapterShell'
import { BaseRowBlock, SixFourColumns } from '../Layouts/TwoColumn'
import { MapBuilderBase } from '../../MapBuilderWalkThrough/MapBuilderBase'
import { Music } from '../../Music/Music'
import { FullChannel } from '../Layouts/FullChannel'

export function Intro(props:CoursePageProps){
    return(
        <div id="intro" className="chapterBlock">
            <SixFourColumns classes={'with-side-bar'} bottomSpace={true}>
                <div>
                    <h3>A Musical Roadmap for Learning Music Theory</h3>
                    <p>
                        HeyKey Musical Geometry is an alternative, graphical way to learn and recall the fundamentals of music theory. It allows us to see the underlying patterns and relationships in music theory such as keys, chords, modes and scales as geometric patterns.</p>
                        <p>Music theory has a reputation for being difficult to learn. Part of the issue has been the lack of a visual roadmap - until now. HexKey provides that missing piece of the puzzle, making music theory a whole lot more accessible and understandable.</p>
                        <p>Just as its much easier to learn geography with maps, we can now use HexKey Musical Geometry as a visual reference to learn music theory. This will accelerate the learning process significantly if you're a visual learner.</p>
                    <h3>The HexKey</h3>
                    <p>
                        The core of HexKey Musical geometry is the HexKey pattern. It's a hexagonal representation of a major scale key and it's seven notes. The key is a foundational construct in music theory. We can use the HexKey pattern to understand the anatomy of a single key, define its notes and derive its diatonic information - its chords, scales and modes. Once the key is defined, the rest is pretty simple.</p>
                    <h3>The HexMap</h3>
                        <p>The HexKey pattern self-replicates into an interconnected network of keys: The HexMap. The HexMap provides us with a macro level view of all the keys and how they relate harmonically to each other. This perspective helps us to understand and map out musical elements like borrowed chords and key changes.
                    </p>
                    <h3>A Musical GPS</h3>
                    <p>
                        HexKey Musical Geometry's objective is to help people visualize musical patterns in real-time. By visualizing this geometry we can use it as a heads-up display to help navigate our way through songs or jam sessions - a musical GPS of sorts. You'll also find it to be a useful visual companion when you're reading about music theory or watching videos on YouTube, Instagram or other platforms.
                    </p>
                    <h3>Focus on Patterns over Labels</h3>
                    <p>
                        HexKey Musical Geometry will help clarify music theory by illustrating the basic patterns that music theory is based on. We focus on these patterns rather the labels that have been applied to them.
                    </p>
                    <p>
                        Music theory labels can be difficult to understand. They're sometimes more complex than the patterns they describe. Given that music theory nomenclature has evolved over several centuries, this is no real surprise.  So we focus on the underlying patterns first and then see how the labels fit in. This is fundamental.
                    </p>
                    
                </div>
                <div>
                    <div className="side-bar-block single-key">
                        <div className="caption-title">The key of G in HexKey form</div>
                        <HKMSingleKey tonic={Music.G}/>
                    </div>
                    <div className="side-bar-block base-map">
                        <div className="caption-title">All Twelve Keys of the HexMap</div>
                        <MapBuilderBase tonic={11}
                                    rows={5}
                                    columns={7}
                                    radius={25} 
                                    selectedKeys={[
                                        [1,2],[1,3],[1,4],[1,5],
                                        [2,1],[2,2],[2,3],[2,4],
                                        [3,1],[3,2],[3,3],[3,4]
                                    ]}
                                    defineAllKeys={true}/>
                        <div className='caption'>
                            <p>The HexKey pattern replicates itself, creating the HexMap Pattern. Within it we find clusters containing all twelve keys. Do you see the circle of fifths emerging?</p>
                        </div>
                        <NeighborMap tonic={Music.G}/>
                        <div className='caption'>
                            <p>Each key shares notes with it's neighbors. Mouse over the keys.</p>
                        </div>
                    </div>
                </div>
            </SixFourColumns>
            <FullChannel>

            <h3>HexKey Is Easy!</h3>
                    <p>
                        The good news is that HexKey Musical Geometry is actually pretty simple. It's based a single geometric pattern, the HexKey pattern. This foundational pattern is easy to learn. And yet it generates a surprising amount of musical information when we allow it to play itself out. For example it generates the full circle of fifths along one diagonal axis. A repeating 4 x 3 grouping of all twelve keys also emerges from the pattern. 
                    </p>
                    <h3>Course Overview</h3>
                    <p>
                        This course will introduce you to the fundamental construct in modern music: the Major Scale Key. Using the HexKey pattern, we'll look at the anatomy of a key. We'll see how we can derive the chords and scales that are built-in (diatonic) to any key. We'll also see how major & minor scales are related to the seven modes and how they're all derivatived from the same basic pattern - the major scale pattern.
                    </p>
                        
                    <p>
                        Then we'll zoom out to the HexMap view and look at the relationship between the twelve major scale keys. We'll use the HexMap to see musical elements as places within a larger structure.</p>
                    <p>This perspective will allow us to map out how non-diatonic musical elements like key changes and substitue chords work. Whether we're analyzing a song or writing one, we have a musical map to help us along.
                    </p>
                    <h3>Text for Concepts, Exercises for Real-Time Knowledge</h3>
                    <p>
                        Part of the course is comprised of chapters that introduce the core concepts and patterns. These chapters will provide a conceptual understanding of HexKey Musical Geometry.</p>
                        <p>The other part of the course is made up of exercises that will help you to internalize these core musical patterns and build your visual memory. This will allow you to visualize and recall musical information as you're playing, learning or writing a song.
                    </p>
                    <p>
                        It is <em>highly</em> recommended that you invest some time in the exercises so you can build your visual memory. There are three classes of exercises: 
                        <ul>
                            <li>'Helper Pattern Exercises' develop your core skills for constructing and navigating the geometry.</li>
                            <li>'Conceptual Exercises' develop our ability to build the HexKey and the HexMap structures.</li>
                            <li>'Memorizer Exercises' help us memorize individual HexKeys and the larger HexMap.</li>
                        </ul>
                    </p>
                    <p>If you work on these simple memory exercises, you'll incrementally build a full model of HexKey Musical Geometry in your visual memory. Just as we can visualize a clock face or the shape of certain countries, we can visualize musical structures. If you put in the time, you'll soon be more fluent in music theory than you may have ever imagined.</p>
                    <p> This musical fluency will improve your ability: to learn an instrument, improvise more fluidly and understand music theory in an effective way.
                    </p>
            </FullChannel>
        </div>
    )
}

