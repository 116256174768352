import { useState } from "react"
import { HKMapComponent } from "./HKMapComponent"
import { HKMapData } from "./HKMapData"
import { HKMapWrapperBase } from "./HKMapWrapperBase"
import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteData } from "./HKMNoteData"

export function HKMKeyPositions(){
    const rows:number = 1
    const columns:number = 1
    const tonic:number = 7 
    const radius:number = 60
    const onKeyClick = (key:HKMKeyData) => {}
    const onNoteClick = (note:HKMNoteData) => {}
    let mapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
    mapData.notes.forEach(
        (note:HKMNoteData, index:number)=>{
            note.presetLabel = String(index + 1)
        }
    )
    let [visibleKeys, setVisibleKeys] = useState(mapData.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(mapData.getVisibleNotes())
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
    return  <div className='key-positions'>
                <HKMapComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={visibleKeys} visibleNotes={visibleNotes}/>
            </div>
}