import '../chapters.css'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { Link } from 'react-router-dom'
import { useNoteName } from '../../Music/Accidentals/Accidentals'
import { ChordBuilderBase } from '../../KeyBuilderWalkThrough/ChordBuilderBase'
import { HKMKeyChordHelper } from '../../HKMap/HKMKeyChordHelper'
import { Music } from '../../Music/Music'
import { CoursePageProps, CoursePageNavigationLink } from '../../Course/CourseNavigation'
import { SideBarBlock } from '../Layouts/SideBarBlock'
import { BaseRowBlock } from '../Layouts/TwoColumn'
import { FullChannel } from '../Layouts/FullChannel'

export function PentatonicScales(props:CoursePageProps){
    return(
        <div id="chapter-six" className="chapterBlock">
            <FullChannel>
                <div className="fullChannel column">
                    <h3>The Pentatonic Scale</h3>
                    <p>The pentatonic scale is a subset of the major scale. Its a popular scale because of it's harmonic quality. This is the result of the 4th and 7th notes being omitted. They're omitted is because they form a tri-tone - a six interval distance that's particularly dissonant. (Try testing this out by counting the intervals from 4th to 7th) By removing this tri-tone from the scale, we get a scale in which all the notes sound good together. This means its hard to go wrong with a pentatonic scale. And that's why its so useful. </p>
                    <p>The pentanic scale is a great place to start on your instrument if you want to get some immediate gratification and sound 'musical'. You'll find that a lot of intrumental solos are based on the pentatonic scale.</p>
                    <p>As mentioned, the pentatonic scale is made up of five of the seven notes of a parent key. The 4th and 7th notes are omitted. The major pentatonic and minor pentatonic scales are derived from these same set of notes. They are two modes of the parent scale, not separate scales. There are three other potential versions of the pentatonic scale as well. These are not traditionally addressed.</p> 
                    <p>Similar to the issue of major keys/relative minor keys vs. modes, we're potentially looking at only two versions of a larger set and missing the overall pattern. We're going to look at all the pentatonic scale's modes to provide a more complete, pattern-based perspective.</p>
                    <p>In the section below we're going to build out a pentatonic scale. We'll identify the major and minor versions and introduce our somewhat unorthodox naming scheme to identify the other three versions. This will help to make the concept more complete. Again, the goal is to go beyond labels and see the underlying pattern at work.</p>     
                </div>
            </FullChannel>
            <BaseRowBlock title='Building the Pentatonic Scale'>
                <div>
                    <p>Let's start by using the key of D as our parent key. We can define D's pentatonic scale using the 1-2-3-5-6 pentatonic pattern (remember: 4 and 7 are omitted).</p>
                    <p>This gives us: D-E-{useNoteName(Music.Gb)}-A-B</p>   
                    <p>Note that the way we apply the formula is different than in the chords chapter. We don't apply the formula to each root note. Each of the five versions listed below will use the exact same set of notes defined above - but will start on a different note.</p>   
               </div>
                <SideBarBlock title={'The Pentatonic Scale for the key of D'}>
                    <ChordBuilderBase 
                                radius={80} 
                                hilitedNotes={[]}
                                selectedNotes={[0,1,2,4,5]}  
                                definedNotes={[0,1,2,4,5]}
                                definedArrows={[1,2,3,4,5,6]}
                                selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='The Major Pentatonic - The first mode of the pentatonic scale'>
                <div>
                    <p>The major pentatonic scale starts at the first note of the pentatonic scale. It's identical to D key's pentatonic scale, just as Ionian is identical to the major scale. </p> 
                    <p>It's note sequence relative to the parent key is: 1,2,3,5,6</p>
                    <p>It's interval sequence is: 2-2-3-2-3</p> 
                    <p>In D that's D-E-{useNoteName(Music.Gb)}-A-B</p>   
                </div>
                <SideBarBlock title={'The major pentatonic'}>
                    <ChordBuilderBase 
                        radius={80} 
                        hilitedNotes={[0]}
                        selectedNotes={[0,1,2,4,5]}  
                        definedNotes={[0,1,2,4,5]}
                        definedArrows={[1,2,3,4,5,6]}
                        selectedArrows={[]}/>
                    </SideBarBlock>
            </BaseRowBlock> 
            <BaseRowBlock title='The Minor Pentatonic Scale - the 5th mode of the pentatonic scale'>
                <div>
                    <p>The minor pentatonic scale starts at the 5th and last note of the pentatonic scale (rooted at the 6 position in the key). It's similar to the Aeolian mode in that respect.</p> 
                    <p>It's note sequence relative to the parent key is: 6,1,2,3,5</p>
                    <p>It's interval sequence is: 3-2-2-3-2</p> 
                    <p>In D that's B-D-E-{useNoteName(Music.Gb)}-A</p>
                </div>
                <SideBarBlock title={'The minor pentatonic scale'}>
                    <ChordBuilderBase 
                        radius={80} 
                        hilitedNotes={[5]}
                        selectedNotes={[0,1,2,4,5]}  
                        definedNotes={[0,1,2,4,5]}
                        definedArrows={[1,2,3,4,6,0]}
                        selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock> 
            <FullChannel>
                <h2>What about the other three modes?</h2>
                <div>
                    <p>We've covered two modes of a five-note scale, major and minor pentatonic. But how do we address the other three potential modes? Do they actually exist? Mathmatecally they certainly do, though they seem ignored by popular music theory. Let's complete the pattern and use mode names to the identify them.</p>
                </div>
            </FullChannel>
            <BaseRowBlock title='The Dorian Pentatonic'>
                <div>
                    <p>The Dorian pentatonic scale starts at the 2nd note of the pentatonic scale (rooted at the 2 position in the key).</p> 
                    <p>It's note sequence relative to the parent key is: 2,3,5,6,1</p>
                    <p>It's interval sequence is: 2-3-2-2-3</p> 
                    <p>In D that's E-{useNoteName(Music.Gb)}-A-B-D</p> 
                    <p>We know that Dorian is a minor mode. If we compare it's interval sequence with the minor pentatonic [3-2-2-3-2], we see that they are not identical.</p>
                </div>
                <SideBarBlock title={'The Dorian pentatonic scale'}>
                    <ChordBuilderBase 
                        radius={80} 
                        hilitedNotes={[1]}
                        selectedNotes={[0,1,2,4,5]}  
                        definedNotes={[0,1,2,4,5]}
                        definedArrows={[2,3,4,5,6,0]}
                        selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock> 
            <BaseRowBlock title='The Phrygian Pentatonic'>
                <div>
                    <p>The Phrygian pentatonic scale starts at the 3rd note of the pentatonic scale (rooted at the 3 position in the key).</p> 
                    <p>It's note sequence relative to the parent key is: 3,5,6,1,2</p>
                    <p>It's interval sequence is: 3-2-2-3-2</p> 
                    <p>In D that's {useNoteName(Music.Gb)}-A-B-D-E</p> 
                    <p>Phrygian is also a minor mode. If we compare it's interval sequence with the minor pentatonic [3-2-2-3-2], we see that they are identical. We can say that the {useNoteName(Music.Gb)} Phrygian pentatonic is synonymous with {useNoteName(Music.Gb)} minor pentatonic scale.</p>
                </div>
                <SideBarBlock title={'The Phrygian pentatonic scale'}><ChordBuilderBase 
                                            radius={80} 
                                            hilitedNotes={[2]}
                                            selectedNotes={[0,1,2,4,5]}  
                                            definedNotes={[0,1,2,4,5]}
                                            definedArrows={[3,4,5,6,0,1]}
                                            selectedArrows={[]}/></SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='The Mixolydian Pentatonic'>
                <div>
                    <p>The Mixolydian pentatonic scale starts at the 4th note of the pentatonic scale (rooted at the 5 position in the key).</p> 
                    <p>It's note sequence relative to the parent key is: 5,6,1,2,3</p>
                    <p>It's interval sequence is: 2-2-3-2-3</p> 
                    <p>In D that's A-B-D-E-{useNoteName(Music.Gb)}</p> 
                    <p>Mixolydian is also a major mode. If we compare it's interval sequence with the major pentatonic [2-2-3-2-3], we see that they are identical. We can say that the A Mixolydian pentatonic is synonymous with the A major pentatonic scale.</p>
                </div>
                <SideBarBlock title={'The Mixolydian pentatonic scale'}>
                    <ChordBuilderBase 
                        radius={80} 
                        hilitedNotes={[4]}
                        selectedNotes={[0,1,2,4,5]}  
                        definedNotes={[0,1,2,4,5]}
                        definedArrows={[5,6,0,1,2,3]}
                        selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock>            
            <FullChannel>
                <div>
                    <h3>Phrygian and Mixolydian are redundant, while Dorian is unique</h3>
                    <div className="fullChannel column">
                        <p>By examining the interval patterns of our three forgotten pentatonic modes, we see that two of them are redundant in a sense. Mixolydian pentatonic has the same interval pattern as the major pentatonic, and Phrygian's is the same as the minor pentatonic. We can see why these are forgotten. We can substitute an already familiar scale for them. Though it breaks the pattern of thinking within a single key to derive diatonic scales, in this case, it works.</p>
                        <p>Dorian on the other hand has a unique interval pattern. If we are in E Dorian, playing the E minor pentatonic isn't entirely correct. If we compare the interval patterns we see that the intervals are not the same. Minor:[3-2-2-3-2] Dorian:[2-3-2-2-3] Let's examine the actual notes to see how this plays out.</p>
                        <p>E Dorian pentatonic is: E-{useNoteName(Music.Gb)}-A-B-D. E Minor pentatonic is: E-G-A-B-D. We can see that the 2nd note in the scale is different. E Dorian pentatonic's 2nd is {useNoteName(Music.Gb)}, while E minor pentatonic's is G. E minor pentatonic is going to sound ok, since G is still in the key of D, but G is technically not in the pentatonic scale of the D key. What we get, relative to D is actually a different scale pattern: 1,2,4,5,6 (in this case we're omitting the 3rd note instead of the 4th).</p>
                        
                        <p>In general, by thinking in modal terms we don't have to think of major and minor pentatonic scales as separate scales. If we are pattern-conscious, we can memorize one pentatonic scale and start that scale at any position. This helps to keep things uncluttered. The fewer patterns we have to memorize, the better.</p>
                    </div>
                </div>
            </FullChannel >
    </div>
    )
}

