import { useState } from "react";
import { HKMIntervalWrapper } from "../../Helpers/IntervalSequence/HKMIntervalWrapper";
import { HKMKeyPositions } from "../../HKMap/HKMKeyPositions";
import { SequenceBase } from "../../KeyBuilderWalkThrough/SequenceBase";

export function SequenceHelpers(){
    const [showHelpers, setShowHelpers] = useState(true)
    const getHelpers = () => {
        if(showHelpers){
            return  <>
                        <div className='helper-block'>
                            <div className='caption-title'>The HexKey Sequence</div>
                            <SequenceBase selectedNotes={[]}/>
                        </div>
                    </>
        }
        return null
    }
    const toggle = () => {
        setShowHelpers(!showHelpers)
    }
    const getLabel = () => {
        return (showHelpers) ? 'Hide' : 'Show'
    }
    return  <div className='helpers'>
                <button type='button' className='show-hide-toggler' onClick={(e:React.MouseEvent)=>{e.stopPropagation(); toggle()}}>{getLabel()} Helper Pattern</button>
                {getHelpers()}
            </div>
}