import '../chapters.css'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { Link } from 'react-router-dom'
import { MusicalAlphabet } from '../../Exercises/KeyBuilderExercise/MusicalAlphabet'
import { KeyBuilderBase } from '../../KeyBuilderWalkThrough/KeyBuilderBase'
import { IntervalBase } from '../../KeyBuilderWalkThrough/IntervalBase'
import { SequenceBase } from '../../KeyBuilderWalkThrough/SequenceBase'
import { CoursePageNavigationLink, CoursePageProps } from '../../Course/CourseNavigation'
import { ChapterSubtitle, FullChannel } from '../Layouts/FullChannel'
import { BaseRowBlock, SixFourColumns } from '../Layouts/TwoColumn'
import { HKSideBar } from './HexKeyStructureSideBar'
import { CenterHex } from './CenterHex'
import { SideBarBlock } from '../Layouts/SideBarBlock'

export function HexKeyStructure(props:CoursePageProps){
    return(
        <div id="hex-key-structure" className="chapterBlock">
            <FullChannel>
                <p>In this chapter we'll walk through the steps used to build a HexKey Structure. In this example we'll build the key of D.</p>
            </FullChannel>
            <BaseRowBlock title='Step 1'>
                <div>
                    <p>The first step is to put the key's 'tonic' note in the 1 position. Since we're building the key of D, the 1 note is D.</p> 
                    <ul className='exercise-steps'>
                        <li>The HexKey Sequence Pattern tells us that the 1 note is located at the top corner.</li>
                    </ul>
                    <CenterHex selectedNote={0}/>     
                </div>
                <SideBarBlock title='The HexKey Sequence'>
                    <SequenceBase selectedNotes={[0]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='Step 2'>
                <div>
                    <p>The next step is to define the 2 note.</p>
                    <ul className='exercise-steps'>
                        <li>The HexKey Sequence Pattern tells us the 2 note is placed in the upper right corner.</li> 
                        <li>The HexKey Interval Pattern tells us that the 2 note is 2 intervals higher than the 1 note.</li> 
                        <li>The Musical Alphabet tells us that: <span>D + 2 intervals = E</span></li>    
                    </ul>   
                    <CenterHex selectedNote={1}/>     
                </div>
                <HKSideBar  selectedPositions={[1]} 
                            selectedNotes={[4]} 
                            referenceNotes={[2]}/>
            </BaseRowBlock>
            
            <BaseRowBlock title='Step 3'>
                <div>
                    <p>Next we define the 3 note.</p>
                    <ul className='exercise-steps'>
                        <li>The HexKey Sequence Pattern tells us the 3 note is placed in the lower right corner.</li>
                        <li>The HexKey Interval Pattern tells us that the 3 note is 2 intervals higher than the 2 note.</li>
                        <li>The Musical Alphabet tells us that:  <span>E + 2 intervals = F#</span></li>
                    </ul> 
                    <CenterHex selectedNote={2}/>     
                </div>
                <HKSideBar  selectedPositions={[2]} 
                            selectedNotes={[6]} 
                            referenceNotes={[4]}/>
            </BaseRowBlock>
            
            <BaseRowBlock title='Step 4'>
                <div>
                    <p>Next we define the 4 note:</p>
                    <ul className='exercise-steps'>
                        <li>The HexKey Sequence Pattern tells us the 4 note is placed in the upper left corner.</li>
                        <li>The HexKey Interval Pattern tells us that the 4 note is 1 interval higher than the 3 note.</li>
                        <li>The Musical Alphabet tells us that: <span>F# + 1 interval = G</span></li>
                    </ul>
                    <CenterHex selectedNote={3}/>      
                </div>
                <HKSideBar  selectedPositions={[3]} 
                            selectedNotes={[7]} 
                            referenceNotes={[6]}/>
            </BaseRowBlock>

            <BaseRowBlock title='Step 5'>
                <div>
                    <p>Next we define the 5 note.</p>
                    <ul className='exercise-steps'>
                        <li>The HexKey Sequence Pattern tells us the 5 note is placed in the lower left corner.</li>
                        <li>The HexKey Interval Pattern tells us that the 5 note is 2 intervals higher than the 4 note.</li>
                        <li>The Musical Alphabet tells us that: <span>G + 2 intervals = A</span></li>    
                    </ul>   
                    <CenterHex selectedNote={4}/>      
                </div>
                <HKSideBar  selectedPositions={[4]} 
                            selectedNotes={[9]} 
                            referenceNotes={[7]}/>
            </BaseRowBlock>

            <BaseRowBlock title='Step 6'>
                <div>
                    <p>Next we define the 6 note.</p>  
                    <ul className='exercise-steps'>
                        <li>The HexKey Sequence Pattern tells us the 6 note is placed in the bottom corner.</li>
                        <li>The HexKey Interval Pattern tells us that the 6 note is 2 intervals higher than the 5 note.</li>
                        <li>The Musical Alphabet tells us that <span>A + 2 intervals = B</span></li>    
                    </ul>  
                    <CenterHex selectedNote={5}/>
                </div>
                <HKSideBar  selectedPositions={[5]} 
                            selectedNotes={[11]} 
                            referenceNotes={[9]}/>
            </BaseRowBlock>

            <BaseRowBlock title='Step 7'>
                <div>
                    <p>Next we define the 7 note.</p>
                    <ul>
                        <li>The HexKey Sequence Pattern tells us the 7 note is placed in the center</li>
                        <li>The HexKey Interval Pattern tells us that the 7 note is 2 intervals higher than the 6 note.</li>
                        <li>The Musical Alphabet tells us that: <span>B + 2 intervals = C#</span></li>
                    </ul>    
                    <p>We've located and defined all the notes in the key of D.</p>
                    <CenterHex selectedNote={6}/>      
                </div>
                <HKSideBar  selectedPositions={[6]} 
                            selectedNotes={[1]} 
                            referenceNotes={[11]}/>
            </BaseRowBlock>

            <BaseRowBlock title='Step 8'>
                <div>
                    <p>We've already defined our 1 note as D. However when creating HexKeys manually or in our mind's eye it's useful to double-check our work by confirming that the 1 note is indeed 1 interval higher than our 7 note, as the HexKey Interval Pattern tells us. If you've made a mistake it will likely show up here. In this case: <span>C# + 1 interval = D.</span></p>
                    <p>The HexKey is correct.</p>
                    <CenterHex selectedNote={7}/>      
                </div>
                <HKSideBar  selectedPositions={[0]} 
                            selectedNotes={[2]} 
                            referenceNotes={[1]}/>
            </BaseRowBlock>
            <FullChannel>
                    <h3>Conclusion</h3>
                    <p>We can step through each note 1-7, define the note's name and place it in the HexKey Structure using two simple helper patterns.</p>
                    <p>We can use this same approach to build out all 12 keys in a 4x3 grouping that infinitely repeats itself, we call this larger structure a HexMap (more on that later).</p>
                    <p>
                        There's an exercise to practice building HexKeys: <Link to='/KeyBuilderExercisePage'>The HexKey Builder Exercise</Link>
                    </p>
                    <p>
                        When you're ready to start memorizing HexKeys, there an exercise for that as well: <Link to='/HexKeyMemorizer'>The HexKey Memorizer</Link>
                    </p>
            </FullChannel>
        </div>
    )
}

