import { useState } from "react"
import { HKMapChordHelperComponent } from "./HKMapChordHelperComponent"
import { HKMapComponent } from "./HKMapComponent"
import { HKMapData } from "./HKMapData"
import { HKMapWrapperBase } from "./HKMapWrapperBase"
import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteData } from "./HKMNoteData"
export interface KeyChordHelperProps{
    radius:number
}
export function HKMKeyChordHelper(props:KeyChordHelperProps){
    const rows:number = 1
    const columns:number = 1
    const tonic:number = 7 
    const onKeyClick = (key:HKMKeyData) => {}
    const onNoteClick = (note:HKMNoteData) => {}
    let mapData:HKMapData = new HKMapData(rows, columns, tonic, props.radius, onKeyClick, onNoteClick)
    mapData.notes.forEach(
        (note:HKMNoteData, index:number)=>{
            note.presetLabel = String(index + 1)
        }
    )
    mapData.notes[0].selected = true
    mapData.notes[3].selected = true
    mapData.notes[4].selected = true
    mapData.notes[1].hilited = true
    mapData.notes[2].hilited = true
    mapData.notes[5].hilited = true
    mapData.notes[6].selected = true
    mapData.notes[6].hilited = true
    let [visibleKeys, setVisibleKeys] = useState(mapData.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(mapData.getVisibleNotes())   
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(props.radius, rows, columns)
            
    return  <div className='key-positions chord-helper'>
                <HKMapChordHelperComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={visibleKeys} visibleNotes={visibleNotes}/>
            </div>
}