import { ChapterSubtitle } from "./FullChannel";

export {}
export interface TwoColumnProps{
    bottomSpace?:boolean
    divider?:boolean
    children: JSX.Element[];
    classes?:string;
}
export function SixFourColumns(props:TwoColumnProps){
    let classes:string[] = ['row']
    if(props.bottomSpace){classes.push('bottom-space')}
    if(props.divider){classes.push('divider')}
    let classNames:string = classes.join(' ')
    if(props.classes){
        classNames += ' ' + props.classes
    }
    return  <div className={classNames}>
                <div className="six-channel left-channel">
                    {props.children[0]}
                </div>
                <div className="four-channel right-channel">
                    {props.children[1]}
                    {props.children.length > 2 && props.children[2]}
                </div>
            </div>
}

export function FourSixColumns(props:TwoColumnProps){
    let classes:string[] = ['row']
    if(props.bottomSpace){classes.push('bottom-space')}
    if(props.divider){classes.push('divider')}
    let classNames:string = classes.join(' ')
    if(props.classes){
        classNames += ' ' + props.classes
    }
    return  <div className={classNames}>
                <div className="four-channel left-channel">
                    {props.children[0]}
                </div>
                <div className="six-channel right-channel">
                    {props.children[1]}
                    {props.children.length > 2 && props.children[2]}
                </div>
            </div>
}

export function FiveFiveColumns(props:TwoColumnProps){
    let classNames:string = 'row'
    if(props.classes){
        classNames += ' ' + props.classes
    }
    return  <div className={classNames}>
                <div className="five-channel">
                    {props.children[0]}
                </div>
                <div className="five-channel">
                    {props.children[1]}
                </div>
            </div>
}

export interface BaseRowBlock{
    children: JSX.Element[];
    title?:string
    omitDivider?:boolean
}
export function BaseRowBlock(props:BaseRowBlock){
    const hasDivider:boolean = (!props.omitDivider)
    return  <> 
                {props.title && <ChapterSubtitle>{props.title}</ChapterSubtitle>}
                <SixFourColumns classes={'with-side-bar'} divider={hasDivider} bottomSpace={true}>{props.children}</SixFourColumns>
            </>
}
export function ReverseRowBlock(props:BaseRowBlock){
    const hasDivider:boolean = (!props.omitDivider)
    return  <> 
                {props.title && <ChapterSubtitle>{props.title}</ChapterSubtitle>}
                <FourSixColumns classes={'with-side-bar'} divider={hasDivider} bottomSpace={true}>
                    {props.children}
                </FourSixColumns>
            </>
}