export interface SideBarBlockProps{
    title?:string
    children:React.ReactNode;
}

export function SideBarBlock(props:SideBarBlockProps){
    return  <div className='side-bar-block'>
                <div className='caption-title'>{props.title}</div>
                {props.children}
            </div>
}