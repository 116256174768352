import { useState } from "react"
import { HKIntervalComponent } from "../Helpers/IntervalSequence/HKIntervalCompnent"
import { HKMapData } from "../HKMap/HKMapData"
import { HKMKeyData } from "../HKMap/HKMKeyData"
import { HKMNoteData } from "../HKMap/HKMNoteData"
import '../Helpers/IntervalSequence/HKInterval.css'
import { HKMapWrapperBase } from "../HKMap/HKMapWrapperBase"
export interface IntervalBaseProps{
    selectedNotes:number[]
}
export function IntervalBase(props:IntervalBaseProps){
    const rows:number = 1
    const columns:number = 1
    const tonic:number = 8 
    const radius:number = 70
    const onKeyClick = (key:HKMKeyData) => {}
    const onNoteClick = (note:HKMNoteData) => {}

    let mapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
    mapData.openAllKeys()
    mapData.showNotesForOpenKeysOnly()
    props.selectedNotes.forEach((noteIndex:number) => {
        mapData.notes[noteIndex].selected = true
    })
    let [visibleKeys, setVisibleKeys] = useState(mapData.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(mapData.getVisibleNotes())
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
    return  <div className='interval-component interval-wrapper'>
                <HKIntervalComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={visibleKeys} visibleNotes={visibleNotes}/>
            </div>
}