import { useState } from "react"
import { Music } from "../Music/Music"
import { HKMapComponent } from "./HKMapComponent"
import { HKMapData } from "./HKMapData"
import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteData } from "./HKMNoteData"
export interface HKMDefinedMapProps{
    tonic?:number
}
export function NeighborMap(props:HKMDefinedMapProps){
    const rows:number = 3
    const columns:number = 3
    const tonic:number = props.tonic ? props.tonic - 4 : Music.G
    const radius:number = 50
    const centerKeyCoords:number[] = [1,1]
    const onKeyClick = (key:HKMKeyData) => {
        mapData.deselectAllKeys()
        let targetKey = mapData.keys[key.rowIndex][key.columnIndex]
        targetKey.open = !targetKey.open
        targetKey.selected = true;
        mapData.showNotesForOpenKeysOnly()
        refreshAllStateEntities()
    }
    const onNoteClick = (note:HKMNoteData) => {
        let targetNote = mapData.notes[note.noteIndex]
        targetNote.selected = !targetNote.selected
        setVisibleNotes(mapData.getVisibleNotes())
    }
    const refreshAllStateEntities = () => {
        setVisibleKeys(mapData.getVisibleKeys())
        setVisibleNotes(mapData.getVisibleNotes())        
    }
    const getCenterKey = () => {
        return mapData.keys[1][1] 
    }
    const onKeyHover = (mousedOverKey:HKMKeyData) => {
        mapData.hideAllNotes()
        mapData.hiliteAllNotes(false)
        mapData.closeAllKeys()
        mapData.deselectAllKeys()
        mousedOverKey.selected = true
        mousedOverKey.open = true
        getCenterKey().selected = true
        getCenterKey().open = true
        const hilitedKeyCoords:number[] = mousedOverKey.coordinates()
        mapData.openKeys([hilitedKeyCoords])
        mapData.showNotesForOpenKeysOnly()
        if(mousedOverKey.keyIndex !== getCenterKey().keyIndex){
            showSharedNotes(mousedOverKey)
        }
        refreshAllStateEntities()
    }

    const showSharedNotes = (mousedOverKey:HKMKeyData) => {
        let centerKeyNoteIndexes:number[] = getCenterKey().notes.getNoteIndexes()
        let selectedKeyNoteIndexes:number[] = mousedOverKey.notes.getNoteIndexes()
        const sharedNoteIndexes:number[] = selectedKeyNoteIndexes.filter((noteIndex:number)=>{
            return (centerKeyNoteIndexes.includes(noteIndex))
        })
        mapData.hiliteNotes(sharedNoteIndexes)
    }

    let mapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
    // mapData.openAllKeys()
    mapData.setSelectedKeys([[1,1]])
    mapData.hideKeys([[0,2],[2,2]])
    mapData.hideAllNotes()
    mapData.openKeys([[1,1]])
    mapData.showNotesForOpenKeysOnly()
    mapData.addHover(onKeyHover)
    let [visibleKeys, setVisibleKeys] = useState(mapData.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(mapData.getVisibleNotes())
    const onMouseOver = (event:React.MouseEvent) => {
        event.stopPropagation(); 
        mapData.hideAllNotes()
        mapData.closeAllKeys()
        mapData.deselectAllKeys()
        mapData.setSelectedKeys([[1,1]])
        mapData.openKeys([[1,1]])
        mapData.showNotesForOpenKeysOnly()
        refreshAllStateEntities()
    }

    return  <div onMouseOver={onMouseOver}>
                <HKMapComponent classNames={['neighbors']} compWidth={260} compHeight={260} visibleKeys={visibleKeys} visibleNotes={visibleNotes}/>
            </div>
}
        