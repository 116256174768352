import { FullChannel } from "../../Chapters/Layouts/FullChannel";
import { SideBarBlock } from "../../Chapters/Layouts/SideBarBlock";
import { BaseRowBlock } from "../../Chapters/Layouts/TwoColumn";
import { CoursePageProps } from "../../Course/CourseNavigation";
import { SimpleMapExerciseHelpers } from "./SimpleMapExerciseHelpers";
import { SimplifiedMapExercise } from "./SimplifiedMapExercise";

export function SimplifiedMapExercisePage(props:CoursePageProps){
    return  <div id="key-builder-exercise-page" className="chapterBlock">
                <h2>The Simplified HexMap Exercise</h2>
                <FullChannel>
                    <p>Build a portion of the HexMap using the Compass Helper Pattern and the HexKey Pattern.</p>
                    <p>The Compass Helper Pattern tells us the interval distances to each neighbor key.</p>
                    <p>If you don't know a key's notes and positions yet, use the HexKey pattern helper. Select the key's tonic note and the notes for each position will display.</p>
                    <p> Once you can do this with the helper patterns, try hiding them and see how you do from memory.</p>
                </FullChannel>                
                <BaseRowBlock omitDivider={true}>
                    <SimplifiedMapExercise/>    
                    <SideBarBlock>
                        <SimpleMapExerciseHelpers/>
                    </SideBarBlock>    
                </BaseRowBlock> 
                <FullChannel>
                    <h3>Additional Exercise</h3>
                    <p>Using paper and pencil draw a Simplified HexMap with five or more rows and columns.</p>
                </FullChannel>
            </div>
}