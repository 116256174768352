import '../../Chapters/chapters.css'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { Link } from 'react-router-dom'
import { SequenceExercise } from './SequenceExercise'
import { SequenceHelpers } from './SequenceHelpers'
import { BaseRowBlock } from '../../Chapters/Layouts/TwoColumn'
import { SideBarBlock } from '../../Chapters/Layouts/SideBarBlock'
import { FullChannel } from '../../Chapters/Layouts/FullChannel'

export function SequenceExercisePage(){
    return(
        <div id="sequence-exercise-page" className="chapterBlock">
            <FullChannel>
                <h2>The HexKey Sequence Exercise</h2>
                <p>This exercise will help you memorize the HexKey Sequence. Use the helper pattern on the right at first. Then try it without the helper pattern. Practice until you've memorized all seven positions. You'll be using this pattern a lot. Check out the additional exercises below. </p>
            </FullChannel>     
            <BaseRowBlock omitDivider={true}>
                <div>
                    <p>Select the corresponding number to each highlighted position. If your answer is correct, the exercise will move to the next position. If your answer is incorrect, the exercise will stay at that position until a correct answer is given.</p>
                    <div className='centered'><SequenceExercise/></div>
                </div>
                <SideBarBlock>
                    <SequenceHelpers/>
                </SideBarBlock>
            </BaseRowBlock>
            <FullChannel>
                <h3>Additional Exercises:</h3>
                <p>Practice with pencil and paper by drawing a blank HexKey and then using a pencil or your finger to step through the sequence, forward and backwards.</p>
                <p>Visualize a blank HexKey and step through the sequence, forward and backwards until you can see the full HexKey Sequence in your mind's eye.</p>
            </FullChannel>
        </div>
    )
}

