import './HKMap.css'
import { useEffect, useState } from "react"
import { HKMKeyComponent } from "./HKMKeyComponent"
import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteComp } from "./HKMNoteComp"
import { HKMNoteData } from "./HKMNoteData"
import { HKMapComponentProps } from './HKMapComponentProps'


export function HKMapComponent(props:HKMapComponentProps){
    let [keys, setKeys] = useState(props.visibleKeys)
    let [notes, setNotes] = useState(props.visibleNotes)
    let keyComps = keys.map((key, index)=>{
        return <HKMKeyComponent key={index} keyData={key}/>
    })
    let noteComps = notes.map((note, index)=>{
        return <HKMNoteComp key={index} noteData={note}/>
    })
    useEffect(()=>{
        setKeys(props.visibleKeys)
        setNotes(props.visibleNotes)
    })
    const isGrouping = () => {
        return (props.visibleKeys.length > 2)

    }
    const getClassName = () => {
        let classes:string[] = ['hkmap']
        if(props.classNames){
            classes = classes.concat(props.classNames)
        }
        classes.push('k-' + props.visibleKeys.length)
        if(isGrouping()){ classes.push('grouping')}
        return classes.join(' ')
    }
    return <svg className={getClassName()} width={props.compWidth} height={props.compHeight}>
                {keyComps}
                {props.children}
                {noteComps}
                
        </svg>
}