import { CourseState } from "../Course/CourseState"
import { useCourseStore } from "../Course/CourseStore"

export function AccidentalToggle(){
    const accidental:number = useCourseStore((state:CourseState)=>{
        return state.accidental
    })
    const setter:Function = useCourseStore((state:CourseState)=>{
        return state.setAccidental
    })
    const toggle:Function = function(){
        let accidentalValue:number = (accidental + 1) % 2
        setter(accidentalValue)
    }
    const getButtonLabel = () => {
        return (accidental === 0) ? 'sharps' : 'flats'
    }
    const getCurrentLabel = () => {
        return (accidental !== 0) ? 'sharps' : 'flats'
    }
    const onSwitchAccidental = (e:React.MouseEvent) => {
        e.stopPropagation()
        toggle()
    }
    return(
        <span>Currently using {getCurrentLabel()}
            <button type='button' onClick={onSwitchAccidental} className='accidental-toggle'>
                Switch to {getButtonLabel()}
            </button>
        </span>
    )
}