import { CoursePageProps } from "../../Course/CourseNavigation";
import { FullChannel } from "../Layouts/FullChannel";

export function ConclusionPage(props:CoursePageProps){ 
    return  <div className="chapterBlock">
                <FullChannel>
                    <p>In this course we've covered some of the fundamentals of music theory and demonstrated how we can use HexKey Musical Geometry to represent these ideas as geometric patterns. We addressed the following:
                    </p>
                    <ul>
                        <li>Building a key and numbering it's notes</li>
                        <li>Defining a key's diatonic chords using simple formulas</li>
                        <li>Deriving the seven modes of a key, and their scales</li>
                        <li>The conceptual relationship between modes and major & minor scales</li>
                        <li>Building all five versions of the pentatonic scale, including major and minor</li>
                        <li>Using the HexMap to understand key changes and mapping out techniques for creating them</li>
                    </ul>
                    <p>We encourage you to practice the conceptual exercises until you've got a good grasp of them. Then move on to the memorizer exercises. Focus on memorizing each key, one at a time. Memorize the key's neighbors as well. Soon you'll be able to visualize each individual HexKey, it's neighbors and the greater HexMap - and do it on-demand in any setting.</p>
                    <h4>A note on memorizing scales</h4>
                    <p>You may have noticed that all of the scale patterns we've covered are derived from the major scale. This means we can choose to learn the full major scale pattern on the instrument of our choice with position numbers and find all the other scales within it. Triad chords, too.</p>
                    <p>This is an efficient alternative to learning each scale as a distinct pattern. By taking the approach of seeing scales as patterns within the major scale, we can save a lot of memorization. It also reinforces our knowledge of the major scale pattern itself. This is especially useful in the early stages of learning your instrument of choice. </p>
                    <p><span className='bold'>Example for Guitar:</span> If we want to play a B minor pentatonic scale, we find a B note anywhere on the fretboard. Then we can apply and shift the major scale pattern so that the B minor note is at the six position of the pattern (the root position for a minor pentaonic scale). Then we can step through the pentatonic formula: 1-2-3-5-6, starting and ending on the six: 6-1-2-3-5-6.</p>
                    <p>Memorizing the major scale pattern with position numbers, rather than as a series of anonymous dots, will help demystify the fretboard. It may sound like a lot of work, but it will actually save a lot of work.</p>
                    <h4>Come back and read this again</h4>
                    <p>It will likely help to go over this material a few times to let it all sink in. Some of the chapters may seem tricky at first but with repetition this will all start to become second nature.</p>
                    <p>We wish you success on your musical journey. Take your time, do the exercises, be methodical and you'll get there.</p>
                </FullChannel>
            </div>

}

