import { CourseState } from '../../Course/CourseState'
import { useCourseStore } from '../../Course/CourseStore'
import './accidentals.css'
export interface AccidentalSplitProps{
    note:number
}
export function AccidentalSplit(props:AccidentalSplitProps){
    const noteName:string = useCourseStore((state:CourseState)=>{
        return state.getNoteLabel(state, props.note)
      })
    const chars:string[] = noteName.split('')
    if(chars.length === 1){
        return <span className={'accidental-split'}>{noteName}</span>
    }
    return <span className={'accidental-split'}>
            <span>{chars[0]}</span>
            <sup className={'accidental'}>{chars[1]}</sup>
            </span>
}

export function useNoteName(note:number){
    const noteName:string = useCourseStore((state:CourseState)=>{
        return state.getNoteLabel(state, note)
      })  
    return noteName 
}

export function SVGAccidentalSplit(props:AccidentalSplitProps){
    const noteName:string = useCourseStore((state:CourseState)=>{
        return state.getNoteLabel(state, props.note)
      })
    const chars:string[] = noteName.split('')
    if(chars.length === 1){
        return <g className={'accidental-split'}>
                    <text className={'accidental-split-base'}>{noteName}</text>
                </g>
    }
    return <g className={'accidental-split accidental-included'}>
                <text className={'accidental-split-base'}>{chars[0]}</text>
                <text className={'accidental'}>{chars[1]}</text>
            </g>
}