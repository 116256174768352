import { useState } from "react";
import { HKMIntervalWrapper } from "../../Helpers/IntervalSequence/HKMIntervalWrapper";
import { HKMKeyPositions } from "../../HKMap/HKMKeyPositions";
import { MusicalAlphabet } from "../KeyBuilderExercise/MusicalAlphabet";

export function MapBuilderExerciseHelpers(){
    const [showHelpers, setShowHelpers] = useState(true)
    const getHelpers = () => {
        if(showHelpers){
            return  <>
                        <div className='helper-block'>
                            <div className='caption-title'>Musical Alphabet</div>
                            <MusicalAlphabet referenceNotes={[]} selectedNotes={[]}/>
                            <div className='caption-title'>HexKey Sequence</div>
                            <HKMKeyPositions/>
                            <div className='caption-title'>HexKey Interval</div>
                            <HKMIntervalWrapper/>
                        </div>
                    </>
        }
        return null
    }
    const toggle = () => {
        setShowHelpers(!showHelpers)
    }
    const getLabel = () => {
        return (showHelpers) ? 'Hide' : 'Show'
    }
    const onClick = (e:React.MouseEvent) => {
        e.stopPropagation()
        toggle()
    }
    return  <div className='helpers'>
                <button type='button' className='show-hide-toggler' onClick={onClick}>{getLabel()} Helper Patterns</button>
                {getHelpers()}
            </div>
}