import React from "react";
import { HKMapComponent } from "../HKMap/HKMapComponent";
import { HKMapData } from "../HKMap/HKMapData";
import { HKMKeyData } from "../HKMap/HKMKeyData";
import { HKMNoteData } from "../HKMap/HKMNoteData";
import { MapBuilderBase } from "./MapBuilderBase";

export class MapBuildereState{
    visibleNotes:HKMNoteData[]
    visibleKeys:HKMKeyData[]
    completed:boolean = false
    constructor(visibleKeys:HKMKeyData[], visibleNotes:HKMNoteData[]){
        this.visibleKeys = visibleKeys
        this.visibleNotes = visibleNotes
    }
}
export interface MapBuilderKeyDirectionsProps{
    tonic:number
    selectedKeys:number[][]
    openKeys:number[][]
    selectedNotes:number[]
    definedKeys?:number[][]
    intervalLabels?:boolean
    hilitedKeys?:number[][]
    radius?:number
    classNames?:string[]
}
export class MapBuilderKeyDirections extends MapBuilderBase{
    props!:MapBuilderKeyDirectionsProps
    constructor(props:MapBuilderKeyDirectionsProps){
        super(props)
        this.props = props
        this.customizeData()
        this.state = this.getNewState()
    }
    componentDidUpdate(prevProps: MapBuilderKeyDirectionsProps, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.intervalLabels !== this.props.intervalLabels){
            this.customizeData()
            this.setState(this.getNewState())
        }
    }
    customizeData = () => {
        this.mapData.defineAllKeysAs(false)
        this.mapData.keys[0][1].predefinedLabel = (this.props?.intervalLabels) ? '8': 'b6th'
        this.mapData.keys[0][2].predefinedLabel = (this.props?.intervalLabels) ? '5': '4th'
        this.mapData.keys[1][1].predefinedLabel = (this.props?.intervalLabels) ? '3': 'b3rd'
        this.mapData.keys[1][3].predefinedLabel = (this.props?.intervalLabels) ? '9': '6th'
        this.mapData.keys[2][1].predefinedLabel = (this.props?.intervalLabels) ? '7': '5th'
        this.mapData.keys[2][2].predefinedLabel = (this.props?.intervalLabels) ? '4': '3rd'
        if(this.props.definedKeys){
            this.props.definedKeys.forEach((keyCoords:number[])=>{
                this.mapData.keys[keyCoords[0]][keyCoords[1]].defined = true
            })
        }
        else{
            this.mapData.keys[0][1].defined = true
            this.mapData.keys[0][2].defined = true
            this.mapData.keys[1][1].defined = true
            this.mapData.keys[1][3].defined = true
            this.mapData.keys[2][1].defined = true
            this.mapData.keys[2][2].defined = true
        }
        this.mapData.hideKeys([[0,0],[1,0],[2,0],[0,3],[0,4],[1,4],[2,3],[2,4]])
        if(this.props.hilitedKeys){
            this.props.hilitedKeys.forEach(
                (keyCoords:number[])=>{
                    this.mapData.keys[keyCoords[0]][keyCoords[1]].hilited = true
                }
            )
        }
        //this.mapData.defineAllKeysAs(true)
        // this.mapData.hideKeys([[2,0],[0,3],[0,4],[1,4],[2,3]])
    }
}
