export class ProjectState{
    usingFlats:boolean
    setter:Function
    loggedIn:boolean
    constructor(setter:Function){
      this.setter = setter
      this.loggedIn = false
      this.usingFlats = true
    }
    logIn = (b:boolean) => { return(this.setState({ loggedIn: b }) ) }
    useFlats = () => this.setState({ usingFlats: true })
    useSharps = () => this.setState({ usingFlats: false })
    setState = (obj:any) => {
      this.setter((state:ProjectState) => (obj))
    }
  }