import { useState } from "react"
import { HKMapData } from "../../HKMap/HKMapData"
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase"
import { HKMKeyData } from "../../HKMap/HKMKeyData"
import { HKMNoteData } from "../../HKMap/HKMNoteData"
import './HKMSequenceFlow.css'
import { HKSequenceFlowComponent } from "./HKSequenceFlowCompnent"

export function HKMSequenceFlowWrapper(){
    const rows:number = 1
    const columns:number = 1
    const tonic:number = 8 
    const radius:number = 80
    const onKeyClick = (key:HKMKeyData) => {

    }
    const onNoteClick = (note:HKMNoteData) => {

    }

    let mapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
    mapData.openAllKeys()
    mapData.showNotesForOpenKeysOnly()
    let [visibleKeys, setVisibleKeys] = useState(mapData.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(mapData.getVisibleNotes())
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)    
    return  <div className='sequence-flow-component'>
                <HKSequenceFlowComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={visibleKeys} visibleNotes={visibleNotes}/>
            </div>
}