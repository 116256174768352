import { CourseState } from "../../Course/CourseState";
import { useCourseStore } from "../../Course/CourseStore";
import { useNoteName } from "../../Music/Accidentals/Accidentals";
import { Music } from "../../Music/Music";
import './MusicalAlphabet.css'

export interface MusicalAlphabetProps{
    referenceNotes:number[]
    selectedNotes:number[]
}
export function MusicalAlphabet(props:MusicalAlphabetProps){
    const accidental:number = useCourseStore((state:CourseState)=>{
        return state.accidental
      }) 
    let noteNames:string[] = (accidental === 0) ? Music.notesFlat : Music.notesSharp
    const getNotes = ():JSX.Element[] => {
        let notes:JSX.Element[] = []
        noteNames.forEach((noteName:string, index:number)=>{
            let classes:string[] = ['musical-alphabet-note']
            if(props.referenceNotes.includes(index)) classes.push('reference-note')
            if(props.selectedNotes.includes(index)) classes.push('selected-note')
            const className:string = classes.join(' ')
            const note:JSX.Element = <span key={index} className={className}>{noteName}</span>
            notes.push(note)
        })
        return notes
    }
    return <div className="musical-alphabet">
                <div>{getNotes()}</div>
            </div>
}