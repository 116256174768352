import { useState } from "react"
import { HKMapComponent } from "../HKMap/HKMapComponent"
import { HKMapData } from "../HKMap/HKMapData"
import { HKMapWrapperBase } from "../HKMap/HKMapWrapperBase"
import { HKMKeyData } from "../HKMap/HKMKeyData"
import { HKMNoteData } from "../HKMap/HKMNoteData"
export interface KeyBuilderBaseProps{
    definedNotes:number[]
    selectedNotes:number[]
}
export function KeyBuilderBase(props:KeyBuilderBaseProps){
    const rows:number = 1
    const columns:number = 1
    const tonic:number = 2 
    const radius:number = 110
    const onKeyClick = (key:HKMKeyData) => {}
    const onNoteClick = (note:HKMNoteData) => {}
    let mapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
    mapData.defineAllNotesAs(false)
    props.definedNotes.forEach((noteIndex:number) => {
        mapData.notes[noteIndex].defined = true
    })
    props.selectedNotes.forEach((noteIndex:number) => {
        mapData.notes[noteIndex].selected = true
    })
    mapData.openKeys([[0,0]])
    let [visibleKeys, setVisibleKeys] = useState(mapData.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(mapData.getVisibleNotes())
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
    return <HKMapComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={visibleKeys} visibleNotes={visibleNotes}/>
}