import { Link } from "react-router-dom";
import { FullChannel } from "../../Chapters/Layouts/FullChannel";
import { NeighborMemorizer } from "./NeighborMemorizer";

export function NeighborMemorizerPage(){
    return <div id="key-memorizer-exercise-page" className="chapterBlock">
                <h2>The HexMap Neighbor Memorizer</h2>
                <FullChannel>
                    <p>
                        This exercise will help you memorize sections of the HexMap by learning the neighbor keys of a chosen key.  This will help reinforce your memory of that chosen key. It will also build your memory of the greater HexMap and the harmonic relationships between it's keys. 
                    </p>
                    <p>
                        Select any key from the dropdown menu, then identify the randomly chosen neighbor of that key. Use the helper patterns to get started then hide them.
                    </p>
                    <p>
                        It's recommended to pair this exercise with the <Link to={'/HexKeyMemorizer'}>HexKey Memorizer</Link> to build your memory of one specific key. Work on the HexKey Memorizer until you've got the key memorized then work on this nieghbor memorizer.
                    </p>
                </FullChannel>
                <NeighborMemorizer/>
            </div>
}