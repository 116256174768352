import { useEffect } from "react";
import { useLocation } from "react-router";
import { CourseState } from "./CourseState";
import { useCourseStore } from "./CourseStore";

const ScrollToTop = (props:any) => {
  const location = useLocation();
  const closeToc:Function = useCourseStore((state:CourseState)=>{
    return ()=>{
        state.setTocIsOpen(false)
    }
})
  useEffect(() => {
    window.scrollTo(0, 0);
    closeToc()
  }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;