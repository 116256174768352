import { HKMapData } from "../../HKMap/HKMapData";
import { HKMKeyData } from "../../HKMap/HKMKeyData";
import { HKMNoteData } from "../../HKMap/HKMNoteData";
import '../KeyBuilderExercise/KeyBuilderExercise.css'
import React from "react";
import { SequenceButtons } from "./SequenceButtons";
import { HKMapComponent } from "../../HKMap/HKMapComponent";
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase";

export class SequenceExerciseState{
    visibleNotes:HKMNoteData[]
    visibleKeys:HKMKeyData[]
    lastAnswer:number | null = null
    lastAnswerCorrect:boolean = false
    constructor(visibleKeys:HKMKeyData[], visibleNotes:HKMNoteData[]){
        this.visibleKeys = visibleKeys
        this.visibleNotes = visibleNotes
    }
}
export class SequenceExercise extends React.Component {
    tonic:number = 0
    selectedPostionIndex:number
    rows:number = 1
    columns:number = 1 
    radius:number = 100
    mapData:HKMapData
    state:SequenceExerciseState
    constructor(props:any){
        super(props)
        this.selectedPostionIndex = this.getRandomIndex()
        this.mapData = this.getMapData()
        this.state = new SequenceExerciseState(this.getVisibleKeys(), this.getVisibleNotes())
    }
    onKeyClick = (key:HKMKeyData) => {}
    onNoteClick = (note:HKMNoteData) => {}
    getRandomIndex = () => {
        const positions:number[] = [0,1,2,3,4,5,6]
        let choices:number[] = positions.filter((position)=>{
            return position != this.selectedPostionIndex
        })
        const index:number = Math.floor( Math.random() * (choices.length) )
        return choices[index]
    }
    onButtonClick = (userAnswer:number) => {
        const isCorrect:boolean = (userAnswer === this.selectedPostionIndex)
        if(isCorrect){
            this.onRightAnswer(userAnswer)
        } 
        else{
            this.onWrongAnswer(userAnswer)
        }
    }
    onWrongAnswer = (userAnswer:number) => {
        this.setState({lastAnswerCorrect:false, lastAnswer:userAnswer})

    }
    onRightAnswer = (userAnswer:number) => {
        this.selectedPostionIndex = this.getRandomIndex()
        this.mapData.selectSingleNote(this.selectedPostionIndex)
        this.setState({visibleNotes:this.getVisibleNotes(), lastAnswerCorrect:true, lastAnswer:userAnswer})
    } 
    getMapData = () => {
        let mapData:HKMapData = new HKMapData(this.rows, this.columns, this.tonic, this.radius, this.onKeyClick, this.onNoteClick)
        mapData.selectSingleNote(this.selectedPostionIndex)
        mapData.defineAllNotesAs(false)
        mapData.notes.forEach(
            (note:HKMNoteData, index:number)=>{
                // note.presetLabel = ''
            }
        )
        return mapData
    }
    getVisibleKeys = () => {return this.mapData.getVisibleKeys()}
    getVisibleNotes = () => {return this.mapData.getVisibleNotes()}
    render = () =>{
            const [width, height] = HKMapWrapperBase.getCompDimensions(this.radius, this.rows, this.columns)
            return  <div className='sequence-exercise'>
                        <HKMapComponent compWidth={width} compHeight={height} visibleKeys={this.state.visibleKeys} visibleNotes={this.state.visibleNotes}/>
                        <SequenceButtons onClick={this.onButtonClick}/>
                        <SequenceExerciseMessage correct={this.state.lastAnswerCorrect} lastAnswer={this.state.lastAnswer}/>
                    </div>
    }     
}

export function SequenceExerciseMessage(props:any){
    let message:string = ' - '
    if(props.lastAnswer !== null){
        message = String(props.lastAnswer + 1) + ' is '
        message += ((props.correct) ? 'correct. Next one is...' : ' incorrect.')
    }
    return <div className='message'>{message}</div>
}