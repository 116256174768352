import { HKMNoteData } from "./HKMNoteData"

export class HKMKeyNotes{
    ionian:HKMNoteData
    dorian:HKMNoteData
    phrygian:HKMNoteData
    lydian:HKMNoteData
    mixolydian:HKMNoteData
    aeolian:HKMNoteData
    locrian:HKMNoteData
    constructor(){
        this.ionian = new HKMNoteData()
        this.dorian = new HKMNoteData()
        this.phrygian = new HKMNoteData()
        this.lydian = new HKMNoteData()
        this.mixolydian = new HKMNoteData()
        this.aeolian = new HKMNoteData()
        this.locrian = new HKMNoteData()
    }
    getNoteIndexes(){
        return [this.ionian.noteIndex, this.dorian.noteIndex, this.phrygian.noteIndex, this.lydian.noteIndex, this.mixolydian.noteIndex, this.aeolian.noteIndex, this.locrian.noteIndex]
    }
    hasNoteByIndex(noteIndex:number){
        const noteIndexes:number[] = this.getNoteIndexes()
        const hasNote:boolean = noteIndexes.includes(noteIndex)
        return hasNote
    }
}