import { HKMapData } from "../../HKMap/HKMapData";
import { HKMKeyData } from "../../HKMap/HKMKeyData";
import { HKMNoteData } from "../../HKMap/HKMNoteData";
import '../KeyBuilderExercise/KeyBuilderExercise.css'
import React from "react";
import { IntervalButtons } from "./IntervalButtons";
import { IntervalExerciseComponent } from "./IntervalExerciseComponent";
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase";
import { HKMapComponent } from "../../HKMap/HKMapComponent";
import { HKMIntervalWrapper } from "../../Helpers/IntervalSequence/HKMIntervalWrapper";
import { HKIntervalComponent } from "../../Helpers/IntervalSequence/HKIntervalCompnent";

export class IntervalExerciseState{
    visibleNotes:HKMNoteData[]
    visibleKeys:HKMKeyData[]
    lastAnswer:number | null = null
    lastAnswerCorrect:boolean = false
    constructor(visibleKeys:HKMKeyData[], visibleNotes:HKMNoteData[]){
        this.visibleKeys = visibleKeys
        this.visibleNotes = visibleNotes
    }
}
export class IntervalExercise extends React.Component {
    tonic:number = 0
    selectedIntervalIndex:number
    rows:number = 1
    columns:number = 1 
    radius:number = 100
    mapData:HKMapData
    state:IntervalExerciseState
    oneIntervals:number[] = [0,3]
    constructor(props:any){
        super(props)
        this.selectedIntervalIndex = this.getRandomIndex()
        this.mapData = this.getMapData()
        this.state = new IntervalExerciseState(this.getVisibleKeys(), this.getVisibleNotes())
    }
    onKeyClick = (key:HKMKeyData) => {}
    onNoteClick = (note:HKMNoteData) => {}
    getRandomIndex = () => {
        const positions:number[] = [0,1,2,3,4,5,6]
        let choices:number[] = positions.filter((position)=>{
            return position != this.selectedIntervalIndex
        })
        const index:number = Math.floor( Math.random() * (choices.length) )
        return choices[index]
    }
    onButtonClick = (userAnswer:number) => {
        const correctAnswer:number = (this.oneIntervals.includes(this.selectedIntervalIndex)) ? 1 : 2
        const isCorrect:boolean = (userAnswer === correctAnswer)
        if(isCorrect){
            this.onRightAnswer(userAnswer)
        } 
        else{
            this.onWrongAnswer(userAnswer)
        }
    }
    onWrongAnswer = (userAnswer:number) => {
        this.setState({lastAnswerCorrect:false, lastAnswer:userAnswer})

    }
    onRightAnswer = (userAnswer:number) => {
        this.selectedIntervalIndex = this.getRandomIndex()
        this.mapData.selectSingleNote(this.selectedIntervalIndex)
        this.setState({visibleNotes:this.getVisibleNotes(), lastAnswerCorrect:true, lastAnswer:userAnswer})
    } 
    getMapData = () => {
        let mapData:HKMapData = new HKMapData(this.rows, this.columns, this.tonic, this.radius, this.onKeyClick, this.onNoteClick)
        mapData.selectSingleNote(this.selectedIntervalIndex)
        mapData.defineAllKeysAs(false)
        return mapData
    }
    getVisibleKeys = () => {return this.mapData.getVisibleKeys()}
    getVisibleNotes = () => {return this.mapData.getVisibleNotes()}
    render = () =>{
            const [width, height] = HKMapWrapperBase.getCompDimensions(this.radius, this.rows, this.columns)
            return  <div className='interval-exercise'>
                        <div className=''>
                            <HKIntervalComponent compWidth={width} compHeight={height} visibleKeys={this.state.visibleKeys} visibleNotes={this.state.visibleNotes}/>
                        </div>
                        <IntervalButtons onClick={this.onButtonClick}/>
                        <IntervalExerciseMessage correct={this.state.lastAnswerCorrect} lastAnswer={this.state.lastAnswer}/>
                    </div>
    }     
}

export function IntervalExerciseMessage(props:any){
    let message:string = 'Is the highlighted interval 1 or 2?'
    if(props.lastAnswer !== null){
        message = String(props.lastAnswer)
        message += (props.correct) ? ' is correct. The next one is...' : ' is incorrect.'
    }
    return <div className='message'>{message}</div>
}