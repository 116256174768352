import { useState } from "react"
import { HKMapComponent } from "../HKMap/HKMapComponent"
import { HKMapData } from "../HKMap/HKMapData"
import { HKMapWrapperBase } from "../HKMap/HKMapWrapperBase"
import { HKMKeyData } from "../HKMap/HKMKeyData"
import { HKMNoteData } from "../HKMap/HKMNoteData"

export interface SequenceBaseProps{
    selectedNotes:number[]
}
export function SequenceBase(props:SequenceBaseProps){
    const rows:number = 1
    const columns:number = 1
    const tonic:number = 7 
    const radius:number = 60
    const onKeyClick = (key:HKMKeyData) => {}
    const onNoteClick = (note:HKMNoteData) => {}
    let mapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
    mapData.notes.forEach(
        (note:HKMNoteData, index:number)=>{
            note.presetLabel = String(index + 1)
        }
    )
    props.selectedNotes.forEach((noteIndex:number) => {
        mapData.notes[noteIndex].selected = true
        mapData.notes[noteIndex].defined = true
    })
    let [visibleKeys, setVisibleKeys] = useState(mapData.getVisibleKeys())
    let [visibleNotes, setVisibleNotes] = useState(mapData.getVisibleNotes())
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
    return  <div className='key-positions'>
                <HKMapComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={visibleKeys} visibleNotes={visibleNotes}/>
            </div>
}