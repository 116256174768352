import React from "react";
import { Login } from "../Login/Login";
import { ChapterShell } from "../Chapters/Layouts/ChapterShell";
export class SiteIntro extends React.Component{
    render():React.ReactNode {
        return  <div className='chapter-shell'>
                    <h1>HexKey Musical Geometry</h1>
                    <div>
                        <p>Welcome to the bare-bones 0.1 version of the site.</p> 
                        <p>The site is currently built and tested for the Chrome browser at desktop dimensions only.</p>
                        <p>Login to access the course.</p>
                    </div>
                    <Login/>
                </div>
    }
}