import '../../Chapters/chapters.css'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { IntervalHelpers } from './IntervalHelpers'
import { IntervalExercise } from './IntervalExercise'
import { BaseRowBlock } from '../../Chapters/Layouts/TwoColumn'
import { SideBarBlock } from '../../Chapters/Layouts/SideBarBlock'
import { FullChannel } from '../../Chapters/Layouts/FullChannel'

export function IntervalExercisePage(){
    return(
        <div id="interval-exercise-page" className="chapterBlock">
            <h2>The Interval Exercise</h2>
            <FullChannel>
                <p>
                    This exercise will help you memorize the interval distances between positions. Notice that most are 2's and that there are only a couple places where they are 1's. Memorize where the 1's are and you're effectively done. You'll use this interval pattern quite a bit so it's important to know it well. 
                </p>     
            </FullChannel>
            <BaseRowBlock omitDivider={true}>
                <div className="centered">
                    <IntervalExercise/>
                </div>
                <SideBarBlock>
                    <IntervalHelpers/>
                </SideBarBlock>
            </BaseRowBlock>
            <FullChannel>
                <h3>Additional Exercises:</h3>
                <p>Practice with pencil and paper by drawing the arrows of the HexKey Interval pattern and then using a pencil or your finger to step through the sequence, forward and backwards.</p>
                <p>Visualize the color-coded arrows of the HexKey Interval pattern and step through the sequence and define each interval. Try it going forward and backwards until you can see the HexKey Interval pattern in your mind's eye.</p>
            </FullChannel>
        </div>
    )
}

