export interface FullChannelProps{
    vspace?:boolean
    children?: React.ReactNode;
}

export function FullChannel(props:FullChannelProps){
    return  <div className="row">
                <div className="fullChannel column">
                    {props.children}      
                </div>
            </div>
}
export function ChapterSubtitle(props:FullChannelProps){
    return <FullChannel><h2>{props.children}</h2></FullChannel>
}