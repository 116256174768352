import { CourseState } from "../../Course/CourseState";
import { useCourseStore } from "../../Course/CourseStore";
export interface IntervalButtonsProps{
    onClick:Function
}
export function IntervalButtons(props:IntervalButtonsProps){
        let firstRowButtons:JSX.Element[] = []
        const firstRow:number[] = [1,2]
        firstRow.forEach((interval:number) => {
          let b:JSX.Element = <IntervalButton interval={interval} onClick={props.onClick} key={interval}/>
          firstRowButtons.push(b)
        })
        const accidental:number = useCourseStore((state:CourseState)=>{
          return state.accidental
        })
        const accidentalClassName:string = (accidental === 0) ? 'flat' : 'sharp'
        const className:string = 'note-buttons ' + accidentalClassName
        return  <div className={className}>
                  <div className='first-row'>{firstRowButtons}</div>
                </div>
}
export interface NoteButtonProps{
  interval:number
  onClick:Function
}
export function IntervalButton(props:NoteButtonProps){
  return  <button className="interval-button" onClick={(e:React.MouseEvent)=>{e.stopPropagation(); props.onClick(props.interval)}} type="button">
            {props.interval}
          </button>
}