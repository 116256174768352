import { AccidentalSplit } from "../../Music/Accidentals/Accidentals"
import { Music } from "../../Music/Music"
import { useNoteName } from "../../Music/Accidentals/Accidentals";
import { CourseState } from "../../Course/CourseState";
import { useCourseStore } from "../../Course/CourseStore";
import './NoteButtons.css'
export interface NoteButtonsProps{
    onClick:Function
}
export function NoteButtons(props:NoteButtonsProps){
        let firstRowButtons:JSX.Element[] = []
        let secondRowButtons:JSX.Element[] = []
        const firstRow:number[] = [Music.F, Music.C, Music.G, Music.D, Music.A, Music.E, Music.B]
        const secondRow:number[] = [Music.Gb, Music.Db, Music.Ab, Music.Eb, Music.Bb]
        firstRow.forEach((note:number, index:number) => {
          let b:JSX.Element = <NoteButton note={note} onClick={props.onClick} key={index}/>
          firstRowButtons.push(b)
        })
        secondRow.forEach((note:number, index:number) => {
          let b:JSX.Element = <NoteButton note={note} onClick={props.onClick}key={index}/>
          secondRowButtons.push(b)
        })
        const accidental:number = useCourseStore((state:CourseState)=>{
          return state.accidental
        })
        const accidentalClassName:string = (accidental === 0) ? 'flat' : 'sharp'
        const className:string = 'note-buttons ' + accidentalClassName
        return  <div className={className}>
                  <div className='first-row'>{firstRowButtons}</div>
                  <div className='second-row'>{secondRowButtons}</div>
                </div>
}
export interface NoteButtonProps{
  note:number
  onClick:Function
}
export function NoteButton(props:NoteButtonProps){
  const onClick = (e:React.MouseEvent) => {
    e.stopPropagation()
    props.onClick(props.note)
}
  return  <button className="note-button" onClick={onClick} type="button">
            {useNoteName(props.note)}
          </button>
}