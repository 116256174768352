import { MusicalAlphabet } from "../../Exercises/KeyBuilderExercise/MusicalAlphabet";
import { IntervalBase } from "../../KeyBuilderWalkThrough/IntervalBase";
import { SequenceBase } from "../../KeyBuilderWalkThrough/SequenceBase";

export interface HKSideBarProps{
    selectedPositions:number[]
    selectedNotes:number[]
    referenceNotes:number[]
}

export function HKSideBar(props:HKSideBarProps){
    return  <div>
                <div className='side-bar-block'>
                    <div className='caption-title'>HexKey Sequence</div>
                    <SequenceBase selectedNotes={props.selectedPositions}/>
                    <div className='caption-title'>HexKey Interval</div>
                    <IntervalBase selectedNotes={props.selectedPositions}/>
                    <div className='caption-title'>Musical Alphabet</div>
                    <MusicalAlphabet referenceNotes={props.referenceNotes} selectedNotes={props.selectedNotes}/>
                </div>
            </div>
}