import { Music } from "../Music/Music"
import { HKMComponentState } from "./HKMComponentState"
import { HKMKeyData, KeyNeighbors } from "./HKMKeyData"
import { KeyNoteRelationship } from "./HKMKeyNoteRelationship"
import { HKMKeyNotes } from "./HKMKeyNotes"
import { HKMNoteComp } from "./HKMNoteComp"
import { HKMNoteData } from "./HKMNoteData"

export class HKMapData{
    rows:number
    columns:number
    tonic:number
    keys:HKMKeyData[][]
    notes:HKMNoteData[]
    radius:number
    noteXFactor:number = .866
    noteYFactor:number = .5
    onKeyClick:Function
    onNoteClick:Function
    xTransform:number
    onHover:Function = ()=>{}
    constructor(rows:number, columns:number, tonic:number, radius:number, onKeyClick:Function, onNoteClick:Function, xTransform:number = 0){
        this.rows = rows
        this.columns = columns
        this.tonic = tonic
        this.radius = radius
        this.onKeyClick = onKeyClick
        this.onNoteClick = onNoteClick
        this.xTransform = xTransform
        this.keys = []
        this.notes = []
        this.buildKeys()
    }
    addHover(onHover:Function){
        this.keys.flat().forEach((key:HKMKeyData)=>{
            key.onHover = onHover
        })
    }
    buildKeys = () => {
        for(let rowIndex = 0; rowIndex < this.rows; rowIndex++){
            let row:HKMKeyData[] = []
            this.keys.push(row)
            for(let columnIndex = 0; columnIndex < this.columns; columnIndex++){
                const keyIndex = (rowIndex * this.rows) + columnIndex
                const tonic = this.getKeyTonic(rowIndex, columnIndex)
                const [x,y] = this.getKeyCoordinates(rowIndex, columnIndex)
                let key:HKMKeyData = new HKMKeyData(keyIndex, rowIndex, columnIndex, tonic, x, y, this.radius, this.onKeyClick)
                row.push(key)
                this.buildAllNotesForKey(key)
            }
        }
    }
    getKeyCoordinates = (rowIndex:number, columnIndex:number) => {
        const multiplier:number = (this.rows > 1) ? 2 : 1
        let x:number = this.xTransform + Math.round(multiplier * this.radius * this.noteXFactor) + Math.floor(this.radius * HKMNoteComp.circleToRadiusRatio)
        let y:number = this.radius + Math.floor(this.radius * HKMNoteComp.circleToRadiusRatio)
        // ^ default position of first key
        const prevKey:HKMKeyData | null = this.getPreviousKey(rowIndex, columnIndex)
        if(prevKey){
            x = prevKey.x + Math.round(2 * this.radius * this.noteXFactor)
            y = prevKey.y
        }
        const fourthKey:HKMKeyData | null = this.getFourthKey(rowIndex, columnIndex)
        if(fourthKey){
            x = fourthKey.x - Math.round(this.radius * this.noteXFactor)
            y = fourthKey.y + Math.round(this.radius * 1.5)
        }
        return [x,y] 
    }
    getKeyTonic = (rowIndex:number, columnIndex:number) => {
        let tonic:number = this.tonic
        const prevKey:HKMKeyData | null = this.getPreviousKey(rowIndex, columnIndex)
        if(prevKey){
            tonic = Music.addInterval(prevKey.tonic, Music.interval_6th)
        } 
        const fourthKey:HKMKeyData | null = this.getFourthKey(rowIndex, columnIndex)
        if(fourthKey){
            tonic = Music.addInterval(fourthKey.tonic, Music.interval_5th)
        }  
        return tonic   
    }
    buildAllNotesForKey = (key:HKMKeyData) => {
        this.buildIonian(key)
        this.buildDorian(key)
        this.buildPhrygian(key)
        this.buildLydian(key)
        this.buildMixolydian(key)
        this.buildAeolian(key)
        this.buildLocrian(key)
    }
    buildNote = (x:number, y:number, tonic:number) => {
        const noteIndex = this.notes.length
        const note = new HKMNoteData(noteIndex, x, y, tonic, this.radius, this.onNoteClick)
        this.notes.push(note)
        return note
    }
    bondNoteToKey = (key:HKMKeyData, position:number, note:HKMNoteData) => {
        const relationship:KeyNoteRelationship = new KeyNoteRelationship(key, position)
        note.keysRelationships.push(relationship)

    }
    buildIonian = (key:HKMKeyData) => {        
        const flatSixthKey:HKMKeyData | null = this.getFlatSixthKey(key.rowIndex, key.columnIndex)
        const fourthKey:HKMKeyData | null = this.getFourthKey(key.rowIndex, key.columnIndex)
        if(flatSixthKey){
            key.notes.ionian = flatSixthKey.notes.phrygian
        }
        else if(fourthKey){
            key.notes.ionian = fourthKey.notes.mixolydian
        }
        else{
            const x = key.x
            const y = key.y - this.radius
            const tonic = key.tonic
            const note = this.buildNote(x, y, tonic)
            key.notes.ionian = note
        }
        this.bondNoteToKey(key, 1, key.notes.ionian)

        
        
    }
    buildDorian = (key:HKMKeyData) => {
        const fourthKey:HKMKeyData | null = this.getFourthKey(key.rowIndex, key.columnIndex)
        if(fourthKey){
            key.notes.dorian = fourthKey.notes.aeolian
        }
        else{
            const x = key.x + Math.floor(this.radius * this.noteXFactor)
            const y = key.y - Math.floor(this.radius * this.noteYFactor)
            const tonic = Music.addInterval(key.tonic, Music.interval_2nd)
            const note = this.buildNote(x, y, tonic)
            key.notes.dorian = note
        }
        this.bondNoteToKey(key, 2, key.notes.dorian)
    }
    buildPhrygian = (key:HKMKeyData) => {
        const x = key.x + Math.floor(this.radius * this.noteXFactor)
        const y = key.y + Math.floor(this.radius * this.noteYFactor)
        const tonic = Music.addInterval(key.tonic, Music.interval_3rd)
        const note = this.buildNote(x, y, tonic)
        key.notes.phrygian = note
        this.bondNoteToKey(key, 3, key.notes.phrygian)
    }
    buildLydian = (key:HKMKeyData) => {
        const flatSixthKey:HKMKeyData | null = this.getFlatSixthKey(key.rowIndex, key.columnIndex)
        const prevKey:HKMKeyData | null = this.getPreviousKey(key.rowIndex, key.columnIndex)
        if(flatSixthKey){
            key.notes.lydian = flatSixthKey.notes.aeolian
        }
        else if(prevKey){
            key.notes.lydian = prevKey.notes.dorian
        }
        else{
            const x = key.x - Math.floor(this.radius * this.noteXFactor)
            const y = key.y - Math.floor(this.radius * this.noteYFactor)
            const tonic = Music.addInterval(key.tonic, Music.interval_4th)
            const note = this.buildNote(x, y, tonic)
            key.notes.lydian = note
        }

        this.bondNoteToKey(key, 4, key.notes.lydian)
    }
    buildMixolydian = (key:HKMKeyData) => {
        const prevKey:HKMKeyData | null = this.getPreviousKey(key.rowIndex, key.columnIndex)
        if(prevKey){
            key.notes.mixolydian = prevKey.notes.phrygian
        }
        else{ 
            const x = key.x - Math.floor(this.radius * this.noteXFactor)
            const y = key.y + Math.floor(this.radius * this.noteYFactor)
            const tonic = Music.addInterval(key.tonic, Music.interval_5th)
            const note = this.buildNote(x, y, tonic)
            key.notes.mixolydian = note
        }
        this.bondNoteToKey(key, 5, key.notes.mixolydian)
    }
    buildAeolian = (key:HKMKeyData) => {
        const x = key.x
        const y = key.y + this.radius
        const tonic = Music.addInterval(key.tonic, Music.interval_6th)
        const note = this.buildNote(x, y, tonic)
        key.notes.aeolian = note
        this.bondNoteToKey(key, 6, key.notes.aeolian)
    }
    buildLocrian = (key:HKMKeyData) => {
        const x = key.x
        const y = key.y
        const tonic = Music.addInterval(key.tonic, Music.interval_7th)
        const note = this.buildNote(x, y, tonic)
        key.notes.locrian = note
        this.bondNoteToKey(key, 7, key.notes.locrian)
    }
    getFlatSixthKey = (rowIndex:number, columnIndex:number) =>{
        if(rowIndex === 0){ // first row
            return null
        }
        if(rowIndex % 2 !== 0){ // even row
            if(columnIndex === 0){ // first key
                return null
            }
            else{
                return this.keys[rowIndex-1][columnIndex-1]
            } 
        }
        else{ // odd row 
            return this.keys[rowIndex-1][columnIndex]
        }
    }
    getFourthKey = (rowIndex:number, columnIndex:number) =>{
        if(rowIndex === 0){ // first row
            return null
        }
        if(rowIndex % 2 !== 0){ // even row
            return this.keys[rowIndex-1][columnIndex]
        }
        else{ // odd row
            if(columnIndex === this.columns - 1){
                return null
            } // last key
            return this.keys[rowIndex-1][columnIndex + 1]
        }
    }
    getPreviousKey = (rowIndex:number, columnIndex:number) => {
        if(columnIndex === 0){
            return null
        }
        return this.keys[rowIndex][columnIndex - 1]
    }
    getVisibleKeys(){
        return this.keys.flat().filter((key:HKMKeyData)=>{
            return key.visible === true
        })
    }
    getVisibleNotes(){
        return this.notes.filter((note)=>{
            return note.visible === true
        })
    } 
    showNotesForVisibleKeysOnly = () => {
        let notes:HKMNoteData[] = this.notes
        let keys:HKMKeyData[] = this.getVisibleKeys()
        notes.forEach((note:HKMNoteData)=>{
            let hasVisibleKey:boolean = false
            for (let key of keys) {               
                if(key.notes.hasNoteByIndex(note.noteIndex)){
                    hasVisibleKey = true
                    break
                } 
            }
            note.visible = hasVisibleKey
        }) 
    } 
    showNotesForOpenKeysOnly = () => {
        let notes:HKMNoteData[] = this.notes
        let keys:HKMKeyData[] = this.getVisibleKeys()
        notes.forEach((note:HKMNoteData)=>{
            let hasOpenKey:boolean = false
            for (let key of keys) {               
                if(key.open && key.notes.hasNoteByIndex(note.noteIndex)){
                    hasOpenKey = true
                    break
                } 
            }
            note.visible = hasOpenKey
        }) 
    } 

    getNeighborsForKey = (key:HKMKeyData) => {
        let neighbors:KeyNeighbors = {}
        const isEven:boolean = ((key.rowIndex % 2) === 0)
        if(key.columnIndex > 0){
            neighbors.w = this.keys[key.rowIndex][key.columnIndex-1]
        }
        if(key.columnIndex < this.columns - 1){
            neighbors.e = this.keys[key.rowIndex][key.columnIndex+1]
        }
        if(key.rowIndex > 0){ // get top values
            const nwColumnIndex:number = (isEven) ? key.columnIndex : key.columnIndex - 1
            if(key.columnIndex > 0) {
                neighbors.nw = this.keys[key.rowIndex-1][nwColumnIndex]
            }
            if(key.columnIndex < this.columns-1) {
                neighbors.ne = this.keys[key.rowIndex-1][nwColumnIndex + 1]
            }
        }
        if(key.rowIndex < this.rows - 1){ // get bottom values
            const swColumnIndex:number = (isEven) ? key.columnIndex : key.columnIndex-1
            if(key.columnIndex > 0) {
                neighbors.sw = this.keys[key.rowIndex+1][swColumnIndex]
            }
            if(key.columnIndex < this.columns-1) {
                neighbors.se = this.keys[key.rowIndex+1][swColumnIndex + 1]
            }
        }




        return neighbors
    }



/*

foreach state add timeline moment where that state is set

create a timeline, on each point is a setState({key:0, positions:[5]})
timeline  = new timeline
for each state, add event on timeline ()={setState({key:0, positions:[5]})}

build this chain of states
create timeline entries to set state using

{key:0, positions:[5]},
{key:0, positions:[1]}
{key:8, positions:[5]},
{key:8, positions:[1]},
{key:4, positions:[2]}
{key:4, positions:[5]}
{key:4, positions:[1]}
*/


    selectKeyAndShowPositions = (keyTonic:number, iteration:number, positions:number[]) => {
        const key:HKMKeyData | undefined = this.getKeyFromTonic(keyTonic, iteration)
        if(key){
            key.visible = true
            key.selected = true
            positions.forEach((position:number)=>{
                const note:HKMNoteData = this.getNoteFromPosition(key, position)
                note.visible = true
                note.selected = true
                note.hilited = true
            })
        }
    }
    showAllKeysByTonic = (tonics:number[]) => {
        const keysWithTonic:HKMKeyData[] = this.keys.flat().filter((key:HKMKeyData)=>{
            return tonics.includes(key.tonic)
        })
        keysWithTonic.forEach((key:HKMKeyData)=>{
            key.visible = true
            key.hilited = false
            key.selected = false
        })
    }
    getKeyFromTonic = (keyTonic:number, iteration:number = 1):HKMKeyData | undefined => {
        let foundNum:number = 0
        let foundKey:HKMKeyData | undefined = undefined
        this.keys.flat().forEach( 
            (key:HKMKeyData) => {
                    if (key.tonic === keyTonic){
                        foundNum = foundNum + 1
                        if(foundNum === iteration){
                            foundKey = key
                        }
                    }
            }
        )  
        if(foundKey === undefined){
            console.warn('key not found tonic and interation', keyTonic)
            console.warn('- interation', iteration)
        }
        return foundKey
    }
    getNoteFromPosition = (key:HKMKeyData, position:number) => {
        const noteIndex:number = key.notes.getNoteIndexes()[position-1]
        return this.notes[noteIndex]
    }
    hideKeys = (keysToHide:number[][]) => {
        keysToHide.forEach(([rowCount, columnCount])=>{
            this.keys[rowCount][columnCount].visible = false
        })
    } 
    hideAllKeys = () => {
        this.keys.flat().forEach((key:HKMKeyData)=>{
            key.visible = false
        })
    }
    openKeys = (keysToOpen:number[][]) => {
        keysToOpen.forEach(([rowCount, columnCount])=>{
            this.keys[rowCount][columnCount].open = true
        })
        this.showNotesForOpenKeysOnly()
    } 
    openAllKeys = () => {
        this.keys.flat().forEach((key:HKMKeyData)=>{
            key.open = true
        })
    }
    closeKeys = (keysToClose:number[][]) => {
        keysToClose.forEach(([rowCount, columnCount])=>{
            this.keys[rowCount][columnCount].open = false
        })
    }
    closeAllKeys = () => {
        this.keys.flat().forEach((key:HKMKeyData)=>{
            key.open = false
        })
    }
    onButtonInput = (noteIndex:number) =>{
    }
    defineAllNotesAs = (isDefined:boolean) => {
        this.notes.forEach((note:HKMNoteData)=>{
            note.defined = isDefined
        })
    }
    defineAllKeysAs = (isDefined:boolean) => {
        this.keys.flat().forEach((key:HKMKeyData)=>{
            key.defined = isDefined
        })
    }
    defineAllNotesForKey = (rowIndex:number, columnIndex:number) => {
        const key:HKMKeyData = this.keys[rowIndex][columnIndex]
        const noteIndexes:number[] = key.notes.getNoteIndexes()
        noteIndexes.forEach((noteIndex:number) => {
            this.notes[noteIndex].defined = true
        })
    }
    hideAllNotes = () => {
        this.notes.forEach((note:HKMNoteData)=>{
            note.visible = false
        })
    }
    hideNotes = (hiddenNotes:number[]) => {
        hiddenNotes.forEach((noteIndex:number)=>{
            this.notes[noteIndex].visible = false
        })
    }
    hiliteNotes = (hilitedNotes:number[]) => {
        hilitedNotes.forEach((noteIndex:number) => {
            this.notes[noteIndex].hilited = true
        })
    }
    hiliteAllNotes = (hilited:boolean = true) => {
        this.notes.forEach((note:HKMNoteData) => {
            note.hilited = hilited
        })
    }
    hiliteNote = (hilitedNoteIndex:number) => {
        const note:HKMNoteData = this.notes[hilitedNoteIndex]
        note.hilited = true
        note.visible = true
    }
    setAllKeysHilited = (hilited:boolean = true) => {
        this.keys.flat().forEach((key:HKMKeyData)=>{
            key.hilited = hilited
        })
    }
    setSelectedKeys = (keyCoordinateSet:number[][]) => {
        keyCoordinateSet.forEach((keyCoordinates)=>{
            const key:HKMKeyData = this.keys[keyCoordinates[0]][keyCoordinates[1]]
            key.selected = true
        })
    }
    deselectAllKeys = () => {
        this.keys.flat().forEach((key:HKMKeyData) => {
            key.selected = false
        })
    }
    selectAllKeys = () => {
        this.keys.flat().forEach((key:HKMKeyData) => {
            key.selected = true
        })
    }
 
    deselectAllNotes = () => {
        this.notes.forEach((note:HKMNoteData) => {
            note.selected = false
        })
    }   
    selectNotes = (noteIndexes:number[]) => {
        noteIndexes.forEach((noteIndex:number)=>{
            this.notes[noteIndex].selected = true
        })
    }
    selectSingleNote = (noteIndex:number) => {
        this.notes.forEach((note:HKMNoteData) => {
            note.selected = (note.noteIndex === noteIndex)
        })
    }
}