import { Music } from "../Music/Music"

export class LabelTypes{
  static byNotePosition:number = -1
  static byNoteIndex:number = -2
  static byNoteName:number = -3
}
export class CourseState{
    currentChapter:number
    accidental:number
    setter:Function
    tocIsOpen:boolean
    showNotesAsPositions:boolean = false
    labelType:number
    constructor(setter:Function){
      this.setter = setter
      this.labelType = LabelTypes.byNoteName
      this.currentChapter = 0
      this.accidental = Music.sharp
      this.tocIsOpen = false
    }
    setLabelType = (type:number) => { 
      return(this.setState({ labelType: type }) ) 
    }
    setShowNotesAsPosition = (show:boolean) => { 
      return(this.setState({ showNotesAsPositions: show }) ) 
    }
    setCurrentChapter = (chapterNumber:number) => { 
      return(this.setState({ currentChapter: chapterNumber }) ) 
    }
    setAccidental = (accidental:number) => {
      return(this.setState({ accidental: accidental }) )
    }
    setTocIsOpen = (isOpen:boolean) => {
      return(this.setState({ tocIsOpen: isOpen }) )
    }
    toggleTocIsOpen = (state:CourseState) => {
      this.setTocIsOpen(!state.tocIsOpen)
    }
    closeToc = (state:CourseState) => {
      this.setTocIsOpen(false)
    }
    getNoteLabels = (state:CourseState) => {
      const labels:string[] = Music.noteLabels[state.accidental]
      return labels
    }
    getNoteLabel = (state:CourseState, index:number) => {
      return this.getNoteLabels(state)[index]
    }
    setState = (obj:any) => {
      this.setter((state:CourseState) => (obj))
    }
  }