import { Course } from "../Course/Course"
import { SiteIntro } from "../SiteIntro/SiteIntro"
import { Login } from "../Login/Login"
import { useProjectStore } from "./ProjectStore"

export function Project(){ 
    const store = useProjectStore()
    const getMainComponent = () =>{
        return (store.loggedIn) ? <Course /> : <SiteIntro />
    }
    return <div>{getMainComponent()}</div>
       
}