import { useEffect, useState } from "react"
import { HKMKeyComponent } from "../../HKMap/HKMKeyComponent"
import { HKMapComponentProps } from '../../HKMap/HKMapComponentProps'
import { HKMNoteIntervalComp } from './HKMNoteIntervalComp'

export function HKSequenceFlowComponent(props:HKMapComponentProps){
    let [keys, setKeys] = useState(props.visibleKeys)
    let [notes, setNotes] = useState(props.visibleNotes)
    let keyComps = keys.map((key, index)=>{
        return <HKMKeyComponent key={index} keyData={key}/>
    })
    let noteComps = notes.map((note, index)=>{
        return <HKMNoteIntervalComp key={index} noteData={note}/>
    })
    useEffect(()=>{
        setKeys(props.visibleKeys)
        setNotes(props.visibleNotes)
    })
    return <svg className='hkmap' width={props.compWidth} height={props.compHeight}>
        {keyComps}
        {noteComps}
    </svg>
}