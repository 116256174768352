import { HKMapComponent } from "../../HKMap/HKMapComponent";
import { HKMapData } from "../../HKMap/HKMapData";
import { HKMKeyData } from "../../HKMap/HKMKeyData";
import React from "react";
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase";
import { IntervalButtons } from "./IntervalButtons";
import '../KeyBuilderExercise/KeyBuilderExercise.css'
import './HexKeyCompassExercise.css'
export class HexKeyCompassExerciseState{
    visibleKeys:HKMKeyData[]
    lastAnswerCorrect:boolean = false
    lastAnswer:string = ''
    completed:boolean = false
    constructor(visibleKeys:HKMKeyData[], ){
        this.visibleKeys = visibleKeys
    }
}
export class HexKeyCompassExercise extends React.Component{
    rows:number = 3
    columns:number = 3 
    radius:number = 50 
    mapData:HKMapData 
    tonic:number  
    selectedKeyCoordinates:number[] = [1,1]
    selectedKey:HKMKeyData | null
    state:HexKeyCompassExerciseState
    directions:number[][] = [[0,8,5],[3,0,9],[0,7,4]]
    quizMode:boolean = false
    constructor(props:any){
        super(props)
        this.tonic = this.getRandomTonic()
        this.selectedKey = null
        this.mapData = this.getMapData()
        if(this.quizMode){
            this.selectRandomKey()
        }
        this.state = this.getState()
    }
    selectRandomKey = () => {
        this.mapData.deselectAllKeys()
        const selectedKey:HKMKeyData | null = this.getRandomUnselectedKey()
        if(selectedKey == null) return
        selectedKey.selected = true
        this.selectedKey = selectedKey
    }

    getState = () => {
        return new HexKeyCompassExerciseState(this.mapData.getVisibleKeys())
    }
    onKeyClick = (key:HKMKeyData) => {
        if(key.defined || this.quizMode) return
        this.mapData.deselectAllKeys()
        key.selected = true;
        this.selectedKey = key
        this.setState({visibleKeys:this.mapData.getVisibleKeys()})
    }
    getCenterKey = () => {
        return this.mapData.keys[1][1]
    }
    getRandomUnselectedKey = () => {
        const unselectedKeys:HKMKeyData[] = this.mapData.getVisibleKeys().filter((key:HKMKeyData)=>{
            return ((key !== this.getCenterKey()) && (key !== this.selectedKey) && !key.defined)
        })
        if(unselectedKeys.length === 0){
            return null
        }
        const randomIndex:number = (unselectedKeys.length === 1)? 0 : Math.floor(Math.random() * unselectedKeys.length)
        const key:HKMKeyData = unselectedKeys[randomIndex]
        return key
    }
    onWrongAnswer = (intervalName:string) => {  
    }
    onRightAnswer = (intervalName:string) => {
        if(!this.selectedKey) return
        this.selectedKey.defined = true
        if(this.quizMode){
            this.selectRandomKey()
        }
        this.setState(  {
                            visibleKeys:this.mapData.getVisibleKeys(), 
                            lastAnswer:intervalName,
                            lastAnswerCorrect:true,
                            completed:this.isComplete()
                        })
    } 
    onButtonInput = (rowIndex:number, columnIndex:number, intervalName:string) => {
        if(this.selectedKey === null) return
        if(this.selectedKey.columnIndex === columnIndex && this.selectedKey.rowIndex === rowIndex){
            this.onRightAnswer(intervalName)
        }
        else{
            this.onWrongAnswer(intervalName)
        }
    }
    onNoteClick = () => {}

    getMapData = () => {
        let mapData:HKMapData = new HKMapData(this.rows, this.columns, this.tonic, this.radius, this.onKeyClick, this.onNoteClick)
        mapData.defineAllKeysAs(false)
        mapData.hideKeys([[0,2],[2,2]])
        mapData.keys[1][1].defined = true
        mapData.setSelectedKeys([[1,1]])
        mapData.keys[1][1].hilited = true
        mapData.keys[0][0].predefinedLabel = 'b6th'
        mapData.keys[0][1].predefinedLabel = '4th'
        mapData.keys[1][0].predefinedLabel = 'b3rd'
        mapData.keys[1][1].predefinedLabel = ''
        mapData.keys[1][2].predefinedLabel = '6th'
        mapData.keys[2][0].predefinedLabel = '5th'
        mapData.keys[2][1].predefinedLabel = '3rd'
        return mapData
    }
    isComplete = () => {
        const visKeys:HKMKeyData[] = this.mapData.getVisibleKeys()
        const definedKeys:HKMKeyData[] = visKeys.filter((key:HKMKeyData)=>{
            return key.defined
        })
        return (visKeys.length === definedKeys.length )
    }
    reset = () => {
        this.selectedKey = null
        this.mapData = this.getMapData()
        if(this.quizMode){
            this.selectRandomKey()
        }
        const newState:HexKeyCompassExerciseState = this.getState()
        this.setState(newState)
    }
    getRandomTonic = () => {
        return Math.floor(Math.random() * 12)
    }
    toggleQuizMode = (e:React.MouseEvent) => {
        e.stopPropagation()
        this.quizMode = !this.quizMode
        this.reset()
    }
    getCurrentMode = () => {
        return ( (this.quizMode) ? "Quiz Mode" : "Builder Mode" )
    }

    render(): React.ReactNode {
        const quizModeButtonLabel:string = (this.quizMode) ? 'Enter Builder Mode' : 'Enter Quiz Mode'
        const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(this.radius, this.rows, this.columns)
        return  <div className='map-builder-exercise'>
                    <div className='centered'><p>Current Mode: {this.getCurrentMode()}</p></div>
                    <div className='centered'>
                        <button type='button' onClick={this.toggleQuizMode}>{quizModeButtonLabel}</button> 
                    </div>   
        <HKMapComponent classNames={['exercise-compass']} compWidth={compWidth} compHeight={compHeight} visibleKeys={this.state.visibleKeys} visibleNotes={[]}/>
        <Message completed={this.state.completed} correct={this.state.lastAnswerCorrect} intervalName={this.state.lastAnswer}/>
        <IntervalButtons onClick={this.onButtonInput}/>
        <div className='centered'>
            <button type='button' onClick={(e:React.MouseEvent)=>{e.stopPropagation();this.reset()}}>reset</button>
        </div>
    </div>       
    }

 
}
interface MessageProps{
    intervalName:string
    correct:boolean
    completed:boolean
}
function Message(props:any){
    let message:string = ' - '
    if(props.tonic !== -1 && props.intervalName !== ''){
        const isCorrect:string = (props.correct) ? 'correct' : 'incorrect'
        message = props.intervalName + ' is ' + isCorrect + '.'
    } 
    if(props.completed){
        message += "You've completed the Directional Map!"
    }
    return <div className='message'>{message}</div>
}