import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteData } from "./HKMNoteData"

export class KeyNoteRelationship{
    key:HKMKeyData
    position:number
    constructor(key:HKMKeyData, position:number){
        this.key = key
        this.position = position
    }
}
/*

getPositionForSelectedParentKey(){
    return this.keyRelationships.forEach((relationship)=>{
        if(relationship.key.selected){
            return relationship.position
        }

        break
    })
}
const noteIndex:
keys.relationships.filter((relationship)=>{
    relationship.noteIndex === 
})

*/