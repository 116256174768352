import React from "react"
import { Link} from "react-router-dom"
import {useNavigate} from "react-router-dom"
import { DiatonicChords } from "../Chapters/DiatonicChords/DiatonicChords"
import { HelperPatterns } from "../Chapters/HelperPatterns/HelperPatterns"
import { HexKeyStructure } from "../Chapters/HexKeyStructure/HexKeyStructure"
import { HexMap } from "../Chapters/HexMap/HexMap"
import { SimplifiedHexMap } from "../Chapters/SimplifiedHexMap/SimplifiedHexMap"
import { Intro } from "../Chapters/Intro/Intro"
import { KeyChanges } from "../Chapters/KeyChanges/KeyChanges"
import { FiveFiveColumns } from "../Chapters/Layouts/TwoColumn"
import { Modes } from "../Chapters/Modes/Modes"
import { PentatonicScales } from "../Chapters/PentatonicScales/PentatonicScales"
import { HexKeyCompassExercisePage } from "../Exercises/HexKeyCompassExercise/HexKeyCompassExercisePage"
import { HexKeyMemorizerPage } from "../Exercises/HexKeyMemorizer/HexKeyMemorizerPage"
import { IntervalExercisePage } from "../Exercises/IntervalExercise/IntervalExercisePage"
import { KeyBuilderExercisePage } from "../Exercises/KeyBuilderExercise/KeyBuilderExercisePage"
import { MapBuilderExercisePage } from "../Exercises/MapBuilderExercise/MapBuilderExercisePage"
import { NeighborMemorizerPage } from "../Exercises/NeighborMemorizer/NeighborMemorizerPage"
import { SequenceExercisePage } from "../Exercises/SequenceExercise/SequenceExercisePage"
import { SimplifiedMapExercisePage } from "../Exercises/SimplifiedMapExercise/SimplifiedMapExercisePage"
import { WanderingKeyExercisePage } from "../Exercises/WanderingKey/WanderingKeyExercisePage"
import { ConclusionPage } from "../Chapters/Conclusion/ConclusionPage"

export interface CoursePageProps{
    index:number
}
export class CoursePage{
    path:string
    title:string
    component:Function
    constructor(path:string, title:string, component:Function){
        this.path = path
        this.title = title
        this.component = component
    }
}
export class CourseNavigation{
    static getChapters = () => {
        const chapters:CoursePage[] = [
            new CoursePage("/Intro", "Intro to HexKey Musical Geometry", Intro),
            new CoursePage("/HelperPatterns", "Helper Patterns", HelperPatterns),
            new CoursePage("/HexKeyStructure", "The HexKey Structure", HexKeyStructure),
            new CoursePage("/DiatonicChords", "Building Diatonic Chords", DiatonicChords),
            new CoursePage("/Modes", "The Modes and Relative Keys", Modes),
            new CoursePage("/PentatonicScales", "The Pentatonic Scale", PentatonicScales),
            new CoursePage("/HexMap", "Building the HexMap", HexMap),
            new CoursePage("/HexMapSimplified", "The Simplified HexMap", SimplifiedHexMap),
            new CoursePage("/KeyChanges", "The HexMap and Key Changes", KeyChanges),
            new CoursePage("/Conclusion", "Conclusion", ConclusionPage)
        ]
        return chapters
    }
    static getConceptualExercises = () => {
        const exercises:CoursePage[] = [
            new CoursePage("/KeyBuilderExercisePage", "The HexKey Builder Exercise", KeyBuilderExercisePage),
            new CoursePage("/MapBuilderExercisePage", "The HexMap Exercise", MapBuilderExercisePage),
            new CoursePage("/SimplifiedMapExercisePage", "The Simplifed HexMap Exercise", SimplifiedMapExercisePage),
        ]
        return exercises
    }
    static getMemorizationExercises = () => {
        const exercises:CoursePage[] = [
            new CoursePage("/HexKeyMemorizer", "The HexKey Memorizer", HexKeyMemorizerPage),
            new CoursePage("/NeighborMemorizer", "The HexMap Neighbor Memorizer", NeighborMemorizerPage),
            new CoursePage("/WanderingKeyExercise", "The Wandering Key Exercise", WanderingKeyExercisePage)
        ]
        return exercises
    } 
    static getHelperPatternExercises = () => {
        const exercises:CoursePage[] = [
            new CoursePage("/SequenceMemorizer", "The HexKey Sequence Memorizer", SequenceExercisePage),
            new CoursePage("/IntervalMemorizer", "The HexKey Interval Memorizer", IntervalExercisePage),
            new CoursePage("/HexKeyCompassMemorizer", "The HexMap Compass Memorizer", HexKeyCompassExercisePage),
        ]
        return exercises
    }    
}
export interface CourseNavigationLinkProps{
    index:number
}

export function ExerciseBackLink(){
    const nav = useNavigate()
    const goBack = (e:React.MouseEvent) => {
        e.stopPropagation()
        nav(-1)
    }
    return  <div className='exercise-back-link'>
                <button type='button' onClick={goBack}>Back to Previous Page</button>
            </div>
}

export function CoursePageNavigationLink(props:CoursePageProps){
    const getPrevious = () => {
        if(props.index === 0) return <div></div>
        const pageData:CoursePage = CourseNavigation.getChapters()[props.index - 1]
        return  <div className="previous">
                    Previous Chapter: <Link to={pageData.path}>{pageData.title}</Link>
                </div>
    }
    const getNext = () => {
        const chapters:CoursePage[] = CourseNavigation.getChapters()
        if(props.index === chapters.length - 1) return <div></div>
        const pageData:CoursePage = chapters[props.index + 1]
        return  <div className="next">
                    Next Chapter: <Link to={pageData.path}>{pageData.title}</Link>
                </div>
    }
    return  <FiveFiveColumns classes={'course-nav'}>
                {getPrevious()}
                {getNext()}
            </FiveFiveColumns>
}