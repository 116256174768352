import '../chapters.css'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { Link } from 'react-router-dom'
import { useNoteName } from '../../Music/Accidentals/Accidentals'
import { ChordBuilderBase } from '../../KeyBuilderWalkThrough/ChordBuilderBase'
import { HKMKeyChordHelper } from '../../HKMap/HKMKeyChordHelper'
import { Music } from '../../Music/Music'
import { CoursePageNavigationLink, CoursePageProps } from '../../Course/CourseNavigation'
import { SideBarBlock } from '../Layouts/SideBarBlock'
import { BaseRowBlock } from '../Layouts/TwoColumn'
import { FullChannel } from '../Layouts/FullChannel'

export function Modes(props:CoursePageProps){
    return(
        <div id="chapter-six" className="chapterBlock">
            <div className="row">
                <div className="fullChannel column">
                    <p>The concept of a mode is fairly simple. It's a type of relative key. We use the same notes as the parent key, but use a different note as the root note for each of the seven modes. This modal root note is what we use as the starting point to create each mode's modal scale.</p>
                    <p> To build a mode's modal scale we start at the assigned root and work our way around the notes of the parent key. Each of the seven modes of a parent key use the exact same notes, in the same relative order, but starting at a different root note.</p>
                    <p>What makes modes so useful is that each mode has a unique interval sequence. This gives the mode it's own musical character. We can think of each of the seven modes as the seven personalities of the parent key. We can mix and match these personalities to give our playing and writing a wider spectrum of expression.</p>
                    <h3>Modes vs Major Keys & Relative Minor Keys:</h3> 
                    <p>Modes and relative keys are, in a sense, the same thing. For example the Ionian mode is identical to the major key itself, since they both use the 1 note as their root. The Aeolian mode is identical to the relative minor key. They both use the 6 note as their root. However there are two significant issues with the major key/relative minor key convention: </p>
                    <ol>
                        <li>It can lead us into thinking that major keys and minor keys are distinct keys. They are not. They are modes of the same parent key. Seeing them as distinct keys obscures the underlying conceptual pattern at work. It also doubles the amount of information we have to memorize.</li>
                        <li>
                        The major key/relative minor key convention also doesn't address the use of the other five notes as root notes for 'relative keys'. Addressing only two of seven modes makes it an incomplete concept. It limits our musical understanding and ulimately narrows our options for musical expression.
                        </li>
                    </ol>
                    <p>This is why HexKey Musical Geometry strongly prefers the concept of modes. It addresses all seven 'relative keys'. It gives us the complete picture and better illustrates the underlying pattern. Once you learn the modes, you can translate back to the notion of a major key or relative minor key with ease.</p>
                    <p>Our mantra is: <span className='bold'>"We're always in a key and we're always in a mode"</span>. If you choose to look at it this way, things will seem much clearer from the start.</p>
                    <p>Let's go through the seven modes and see how to build them. We'll examine their interval patterns and desribe their unique musical qualities. Its important to know the HexKey Sequence well in order to follow and understand the graphic examples below.</p>    
                </div>
            </div>
            <BaseRowBlock title='The Ionian Mode (aka The Major Scale)'>
                <div>
                    <p>The Ionian mode starts on the one note and ends on the 7 note.</p> 
                    <p>It's a major mode since it's interval from root to 3rd is 4. Since it's note and interval sequences are identical to the major scale itself, it's synonymous with the major scale. D major is the same thing as D Ionian. Try to think of it as a mode, though.</p>
                    <p>It's character is solid, uncomplicated and happy. Most traditional songs are in Ionian</p>
                    <p>It's modal scale using parent key positions: 1, 2, 3, 4, 5, 6, 7</p>
                    <p>In the key of D, the D Ionian scale is: D, E, F#, G, A, B, C#</p> 
                    <p>It's interval pattern is 2212221</p> 
                </div>
                <SideBarBlock title={'The Ionian Scale Sequence'}> 
                    <ChordBuilderBase 
                            radius={80} 
                            hilitedNotes={[0]}
                            selectedNotes={[]}  
                            definedNotes={[0,1,2,3,4,5,6]}
                            definedArrows={[1,2,3,4,5,6]}
                            selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='The Dorian Mode'>
                <div>
                    <p>The Dorian gives us a more useful example of a mode. It starts on the two note and loops around the key, ending on the one note.</p> 
                    <p>Its a minor mode since the interval distance from it's root to it's 3rd is 3. It's character is minor-ish with a lighter, spacey, more pensive feel. This is due to it's sixth note being one interval higher than the other minor modes. If we emphasize that sixth note, we're emphasizing Dorian's musical character.</p>
                    <p>It's modal scale, using parent key positions is: 2, 3, 4, 5, 6, 7, 1</p>
                    <p>In the key of D, the E Dorian scale is: E, F#, G, A, B, C#, D</p> 
                    <p>It's interval pattern is 2122212</p>  
                </div>
                <SideBarBlock title={'The Dorian Scale Sequence'}> 
                    <ChordBuilderBase 
                        radius={80} 
                        hilitedNotes={[1]}
                        selectedNotes={[]}  
                        definedNotes={[0,1,2,3,4,5,6]}
                        definedArrows={[0,2,3,4,5,6]}
                        selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='The Phrygian Mode'>
                <div>                     
                    <p>The Phrygian mode starts on the three note and ends on the two note.</p>
                    <p>It's a minor mode as well. It's character is minor-ish, but heavier and darker. This is due to its 2nd note being one interval lower than the other minor modes. Emphasizing it's lowered 2nd brings out Phrygian's character.</p> 
                    <p>It's modal scale: 3, 4, 5, 6, 7, 1, 2</p>
                    <p>In the key of D, the {useNoteName(Music.Gb)} Phrygian scale is: {useNoteName(Music.Gb)}, G, A, B, {useNoteName(Music.Bb)}, D, E.</p> 
                    <p>It's interval pattern is 1222122</p>
                </div>
                <SideBarBlock title={'The Phrygian Scale Sequence'}><ChordBuilderBase 
                                radius={80} 
                                hilitedNotes={[2]}
                                selectedNotes={[]}  
                                definedNotes={[0,1,2,3,4,5,6]}
                                definedArrows={[3,4,5,6,0,1]}
                                selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='The Lydian Mode'>
                <div>
                    <p>The Lydian mode starts on the four note and ends on the three note.</p>
                    <p>It's a major mode. It's character is major-ish but with a lighter, sci-fi quality. This is due to it's 4th note being one interval higher than in the other major modes. Emphasize the 4th to make the Lydian sound.</p> 
                    <p>It's modal scale: 4, 5, 6, 7, 1, 2, 3</p>
                    <p>In the key of D, the G Lydian scale is: G, A, B, C#, D, E, F#</p> 
                    <p>It's interval pattern is 2221221</p>
                </div>
                <SideBarBlock title={'The Lydian Scale Sequence'}><ChordBuilderBase 
                                radius={80} 
                                hilitedNotes={[3]}
                                selectedNotes={[]}  
                                definedNotes={[0,1,2,3,4,5,6]}
                                definedArrows={[4,5,6,0,1,2]}
                                selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='The Mixolydian Scale Sequence'>
                <div><p>The Mixolydian mode starts on the five note and ends on the four note.</p> 
                    <p>It's a major mode. It's character is major-ish with a heavier, rockish feel to it. This is due to it's 7th note being one interval lower than the other major modes. Because of this, it's interval distance from the 7th back to the 1st is two instead of one. Playing the 6 to 7 and 7 to 1 intervals gives us the Mixolydian sound.</p>
                    <p>It's modal scale: 5, 6, 7, 1, 2, 3, 4</p>
                    <p>In the key of D, the A Mixolydian scale is: A, B, C#, D, E, F#, G</p> 
                    <p>It's interval pattern is 2212212</p></div>
                <SideBarBlock title={'The Mixolydian Scale Sequence'}><ChordBuilderBase 
                                radius={80} 
                                hilitedNotes={[4]}
                                selectedNotes={[]}  
                                definedNotes={[0,1,2,3,4,5,6]}
                                definedArrows={[5,6,0,1,2,3]}
                                selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='The Aeolian Mode (The Relative Minor)'>
                <div><p>The Aeolian mode starts on the six note and ends on the five note.</p> 
                    <p>It's character is somewhat dark and unsettled - the standard minor sound. Aeolian is used heavily in modern music, second only to Ionian. Most songs that are somewhat dark and edgy are Aeolian. It's synonymous with the relative minor key since both are rooted on the 6th note. The relative key of B minor is the same thing as B Aeolian.</p>
                    <p>It's modal scale: 6, 7, 1, 2, 3, 4, 5</p>
                    <p>In the key of D, the B Aeolian scale is: B, C#, D, E, F#, G, A</p> 
                    <p>It's interval pattern is 2122122</p></div>
                <SideBarBlock title={'The Aeolian Scale Sequence'}>
                    <ChordBuilderBase 
                        radius={80} 
                        hilitedNotes={[5]}
                        selectedNotes={[]}  
                        definedNotes={[0,1,2,3,4,5,6]}
                        definedArrows={[6,0,1,2,3,4]}
                        selectedArrows={[]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='The Locrian Mode'>
                <div>                    
                    <p>The Locrian mode starts on the 7 note and ends on the 6 note.</p>
                    <p>Although it has a minor third, it's character is more sinister and unsettling than the other minor modes. This is due to it's fifth not being a perfect fifth (7 intervals from the root). It's interval from the root to the fifth is 6 intervals.</p>
                    <p>A perfect fifth is an important component in the harmonic quality of a key. The lack of a perfect fifth makes locrian a difficult mode to work with. Playing it's lowered fifth creates a Locrian sound. Locrian is the least used mode in music.</p> 
                    <p>It's modal scale: 7, 1, 2, 3, 4, 5, 6</p>
                    <p>In the key of D, C# Locrian is: C#, D, E, F#, G, A, B</p> 
                    <p>It's interval pattern is 1221222</p>
                </div>
                <SideBarBlock title={'The Locrian Scale Sequence'}>
                    <ChordBuilderBase 
                            radius={80} 
                            hilitedNotes={[6]}
                            selectedNotes={[]}  
                            definedNotes={[0,1,2,3,4,5,6]}
                            definedArrows={[0,1,2,3,4,5]}
                            selectedArrows={[]}/></SideBarBlock>
            </BaseRowBlock>
            <FullChannel>
                <h3>Conclusion:</h3>
                <p>We can build each of the seven modes by choosing a root note within a parent key and following the HexKey sequence around that key. Each mode has a unique interval pattern giving it a unique musical quality. Modes and relative keys are a similar concept but modes provide us with a more complete picture.</p>
            </FullChannel>
            <FullChannel>
                <h3>A note on relative chord numbering in modes/relative keys</h3>
                <p>We can refer to a mode's chords using a naming scheme that uses the chord's relative position within the mode. In this scheme roman numerals are used to represent the chords position relative to the root note of the mode, instead of their position in the parent key.</p>
                <p>Upper case Roman numerals are used to represent major chords, lower case for minor chords, and lower case with a ø symbol for half-diminished chords.</p>
                <p>Ionian's relative chord seqeunce is: I, ii, iii, IV, V, vi, viiø</p>
                <p>Dorian's relative chord seqeunce is: i, ii, III, IV, v, viø, VII</p>
                <p>Both chord sequences list the same chords, but in a shifted order. In the key of D, D Ionian's minor 2nd (ii) and E Dorian's minor 1st (i) are in fact the same chord: E minor.</p>
                <p>Now we have another case where we have more than one way to name the same thing - much like A# and Bb being two names for the same note. This can be confusing for beginners. It's best to revisit this relative numbering system after you have a solid understanding of the modes.</p>
            </FullChannel>
        </div>
    )
}

