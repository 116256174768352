import { useState } from "react";
import { HKMapComponent } from "../../HKMap/HKMapComponent";
import { HKMapData } from "../../HKMap/HKMapData";
import { HKMKeyData } from "../../HKMap/HKMKeyData";
import { HKMNoteData } from "../../HKMap/HKMNoteData";
import { useNoteName } from "../../Music/Accidentals/Accidentals";
import { NoteButtons } from "../KeyBuilderExercise/NoteButtons";
import '../KeyBuilderExercise/KeyBuilderExercise.css'
import React from "react";
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase";
export class SimplifiedMapExerciseState{
    visibleKeys:HKMKeyData[]
    lastAnswerCorrect:boolean = false
    lastAnswer:number = -1
    completed:boolean = false
    constructor(visibleKeys:HKMKeyData[], ){
        this.visibleKeys = visibleKeys
    }
}
export class SimplifiedMapExercise extends React.Component{
    rows:number = 5
    columns:number = 5 
    radius:number = 35 
    mapData:HKMapData 
    tonic:number  
    selectedKeyCoordinates:number[] = [1,1]
    selectedKey:HKMKeyData | null
    state:SimplifiedMapExerciseState
    constructor(props:any){
        super(props)
        this.tonic = this.getRandomTonic()
        this.mapData = this.getMapData()
        this.selectedKey = null
        this.state = this.getState()
    }
    getState = () => {
        return new SimplifiedMapExerciseState(this.mapData.getVisibleKeys())
    }
    onKeyClick = (key:HKMKeyData) => {
        if(key.defined) return
        this.mapData.deselectAllKeys()
        key.selected = true;
        this.selectedKey = key
        this.setState({visibleKeys:this.mapData.getVisibleKeys()})
    }
    onWrongAnswer = (tonic:number) => {  
        this.setState(  { 
            lastAnswer:tonic,
            lastAnswerCorrect:false
        })
    }
    onRightAnswer = (tonic:number) => {
        if(!this.selectedKey) return
        this.selectedKey.defined = true
        this.setState(  {
                            visibleKeys:this.mapData.getVisibleKeys(), 
                            lastAnswer:tonic,
                            lastAnswerCorrect:true,
                            completed:this.isComplete()
                    
                        })
    } 
    onButtonInput = (tonic:number) => {
        if(this.selectedKey === null) return
        if(this.selectedKey.tonic === tonic){
            this.onRightAnswer(tonic)
            return
        }
        this.onWrongAnswer(tonic)
    }
    onNoteClick = () => {}

    getMapData = () => {
        let mapData:HKMapData = new HKMapData(this.rows, this.columns, this.tonic, this.radius, this.onKeyClick, this.onNoteClick)
        mapData.defineAllKeysAs(false)
        mapData.keys[1][1].defined = true
        mapData.setSelectedKeys([[1,1]])
        return mapData
    }
    isComplete = () => {
        const visKeys:HKMKeyData[] = this.mapData.getVisibleKeys()
        const definedKeys:HKMKeyData[] = visKeys.filter((key:HKMKeyData)=>{
            return key.defined
        })
        return (visKeys.length === definedKeys.length )
    }
    reset = (e:React.MouseEvent) => {
        e.stopPropagation()
        this.selectedKey = null
        this.tonic = this.getRandomTonic()
        this.mapData = this.getMapData()
        this.setState(this.getState())
    }
    getRandomTonic = () => {
        return Math.floor(Math.random() * 12)
    }

    render(): React.ReactNode {
        const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(this.radius, this.rows, this.columns)
        return  <div className='map-builder-exercise centered'>
        <HKMapComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={this.state.visibleKeys} visibleNotes={[]}/>
        <Message completed={this.state.completed} correct={this.state.lastAnswerCorrect} tonic={this.state.lastAnswer}/>
        <NoteButtons onClick={this.onButtonInput}/>
        <p><button type='button' onClick={this.reset}>reset</button></p>
    </div>       
    }

 
}
interface MessageProps{
    tonic:number
    correct:boolean
    completed:boolean
}
function Message(props:any){
    const noteName:string = useNoteName(props.tonic)
    let message:string = 'Select a key and identify it.'
    if(props.tonic !== -1){
        const isCorrect:string = (props.correct) ? 'correct' : 'incorrect'
        message = noteName + ' is ' + isCorrect + '.'
    } 
    if(props.completed){
        message += "You've completed the HexMap!"
    }
    return <div className='message'>{message}</div>
}