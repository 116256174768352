import React from "react";
import { HKMapComponent } from "../HKMap/HKMapComponent";
import { HKMapData } from "../HKMap/HKMapData";
import { HKMapWrapperBase } from "../HKMap/HKMapWrapperBase";
import { HKMKeyData } from "../HKMap/HKMKeyData";
import { HKMNoteData } from "../HKMap/HKMNoteData";
import { MapBuildereState, MapBuilderBaseProps } from "./MapBuilderBase";
export class MapBuilderAxisOfFifths extends React.Component{
    tonic:number = 11
    rows:number = 14
    columns:number = 8 
    radius:number = 22
    mapData:HKMapData
    state:MapBuildereState
    constructor(props:any){
        super(props)
        this.mapData = this.getMapData()
        this.state = this.getNewState()
    }
    onKeyClick = () => {}
    onNoteClick = () => {}
    getNewState = () => {
        return new MapBuildereState(this.getVisibleKeys(), this.getVisibleNotes())
    }
    getMapData = () => {
        let mapData:HKMapData = new HKMapData(this.rows, this.columns, this.tonic, this.radius, this.onKeyClick, this.onNoteClick)
        mapData.hideAllNotes()
        mapData.defineAllKeysAs(false)
        const displayedKeys:number[][] = [
            [1,6],[2,5],[3,5],[4,4],[5,4],[6,3],[7,3],[8,2],[9,2],[10,1],[11,1],[12,0]
        ]
        displayedKeys.forEach(
            (keyCoords:number[])=>{
                const key:HKMKeyData = mapData.keys[keyCoords[0]][keyCoords[1]]
                key.selected = true
                key.defined = true
            }
        )
        mapData.keys[0][6].defined = true
        mapData.keys[13][0].defined = true
        return mapData
    }
    getVisibleKeys = () => {return this.mapData.getVisibleKeys()}
    getVisibleNotes = () => {return this.mapData.getVisibleNotes()}
    render = () =>{
            const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(this.radius, this.rows, this.columns)
            return <div className='map-builder-exercise'>
                <HKMapComponent compWidth={compWidth} 
                                compHeight={compHeight} 
                                visibleKeys={this.state.visibleKeys} 
                                visibleNotes={this.state.visibleNotes}/>
            </div>
    }     
}
