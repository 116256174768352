import React from "react";
import { HKMapComponent } from "../../HKMap/HKMapComponent";
import { HKMapData } from "../../HKMap/HKMapData";
import { HKMapOneNote } from "../../HKMap/HKMapOneNote";
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase";
import { HKMKeyData } from "../../HKMap/HKMKeyData";
import { HKMNoteData } from "../../HKMap/HKMNoteData";
import { Music } from "../../Music/Music";

export interface GiantStepsKeyChangeMapProps{
    positions:number[]
    keyNote:number
    index:number
    radius?:number
    showPositions?:boolean
}


export class GiantStepsKeyChangeMap extends React.Component{
    tonic:number = Music.B
    radius:number = 38
    rows:number = 5
    columns:number = 3
    //mapData:HKMapData
    props!:GiantStepsKeyChangeMapProps
    constructor(props:GiantStepsKeyChangeMapProps){
        super(props)
        this.props = props
    }

    onKeyClick = () => {}
    onNoteClick = () => {}
    getSelectedKey = () => {}

    getMapData = () => {
        let mapData:HKMapData = new HKMapData(this.rows, this.columns, this.tonic, this.radius, this.onKeyClick, this.onNoteClick)
        mapData.hideAllNotes()
        mapData.hideAllKeys()
        mapData.showAllKeysByTonic([Music.B, Music.G, Music.Eb])
        mapData.deselectAllKeys()
        mapData.selectKeyAndShowPositions(this.props.keyNote, this.props.index, this.props.positions)
        mapData.notes.forEach((noteData:HKMNoteData)=>{
            noteData.presetLabel = String(noteData.getSelectedKeyPosition())
        })
        mapData.keys[1][1].predefinedLabel = 'Eb'
        mapData.keys[4][2].predefinedLabel = 'Eb'
        
        return mapData
    }
    render = () =>{
        const mapData:HKMapData = this.getMapData()
        const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(this.radius, this.rows, this.columns)
        return <div className='map-builder-exercise'>
                        <HKMapComponent compWidth={compWidth} 
                                compHeight={compHeight} 
                                visibleKeys={mapData.getVisibleKeys()} 
                                visibleNotes={mapData.getVisibleNotes()}/>
                    </div>
    }     
}
