import { useState } from "react";
import { HKMapComponent } from "../../HKMap/HKMapComponent";
import { HKMapData } from "../../HKMap/HKMapData";
import { HKMKeyData } from "../../HKMap/HKMKeyData";
import { HKMNoteData } from "../../HKMap/HKMNoteData";
import { useNoteName } from "../../Music/Accidentals/Accidentals";
import { NoteButtons } from "./NoteButtons";
import './KeyBuilderExercise.css'
import React from "react";
import { NotesSelect } from "./NotesSelect";
import { HKMNoteComp } from "../../HKMap/HKMNoteComp";
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase";
export class KeyBuilderExerciseState{
    visibleNotes:HKMNoteData[]
    visibleKeys:HKMKeyData[]
    message:string = 'aloha'
    lastAnswer:number = -1
    lastAnswerCorrect:boolean = false
    constructor(visibleKeys:HKMKeyData[], visibleNotes:HKMNoteData[]){
        this.visibleKeys = visibleKeys
        this.visibleNotes = visibleNotes
    }
}
export interface KeyBuilderExerciseProps{
    quizMode?:boolean
    updateTonic?:Function
}
export class KeyBuilderExercise extends React.Component{
    tonic:number = 0
    selectedNoteIndex:number = -1
    rows:number = 1
    columns:number = 1 
    radius:number = 110
    mapData:HKMapData
    state:KeyBuilderExerciseState
    props:KeyBuilderExerciseProps
    quizMode:boolean = false
    constructor(props:KeyBuilderExerciseProps){
        super(props)
        this.props = props
        if(props.quizMode){
            this.quizMode = true
        }
        this.mapData = this.getMapData()
        if(this.quizMode){
            this.selectRandomPosition()
        }
        this.state = this.getNewState()
    }
    selectRandomPosition = () => { 
        this.mapData.defineAllNotesAs(false)
        this.mapData.deselectAllNotes()
        this.mapData.notes[0].defined = true
        let choices:number[] = [1,2,3,4,5,6]
        const index:number = choices.indexOf(this.selectedNoteIndex)
        if(index > -1) choices.splice(index,1)
        const randomIndex:number = Math.floor(Math.random() * choices.length)
        const choice:number = choices[randomIndex]
        this.selectedNoteIndex = choice
        this.mapData.notes[this.selectedNoteIndex].selected = true
    }
    updateStateWithRandomPosition = () => {
        this.selectRandomPosition()
        this.setState(this.getNewState())
    }
    getNewState = () => {
        return new KeyBuilderExerciseState(this.getVisibleKeys(), this.getVisibleNotes())
    }
    updateNotes = () => {
        if(this.quizMode){
            this.updateStateWithRandomPosition()  
        }
        else{
            this.setState({visibleNotes:this.getVisibleNotes()})
        }
        
    }
    onKeyClick = (key:HKMKeyData) => {}
    onNoteClick = (note:HKMNoteData) => {
        if(!note.defined){
            this.selectedNoteIndex = note.noteIndex
            this.mapData.selectSingleNote(note.noteIndex)
            this.setState({visibleNotes:this.getVisibleNotes(), lastAnswer:-1})
        }
    }
    clearWrongAnswer = () => {
        this.setState({lastAnswer:-1, lastAnswerCorrect:false})
    }
    onWrongAnswer = (tonic:number) => {
        this.setState({lastAnswer:tonic, lastAnswerCorrect:false})
        setTimeout(this.clearWrongAnswer, 1000)
    }
    onRightAnswer = (tonic:number) => {
        if(this.quizMode){
            setTimeout(this.updateStateWithRandomPosition, 1000)
        }
        this.setState({lastAnswer:tonic, lastAnswerCorrect:true, visibleNotes:this.getVisibleNotes()})
    } 
    onButtonInput = (tonic:number) => {
        const selectedNote:HKMNoteData = this.mapData.notes[this.selectedNoteIndex]
        if(this.selectedNoteIndex === -1){
            return
        }
        if(selectedNote.tonic === tonic){
            selectedNote.defined = true
            selectedNote.selected = true
            this.onRightAnswer(tonic)
        }
        else{
            this.onWrongAnswer(tonic)
        }
    }

    getMapData = () => {
        let mapData:HKMapData = new HKMapData(this.rows, this.columns, this.tonic, this.radius, this.onKeyClick, this.onNoteClick)
        mapData.defineAllNotesAs(false)
        return mapData
    }

   getVisibleKeys = () => {return this.mapData.getVisibleKeys()}
   getVisibleNotes = () => {return this.mapData.getVisibleNotes()}

    onSelectTonic = (tonicNum:number) => {
        this.tonic = tonicNum
        this.onReset()
    }
    onReset = () => {
        this.mapData = this.getMapData()
        this.updateNotes()
    }
    onSelect = (tonicNum:number) => {
        this.onSelectTonic(tonicNum)
        if(this.props.updateTonic){
            this.props.updateTonic(tonicNum)
        }
    }
    getMessage = () => {
        // if(this.quizMode && this.state.lastAnswerCorrect) return null
        return <Message correct={this.state.lastAnswerCorrect} tonic={this.state.lastAnswer}/>
    }
    toggleQuizMode = () => {
        this.quizMode = !this.quizMode
        if(this.quizMode){
            this.updateStateWithRandomPosition()  
        }
        else{
            this.setState(this.getNewState())
        }
    }
    render = () =>{
            let [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(this.radius, this.rows, this.columns)
            return <div className='key-builder-exercise'>
                        <NotesSelect tonic={this.tonic} onChange={this.onSelect}/>
                        <HKMapComponent compWidth={compWidth} compHeight={compHeight} visibleKeys={this.state.visibleKeys} visibleNotes={this.state.visibleNotes}/>
                        <NoteButtons onClick={this.onButtonInput}/>
                        {this.getMessage()}            
                    </div>
    }     
}

export function Message(props:any){
    const noteName:string = useNoteName(props.tonic)
    let message:string = ' - '
    if(props.tonic !== -1){
        const isCorrect:string = (props.correct) ? 'correct' : 'incorrect'
        message = noteName + ' is ' + isCorrect
    } 
    return <div className='message'>{message}</div>
}
export function HideShowButton(){

}