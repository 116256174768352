import './HKMap.css'
import { useEffect, useState } from "react"
import { HKMKeyComponent } from "./HKMKeyComponent"
import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteComp } from "./HKMNoteComp"
import { HKMNoteData } from "./HKMNoteData"
import { HKMapComponentProps } from './HKMapComponentProps'
import { HKMChordIntervalComp } from './HKMChordIntervalComp'
import { HKMIntervalLabelComp } from '../Helpers/IntervalSequence/HKMIntervalLabelComp'
import { HKMapComponent } from './HKMapComponent'

export interface HKMapComponentWithIntevalsProps{
        visibleKeys:HKMKeyData[]
        visibleNotes:HKMNoteData[]
        compWidth:number
        compHeight:number
        definedArrows:number[]
        selectedArrows:number[]
}
export function HKMapComponentWithIntervals(props:HKMapComponentWithIntevalsProps){
    let [keys, setKeys] = useState(props.visibleKeys)
    let [notes, setNotes] = useState(props.visibleNotes)
    let keyComps = keys.map((key, index)=>{
        return <HKMKeyComponent key={index} keyData={key}/>
    })
    let noteComps = notes.map((note, index)=>{
        return <HKMNoteComp key={index} noteData={note}/>
    })
    const getArrowNotes = () => {
        let arrowNotes = notes.filter((note:HKMNoteData) => {
            return props.definedArrows.includes(note.noteIndex)
        })
        let arrowCloneNotes:HKMNoteData[] = []
        arrowNotes.forEach((arrowNote:HKMNoteData) => {
            let cloneNote:HKMNoteData = {...arrowNote}
            if(props.selectedArrows.includes(cloneNote.noteIndex)){
                cloneNote.selected = true
            }
            else{
                cloneNote.selected = false
            }
            arrowCloneNotes.push(cloneNote)
        })

        return arrowCloneNotes
    }
    const arrowNotes:HKMNoteData[] = getArrowNotes()
    const selectedArrowNotes = arrowNotes.filter((arrowNote:HKMNoteData) => {
        return props.selectedArrows.includes(arrowNote.noteIndex)
    })
    let arrowComps = arrowNotes.map((arrowNote:HKMNoteData, index)=>{
        return <HKMChordIntervalComp key={index} noteData={arrowNote}/>
    })
    let arrowLabelComps = selectedArrowNotes.map((arrowNote:HKMNoteData, index)=>{
        return <HKMIntervalLabelComp key={index} noteData={arrowNote}/>
    })
    // HKMChordIntervalComp HKMIntervalLabelComp
    useEffect(()=>{
        setKeys(props.visibleKeys)
        setNotes(props.visibleNotes)
    })
    return <HKMapComponent visibleKeys={keys} visibleNotes={notes} compWidth={props.compWidth} compHeight={props.compHeight}>
                {arrowComps}
                {arrowLabelComps}   
            </HKMapComponent>
}