import { useLocation } from "react-router-dom";
import { SideBarBlock } from "../../Chapters/Layouts/SideBarBlock";
import { BaseRowBlock } from "../../Chapters/Layouts/TwoColumn";
import { CoursePageProps, ExerciseBackLink } from "../../Course/CourseNavigation";
import { MapBuilderKeyDirections } from "../../MapBuilderWalkThrough/MapBuilderKeyDirections";
import { HexKeyCompassExercise } from "./HexKeyCompassExercise";
import { HexKeyCompassExerciseHelpers } from "./HexKeyCompassExerciseHelpers";

export function HexKeyCompassExercisePage(props:any){
    let location = useLocation();
    return  <div id="key-builder-exercise-page" className="chapterBlock">
                <h2>HexMap Compass Exercise</h2>
                <div className="row">
                    <div className="fullChannel column">
                    <p>This exercise will help you memorize the six intervals of the HexMap Compass.</p>
                        <p>You can choose between two modes. Builder mode allows you to choose the key and define all six intervals. Once you've defined all six, use the reset button to clear your answers and start over.</p>
                        <p>Quiz mode selects a random key and asks you to define it.</p> 
                        <p>Use the HexMap Compass helper pattern to start. Practice building the compass without the helper pattern until you've got it committed to memory.</p>
                         
                    </div>
                </div>                
                <BaseRowBlock omitDivider={true}>
                    <div className="centered">
                        <HexKeyCompassExercise/>    
                    </div>
                    <SideBarBlock>
                        <HexKeyCompassExerciseHelpers/>
                    </SideBarBlock> 
                </BaseRowBlock>
            </div>
}