import { FunctionComponentFactory } from "react"
import { HKMKeyData } from "./HKMKeyData"
import { KeyNoteRelationship } from "./HKMKeyNoteRelationship"

export class HKMNoteData{
    noteIndex:number
    x:number
    y:number
    tonic:number
    selected:boolean
    hilited:boolean
    defined:boolean
    visible:boolean
    keyRadius:number
    onNoteClick:Function
    presetLabel:string | null
    keysRelationships:KeyNoteRelationship[]
    constructor(noteIndex:number = -1, x:number = -1, y:number = -1, tonic:number = -1, keyRadius:number = -1, onNoteClick:Function = ()=>{}){
        this.noteIndex = noteIndex
        this.x = x
        this.y = y
        this.tonic = tonic
        this.keyRadius = keyRadius
        this.onNoteClick = onNoteClick
        this.visible = true
        this.selected = false
        this.hilited = false
        this.defined = true
        this.presetLabel = null
        this.keysRelationships = []
    }
    getCoordinates = () => {
        return [this.x, this.y]
    }
    getSelectedKeyPosition = () => {
        if(this.keysRelationships.length === 1){
           const relationship = this.keysRelationships[0]
           return relationship.position
        }
        for(let i:number = 0; i < this.keysRelationships.length; i++){
            const relationship:KeyNoteRelationship = this.keysRelationships[i]
            if(relationship.key.selected){
                return relationship.position
            }
        }
        if(this.keysRelationships.length === 2){
            return this.keysRelationships[0].position
        }
        return undefined
    }
}