import { useState } from "react"
import { ProjectState } from "../Project/ProjectState"
import { useProjectStore } from "../Project/ProjectStore"
import './Login.css'

export function Login(){
        const store = useProjectStore()
        const loggedIn:boolean = useProjectStore((state:ProjectState)=>state.loggedIn)
        const logIn:Function = useProjectStore((state:ProjectState)=>state.logIn)
        const [message, setMessage] = useState('')
        let pswd:string = ''
        const handleLogin = function(e:React.MouseEvent){
            e.stopPropagation()
            if(pswd === 'SeeTheMusic!'){
                logIn(true)
            }
            else{
                setMessage('login failed')
            }
        }
        const handleLogout = function(e:React.MouseEvent){
            e.stopPropagation()
            logIn(false)
        }
        const updatePassword = (e:React.FormEvent<HTMLInputElement>) => {
            pswd = e.currentTarget.value
        }
        const getLoginForm = () => {
            return <div className='login'>
                        <input type='text' onChange={updatePassword}></input>
                        <button name='clickme' className='login-button' onClick={handleLogin}>Log In</button>
                        <div className='login-message'>{message}</div>
                    </div>
        }
        const getLogoutButton = () => {
            return <button name='clickme' className='login-button' onClick={handleLogout}>Log Out</button>
        }
        const getContents = () => {
            if(store.loggedIn){
                return getLogoutButton()
            }
            return getLoginForm()
        }
        return getContents()
           
}