import { useRef, useState } from "react"
import { HKMapComponent } from "../../HKMap/HKMapComponent"
import { HKMapData } from "../../HKMap/HKMapData"
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase"
import { HKMKeyData, KeyNeighbors } from "../../HKMap/HKMKeyData"
import { NoteButtons } from "../KeyBuilderExercise/NoteButtons"
import './WanderingKey.css'

export interface WanderingKeyProps{
    rows?:number
    columns?:number 
    tonic?:number
    radius?:number
}
export interface UserAnswer{
    isCorrect:boolean
    tonic:number
}
export function WanderingKey(props:WanderingKeyProps){
    const rows:number = (props.rows) ? props.rows : 5
    const columns:number = (props.columns) ? props.columns : 6
    const tonic:number = (props.tonic) ? props.tonic : 0
    const radius:number = (props.radius) ? props.radius : 50
    const postCorrectAnswerPause:number = 1000
    const postIncorrectAnswerPause:number = 1500
    const acceptingAnswers:React.MutableRefObject<boolean> = useRef(false)
    const [correctInARow, setCorrectInARow] = useState(0)
    const [answer, setAnswer] = useState<null | UserAnswer>(null)

    const getInitialMapData =  () => {
        const initMapData:HKMapData = new HKMapData(rows, columns, tonic, radius, onKeyClick, onNoteClick)
        // hide all notes, show all keys, undefine all keys
        return initMapData
    }

    const getInitialRefKey = () => {
        const keys = mapData.current.keys.flat()
        const randomIndex:number = Math.floor(keys.length * Math.random())
        const refKey:HKMKeyData = keys[randomIndex]
        refKey.defined = true
        refKey.selected = true
        return refKey
    }
    const getRandomNeighborKey = (key:HKMKeyData) => {
        let neighbors:KeyNeighbors = mapData.current.getNeighborsForKey(key)
        const neighborKeys:HKMKeyData[] = key.getNeighborsAsArray(neighbors).filter((key:HKMKeyData)=>{
            return refKey.current.tonic !== key.tonic
        })
        const randomIndex:number = Math.floor(Math.random() * neighborKeys.length)
        return neighborKeys[randomIndex]
    }
    const getQKey = (refKey:HKMKeyData) => {
        return getRandomNeighborKey(refKey)
    }
    const onKeyClick = () => {
        
    }
    const onNoteClick = () => {
        
    }
    const onCorrectAnswer = (tonic:number) => {
        setAnswer({isCorrect:true, tonic:tonic})
        setCorrectInARow(correctInARow + 1)
        setTimeout(()=>{
            nextKey()
        }, postCorrectAnswerPause)
    }
    const clearIncorrectAnswer = () => {
        setAnswer(null)
        acceptingAnswers.current = true     
    }
    const onIncorrectAnswer = (tonic:number) => {
        setAnswer({isCorrect:false, tonic:tonic})
        setCorrectInARow(0)
        setTimeout(clearIncorrectAnswer, postIncorrectAnswerPause)
    }
    const onInput = (tonic:number) => {
        if(!acceptingAnswers.current) return
        acceptingAnswers.current = false
        if(tonic === qKey.current.tonic){
            onCorrectAnswer(tonic)
        }
        else{
            onIncorrectAnswer(tonic)
        }
    }
    const nextKey = () => {
        cleanMapData()
        const newRefKey:HKMKeyData = qKey.current
        qKey.current = getRandomNeighborKey(newRefKey)
        refKey.current = newRefKey
        refKey.current.selected = true
        refKey.current.defined = true
        qKey.current.hilited = true
        acceptingAnswers.current = true
        setAnswer(null)
        setVisibleKeys(mapData.current.getVisibleKeys())      
    }
    const cleanMapData = () => {
        mapData.current.defineAllKeysAs(false)
        mapData.current.deselectAllKeys()
        mapData.current.setAllKeysHilited(false)
    }
    const setUpData = () => {
        cleanMapData()
        refKey.current.selected = true
        refKey.current.defined = true
        qKey.current.hilited = true
        acceptingAnswers.current = true
    }

    const mapData:React.MutableRefObject<HKMapData> = useRef(getInitialMapData())
    const refKey:React.MutableRefObject<HKMKeyData> = useRef(getInitialRefKey())
    const qKey:React.MutableRefObject<HKMKeyData> = useRef(getQKey(refKey.current))
    setUpData()
    const [visibleKeys, setVisibleKeys] = useState(mapData.current.getVisibleKeys())

    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
    
    const getClassNames = () => {
        let classes:string[] = ['wandering-key','map-builder-exercise']
        if(answer){
            let className:string = (answer.isCorrect) ? 'correct' : 'incorrect'
            classes.push(className)
        }
        return classes.join(' ')
    }


    return      <div className={getClassNames()}>
                    <div>[{correctInARow}] in a row</div>
                    <HKMapComponent compWidth={compWidth} 
                            compHeight={compHeight} 
                            visibleKeys={visibleKeys} 
                            visibleNotes={[]}/>
                    <WanderingKeyMessage answer={answer}/>
                    <NoteButtons onClick={onInput}/>
                </div>

}
export interface WanderingKeyMessageProps{
    answer:UserAnswer | null
}
export function WanderingKeyMessage(props:WanderingKeyMessageProps){
    let msg:string = 'Identify the highlighted key.'
    if(props.answer){
        msg = (props.answer.isCorrect) ? 'correct' : 'incorrect'
    }
    return <div>{msg}</div>
}