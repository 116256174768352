import '../../Chapters/chapters.css'
import { FullChannel } from '../../Chapters/Layouts/FullChannel'
import { SideBarBlock } from '../../Chapters/Layouts/SideBarBlock'
import { BaseRowBlock } from '../../Chapters/Layouts/TwoColumn'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { WanderingKey } from './WanderingKey'
import { WanderingKeyHelpers } from './WanderingKeyHelpers'

export function WanderingKeyExercisePage(){
    return(
        <div id="interval-exercise-page" className="chapterBlock">
            <h2>The Wandering Key Exercise</h2>
            <FullChannel>
                    <p>In this exercise you'll test your knowledge of the Simplified HexMap. The exercise will select and define a key and then highlight one of it's neighbors. Identify the neighbor correctly and the exercise will then highlight a neighbor of the key you defined. It will track the number of consecutive correct answers you give. If you get one wrong, the exercise will not progress. It will wait for the correct answer before moving on.</p>     
            </FullChannel>
            <BaseRowBlock omitDivider={true}>
                <div className="centered">
                    <WanderingKey radius={35}/>
                </div>
                <SideBarBlock>
                    <WanderingKeyHelpers/>
                </SideBarBlock>
            </BaseRowBlock>
        </div>
    )
}

