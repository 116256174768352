import { useState } from "react";
import { HexKeyCompass } from "../../Helpers/HexKeyCompass/HexKeyCompass";
import { HKMSingleKeySetTonic } from "../../HKMap/HKMSingleKeySetTonic";
import { MapBuilderBase } from "../../MapBuilderWalkThrough/MapBuilderBase";
import { MapBuilderKeyDirections } from "../../MapBuilderWalkThrough/MapBuilderKeyDirections";
import { Music } from "../../Music/Music";
import { NotesSelect } from "../KeyBuilderExercise/NotesSelect";

export function WanderingKeyHelpers(){
    const [hideMap, setHideMap] = useState(false)
    const [hideCompass, setHideCompass] = useState(false)
    const [hideHexKey, setHideHexKey] = useState(false)
    const [tonic, setTonic] = useState(Music.C)
    const getMapHelper = () => {
        if (hideMap) return null
        return <MapBuilderBase tonic={Music.Ab} 
                columns={6}
                rows={5}
                radius={25}
                selectedKeys={[]} 
                openKeys={[]} 
                selectedNotes={[]}
                defineAllKeys={true}/>
    }
    const getCompass = () => {
        if (hideCompass) return null
        return <HexKeyCompass/>
    }
    const getHexKey = () => {
        if (hideHexKey) return null
        return  <div>
                    <NotesSelect tonic={tonic} onChange={onChange}/>
                    <HKMSingleKeySetTonic radius={60} tonic={tonic}/>
                </div>
    }
    const onChange = (note:number) => {
        setTonic(note)
    }   
    return  <div>
                <h3>Helper Patterns</h3>
                <div>
                    <p>
                        <button onClick={(e:React.MouseEvent)=>{e.stopPropagation(); setHideCompass(!hideCompass)}} type="button">hide/show Compass</button>
                    </p>
                    {getCompass()}
                </div>
                <div>
                    <p>
                        <button onClick={(e:React.MouseEvent)=>{e.stopPropagation(); setHideHexKey(!hideHexKey)}} type="button">hide/show HexKey</button>
                    </p>
                    {getHexKey()}
                </div>
                <div>
                    <p>
                        <button onClick={(e:React.MouseEvent)=>{e.stopPropagation(); setHideMap(!hideMap)}} type="button">hide/show HexMap</button>
                    </p>
                    {getMapHelper()}
                </div>
            </div>
}