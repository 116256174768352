import { KeyNoteRelationship } from "./HKMKeyNoteRelationship"
import { HKMKeyNotes } from "./HKMKeyNotes"
import { HKMNoteData } from "./HKMNoteData"

export interface KeyNeighbors{
    w?:HKMKeyData
    e?:HKMKeyData
    sw?:HKMKeyData
    se?:HKMKeyData
    nw?:HKMKeyData
    ne?:HKMKeyData
}
export class HKMKeyData{
    keyIndex:number
    tonic:number
    rowIndex:number
    columnIndex:number
    x:number
    y:number
    notes:HKMKeyNotes
    visible:boolean
    hilited:boolean
    selected:boolean
    defined:boolean
    open:boolean
    radius:number
    onKeyClick:Function
    predefinedLabel:string | null = null
    positions:KeyNoteRelationship[]
    onHover:Function = ()=>{}
    constructor(keyIndex:number, rowIndex:number, 
        columnIndex:number, tonic:number, x:number, y:number, radius:number, onKeyClick:Function){
        this.keyIndex = keyIndex
        this.tonic = tonic
        this.rowIndex = rowIndex
        this.columnIndex = columnIndex
        this.x = x
        this.y = y
        this.radius = radius
        this.notes = new HKMKeyNotes()
        this.visible = true
        this.hilited = false
        this.selected = false
        this.defined = true
        this.open = false
        this.onKeyClick = onKeyClick
        this.positions = []
    }
    coordinates = () => {
        return[this.rowIndex, this.columnIndex]
    }
    getNeighborsAsArray = (neighbors:KeyNeighbors) => {
        let neighborArray:HKMKeyData[] = []
        if(neighbors.w){neighborArray.push(neighbors.w)}
        if(neighbors.e){neighborArray.push(neighbors.e)}
        if(neighbors.nw){neighborArray.push(neighbors.nw)}
        if(neighbors.ne){neighborArray.push(neighbors.ne)}
        if(neighbors.sw){neighborArray.push(neighbors.sw)}
        if(neighbors.se){neighborArray.push(neighbors.se)}
        return neighborArray
    }
}