import './course.css'
import { HashRouter as Router, Route, Routes, BrowserRouter, useLocation } from 'react-router-dom'
import { TopNav } from "./TopNav"
import ScrollToTop from './ScrollTop'
import { Intro } from "../Chapters/Intro/Intro"
import { IntervalExercisePage } from '../Exercises/IntervalExercise/IntervalExercisePage'
import { SequenceExercisePage } from '../Exercises/SequenceExercise/SequenceExercisePage'
import { KeyBuilderExercisePage } from '../Exercises/KeyBuilderExercise/KeyBuilderExercisePage'
import { MapBuilderExercisePage } from '../Exercises/MapBuilderExercise/MapBuilderExercisePage'
import { CourseNavigation, CoursePage } from './CourseNavigation'
import { ChapterShell } from '../Chapters/Layouts/ChapterShell'
import { ExerciseShell } from '../Exercises/ExerciseShell'
import { useCourseStore } from './CourseStore'
import { CourseState } from './CourseState'

export function Course(){
    const getChapterRoutes = () => {
        const chapters:CoursePage[] = CourseNavigation.getChapters()
        const defaultChapter:CoursePage = chapters[0]
        const DefaultElm:Function = defaultChapter.component
        let chapterRoutes:JSX.Element[] = []
        chapters.forEach((page:CoursePage, index:number) => {
            const Elm:Function = page.component
            chapterRoutes.push(<Route path={page.path} key={index} element={<ChapterShell index={index}><Elm index={index}/></ChapterShell>} />)
        });
        return chapterRoutes
    }
    const getExerciseRoutes = () => {
        const conceptualExercises:CoursePage[] = CourseNavigation.getConceptualExercises()
        const memorizationExercises:CoursePage[] = CourseNavigation.getMemorizationExercises()
        const helperPatternExercises:CoursePage[] = CourseNavigation.getHelperPatternExercises()
        let exercises:CoursePage[] = conceptualExercises.concat(memorizationExercises)
        exercises = exercises.concat(helperPatternExercises)

        
        let exerciseRoutes:JSX.Element[] = []
        exercises.forEach((page:CoursePage, index:number) => {
            const Elm:Function = page.component
            exerciseRoutes.push(<Route path={page.path} key={index} element={<ExerciseShell><Elm/></ExerciseShell>} />)
        });
        return exerciseRoutes
    }
    const closeToc:Function = useCourseStore((state:CourseState)=>{
        return ()=>{
            if(state.tocIsOpen){state.closeToc(state)}
        }
    })
    const onCourseClick = (e:React.MouseEvent) => {
        console.log('course click!!!')
        e.preventDefault()
        closeToc()
    }
        return <div data-testid="course" className="course" onClick={onCourseClick}>
                <BrowserRouter>
                    <TopNav/>
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<ChapterShell index={0}><Intro index={0}/></ChapterShell>} />
                            {getChapterRoutes()}
                            {getExerciseRoutes()}
                            <Route path="*" element={<ChapterShell index={0}><Intro index={0}/></ChapterShell>} />  
                        </Routes>
                        </ScrollToTop>
                    </BrowserRouter>
                    <div className='footer'>
                        HexKey Musical Geometry Copyright © 2023 James Moriarity
                        <div className='copyright-notice'>
                            <p>This website, its content, text, products, audio, videos, books and all graphic/drawing/artwork images are all the work of James Moriarity unless otherwise stated and may not be reproduced or copied in any form. 
                            
                            <span className='no-break'> All rights reserved.</span>
                            </p>
                        </div>
                    </div>
                </div>
}