import React from "react"
import { CourseState } from "../../Course/CourseState"
import { useCourseStore } from "../../Course/CourseStore"
import { Music } from "../../Music/Music"
export interface NotesSelectProps{
    tonic:number
    onChange:Function
}
export function NotesSelect(props:NotesSelectProps):JSX.Element{
    let options:JSX.Element[] = []
    const notes:string[] = useCourseStore((state:CourseState)=>{
      return state.getNoteLabels(state)
    })
    notes.forEach((note:string, index:number) => {
      let o:JSX.Element = <option value={index} key={index}>{note}</option>
      options.push(o)
    })
    return <div className="key-select"><span className='bold'>Select a Key </span>
              <select className='note-select' onClick={(e:React.MouseEvent)=>{e.stopPropagation()}} value={props.tonic} onChange={(e)=>props.onChange(parseInt(e.currentTarget.value))}>
                {options}
              </select>
            </div>
  }
