import React from "react";
import { useNoteName } from "../../Music/Accidentals/Accidentals";
import { HKMNoteData } from "../../HKMap/HKMNoteData";
// origin keys notename
export interface HKMNoteCompProps{
    noteData:HKMNoteData
}

export class HKMNoteIntervalComp extends React.Component{
    props!:HKMNoteCompProps
    note:HKMNoteData
    shaftLength:number
    shaftWidth:number
    constructor(props:HKMNoteCompProps){
        super(props)
        this.note = props.noteData
        this.shaftLength = this.note.keyRadius * .75
        
        if (this.note.noteIndex === 3) { this.shaftLength = this.shaftLength + this.note.keyRadius}
        this.shaftWidth = this.note.keyRadius/20
    }
    getRotation = () => {
        if(this.note.noteIndex === 0){
            return -90
        }
        if(this.note.noteIndex === 1){
            return 30
        }
        if(this.note.noteIndex === 2){
            return 90
        }
        if(this.note.noteIndex === 3){
            return -150
        }
        if(this.note.noteIndex === 4){
            return 90
        }
        if(this.note.noteIndex === 5){
            return 30
        }
        return -90
    }
    getTransform = () => {
        const translate:string = 'translate(' + this.note.x + ' ' + this.note.y + ')'
        const rotate:string = 'rotate(' + this.getRotation() + ')'
        return translate + ' ' + rotate
    }
    getGroupClassName = () => {
        const intervalClass:string = (this.note.noteIndex === 0 || this.note.noteIndex === 3)  ? 'one' : 'two'
        const selectClass = (this.note.selected) ? 'selected ' : ''
        return "interval-arrow-inner " + selectClass + intervalClass
    }
    onClick = (e:React.MouseEvent) => {
        e.stopPropagation()
        this.note.onNoteClick(this.note)
    }
    getShaftPoints = () => { 
        return [0,0, this.shaftLength,0, this.shaftLength,this.shaftLength, 0,this.shaftWidth].join()
    }
    getShaftTransform = () => {
        const yTranslate:number = this.shaftWidth/-2
        return 'translate(5,' + yTranslate + ')' 
    }
    getArrowHeadPoints = () => {
        const arrowHeadLength:number = this.note.keyRadius/5
        const arrowHeadWidth:number = arrowHeadLength * .75
        let points:string[] = []
        const arrowPointX:number = this.shaftLength + arrowHeadLength
        const arrowPointY:number = 0
        points.push(String(arrowPointX))
        points.push(String(arrowPointY))

        points.push(String(arrowPointX - arrowHeadLength))
        points.push(String(arrowPointY - arrowHeadWidth * .5))

        points.push(String(arrowPointX - arrowHeadLength))        
        points.push(String(arrowPointY + arrowHeadWidth * .5))
        return points.join(',')

    }
    getPositionTransform = () => {
        let xTranslate:number = (this.note.keyRadius * -1)
        if(this.note.noteIndex === 3) xTranslate *= 2
        return 'translate(' + xTranslate + ',0)'
    }
    render(){
        //
        return  <g className='HKM-note'>
                    <g transform={this.getTransform()}>
                        <g className={this.getGroupClassName()} transform={this.getPositionTransform()}>
                            <polygon className="interval-arrow-head" points={this.getArrowHeadPoints()} />
                            <rect transform={this.getShaftTransform()} width={this.shaftLength} height={this.shaftWidth}/>
                        </g>
                    </g>
                </g>
    }
}

