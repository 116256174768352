import { useEffect, useRef, useState } from "react";
import { SideBarBlock } from "../../Chapters/Layouts/SideBarBlock";
import { BaseRowBlock } from "../../Chapters/Layouts/TwoColumn";
import { HKMapComponent } from "../../HKMap/HKMapComponent";
import { HKMapData } from "../../HKMap/HKMapData";
import { HKMapWrapperBase } from "../../HKMap/HKMapWrapperBase";
import { HKMKeyData } from "../../HKMap/HKMKeyData";
import { useNoteName } from "../../Music/Accidentals/Accidentals";
import { NotesSelect } from "../KeyBuilderExercise/NotesSelect";
import '../KeyBuilderExercise/KeyBuilderExercise.css'
import { NoteButtons } from "../KeyBuilderExercise/NoteButtons";
import { HexKeyCompass } from "../../Helpers/HexKeyCompass/HexKeyCompass";
import { HKMSingleKeySetTonic } from "../../HKMap/HKMSingleKeySetTonic";

export function NeighborMemorizer(){
    const rows:number = 3
    const columns:number = 3 
    const radius:number = 50
    const [selectedKey, setSelectedKey] = useState<null|HKMKeyData>(null)
    const [tonic, setTonic] = useState(0)
    const [lastAnswerCorrect, setLastAnswerCorrect] = useState(false)
    const [lastAnswer, setLastAnswer] = useState<null|number>(null)
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
    const [showHelpers, setShowHelpers] = useState(true)
    const onToggleHelpers = (e:React.MouseEvent) => {
        e.stopPropagation()
        setShowHelpers(!showHelpers)
    }
    const getHelpers = () => {
        if(showHelpers){
            return <div className='centered'>
                        <HexKeyCompass/>
                        <HKMSingleKeySetTonic radius={60} tonic={tonic}/>
                    </div>
        }
        return null
    }
    const getHelperButtonLabel = () => {
        const label:string = (showHelpers) ? 'Hide' : 'Show'
        return (label + ' Helpers')
    }
    const onAnswer = (answerTonic:number) => {
        setLastAnswer(answerTonic)
        const isCorrect:boolean = (selectedKey !== null && answerTonic === selectedKey.tonic)
        setLastAnswerCorrect(isCorrect)
        if(isCorrect){
            onCorrectAnswer()
        }
        onIncorrectAnswer()
    }
    const onKeyClick = () => {}

    const onNoteClick = () => {}

    const getMapData = (tonic:number) => {
        const adjustedTonic:number = (tonic - 4 + 12) % 12
        let mapData:HKMapData = new HKMapData(rows, columns, adjustedTonic, radius, onKeyClick, onNoteClick)
        mapData.defineAllKeysAs(false)
        mapData.hideKeys([[0,2],[2,2]])
        mapData.keys[1][1].defined = true
        mapData.setSelectedKeys([[1,1]])
        mapData.keys[1][1].hilited = true
        return mapData
    }
    const onCorrectAnswer = () => {
        if(selectedKey !== null){selectedKey.defined = true}
        setTimeout(selectNextKey, 2000)
    }
    const onIncorrectAnswer = () => {}
    const selectNextKey = () => {
        if(selectedKey !== null){selectedKey.defined = false}
        setLastAnswer(null)
        setLastAnswerCorrect(false)
        selectRandomKey()
    }
    const reset = () => {

    }
    const onTonicSelect = (selectedTonic:number) => {
        mapData.current = getMapData(selectedTonic)
        setTonic(selectedTonic)
        selectNextKey()
    }
    const  getCenterKey = () => {
        return mapData.current.keys[1][1]
    }


    const getRandomUnselectedKey = () => {
        const unselectedKeys:HKMKeyData[] = mapData.current.getVisibleKeys().filter((key:HKMKeyData)=>{
            return ((key !== getCenterKey()) && (key !== selectedKey) && !key.defined)
        })
        if(unselectedKeys.length === 0){
            return null
        }
        const randomIndex:number = (unselectedKeys.length === 1)? 0 : Math.floor(Math.random() * unselectedKeys.length)
        const key:HKMKeyData = unselectedKeys[randomIndex]
        return key
    }    
    const selectRandomKey = () => {
        mapData.current.deselectAllKeys()
        let newSelectedKey:HKMKeyData | null = getRandomUnselectedKey()
        if(newSelectedKey === null) return
        newSelectedKey.selected = true
        setSelectedKey(newSelectedKey)
    }
    useEffect(()=>{
        if(selectedKey === null){selectRandomKey()}
    })
    const mapData = useRef<HKMapData>(getMapData(tonic))
    return  <BaseRowBlock>
                <div className='centered'>
                    <NotesSelect tonic={tonic} onChange={onTonicSelect}/>
                    <HKMapComponent classNames={['exercise-compass']} compWidth={compWidth} compHeight={compHeight} visibleKeys={mapData.current.getVisibleKeys()} visibleNotes={[]}/>
                    <Message isCorrect={lastAnswerCorrect} lastAnswer={lastAnswer}/>
                    <NoteButtons onClick={onAnswer}/>
                </div>
                <SideBarBlock>
                    <button type='button' onClick={onToggleHelpers}>{getHelperButtonLabel()}</button>
                    {getHelpers()}
                </SideBarBlock>
            </BaseRowBlock>
}
export interface MessageProps{
    isCorrect:boolean
    lastAnswer:number | null
}
export function Message(props:MessageProps){
    let message:string = 'Define the selected key.'
    let userAnswer = (props.lastAnswer === null) ? 0 : props.lastAnswer
    const noteName:string = useNoteName(userAnswer)
    if(props.lastAnswer !== null){
        const isCorrect:string = (props.isCorrect) ? 'correct' : 'incorrect'
        const answerNoteName:string = noteName
        message = answerNoteName + ' is ' + isCorrect
    } 
    return <div className='message'>{message}</div>
}