import React from "react"
import { useState } from "react"
import { HKMapComponent } from "./HKMapComponent"
import { HKMapData } from "./HKMapData"
import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteComp } from "./HKMNoteComp"
import { HKMNoteData } from "./HKMNoteData"

export class HKMapWrapperBase{
    static getCompDimensions(radius:number, rows:number, columns:number): [compWidth:number, compHeight:number]{
        let compWidth:number
        let compHeight:number
        if(rows === 1 && columns === 1){
            compWidth = (radius * 2 *.866) + (radius * 2 * HKMNoteComp.circleToRadiusRatio)
            compHeight = (radius * 2) + (radius * 2 * HKMNoteComp.circleToRadiusRatio)
        }
        else{
            compWidth = (radius * 2 *.866 * columns) + (radius * 4 * HKMNoteComp.circleToRadiusRatio)
            compHeight = (radius * 2) + (radius * (rows - 1) * 1.5) + (radius * 2 * HKMNoteComp.circleToRadiusRatio)  
        }
  
        return [Math.floor(compWidth), Math.floor(compHeight)]
    }
}