import '../../Chapters/chapters.css'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { Link } from 'react-router-dom'
import { Helpers } from '../KeyBuilderExercise/Helpers'
import { MapBuilderExercise } from './MapBuilderExercise'
import { FullChannel } from '../../Chapters/Layouts/FullChannel'
import { BaseRowBlock } from '../../Chapters/Layouts/TwoColumn'
import { SideBarBlock } from '../../Chapters/Layouts/SideBarBlock'
import { MapBuilderExerciseHelpers } from './MapBuilderExerciseHelpers'


export function MapBuilderExercisePage(){
    return(
        <div id="key-builder-exercise-page" className="chapterBlock">
            <h2>The HexMap Builder Exercise</h2>
            <FullChannel>
                <p>In this exercise you'll build out a 12-key section of the HexMap using a random initial key as a starting point. Use the same key-building skills you've already acquired from building a single HexKey. The helper patterns are there, but hopefully you won't need them. Click on any key to reveal that key's notes. If you click on a key with visible notes, it will deactivate and hide it's notes. Use the reset button to start over.</p>
            </FullChannel>
            <BaseRowBlock omitDivider={true}>
                <div>
                    <MapBuilderExercise/>
                    <h3>Additional Exercise:</h3>
                    <p>Draw a 12-key section of the HexMap using paper and pencil. If you're hexagon-drawing skills are still a work in progress, try using a paper copy of this page to practice with. Hide the initial visible key so all the keys are blank, then print it out.</p>
                </div>
                <SideBarBlock>
                    <MapBuilderExerciseHelpers/>
                </SideBarBlock>
            </BaseRowBlock>
            <FullChannel>
                            </FullChannel>
        </div>
    )
}

