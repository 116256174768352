import React, { MouseEventHandler } from "react";
import { useNoteName } from "../Music/Accidentals/Accidentals";
import { HKMKeyData } from "./HKMKeyData";
export interface HKMKeyComponentProps{
    keyData:HKMKeyData
}
export function HKMKeyComponent(props:HKMKeyComponentProps){
    const key = props.keyData
    const noteName = useNoteName(key.tonic)
    const getTransform = () => {
        let x:number = key.x
        let y:number = key.y
        let t = 'translate(' + x + ' ' + y + ')'
        return t
    }
    const getPointArray = () => {
        let r:number = key.radius
        let w:number = r * .866
        let posA:number[] = [0, -1 * r]
        let posB:number[] = [w, -0.5 * r]
        let posC:number[] = [w, 0.5 * r]
        let posD:number[] = [0, r]
        let posE:number[] = [-1 * w, 0.5 * r]
        let posF:number[] = [-1 * w, -0.5 * r]
        return[posA, posB, posC, posD, posE, posF]
    } 
    const getPoints = () => {
        let points:number[][] = getPointArray()
        return [points[0].join(' '), points[1].join(' '), points[2].join(' '), points[3].join(' '), points[4].join(' '), points[5].join(' ')].join(',')
    }
    const onClick = (e:React.MouseEvent)=>{
        e.stopPropagation()
        key.onKeyClick(key)
    }
    
    const getLabel = () => {
        if(key.predefinedLabel !== null && key.defined){
            return key.predefinedLabel
        }
        return (key.defined && !key.open) ? noteName: ''
    }
    const getClassName = () => {
        let classes:string[] = ['HKMKey']
        if(key.selected){
            classes.push('selected')
        }
        if(key.hilited){
            classes.push('hilited')
        }
        return classes.join(' ')
    }
    const getLabelClassName = () => {
        let classes:string[] = ['field-key-label map-key-label']
        return classes.join(' ')
    }
    const getFontSize = () => {
        const minimumSize:number = 15
        const maximumSize:number = 28
        let size:number = key.radius * .7
        if(size < minimumSize) size = minimumSize
        if(size > maximumSize) size = maximumSize
        return size
    }
    const onMouseOver = (event:React.MouseEvent) => {
        event.stopPropagation();
        props.keyData.onHover(props.keyData)
    }
    const getLabelTransform = () => {
        const y:number = getFontSize()/4
        return 'translate(0,' + y + ')'
    }
    return  <g className={getClassName()} transform={getTransform()}>
                <polygon onMouseOver={onMouseOver} onClick={onClick} className={'field-hexagon'} 
                        points={getPoints()}
                        />
                <text transform={getLabelTransform()} fontSize={getFontSize()} className={getLabelClassName()}>{getLabel()}</text>
            </g>
}