import '../chapters.css'
import { useNoteName } from '../../Music/Accidentals/Accidentals'
import { Link } from 'react-router-dom'
import { MapBuilderBase } from '../../MapBuilderWalkThrough/MapBuilderBase'
import { Music } from '../../Music/Music'
import { MapBuilderKeyDirections } from '../../MapBuilderWalkThrough/MapBuilderKeyDirections'
import { MapBuilderAxisOfFifths } from '../../MapBuilderWalkThrough/MapBuilderAxisOfFifths'
import { MapBuilderAxisOfThirds } from '../../MapBuilderWalkThrough/MapBuilderAxisOfThirds'
import { MapBuilderAxisOfSixths } from '../../MapBuilderWalkThrough/MapBuilderAxisOfSixths'
import { MapBuilderAxisOfSixthsExpanded } from '../../MapBuilderWalkThrough/MapBuilderAxisOfSixthsExpanded'
import { MapBuilderAxisOfThirdsExpanded } from '../../MapBuilderWalkThrough/MapBuilderAxisOfThirdsExpanded'
import { CoursePageNavigationLink, CoursePageProps } from '../../Course/CourseNavigation'
import { KeyChangesAxisOfThirds } from '../../MapBuilderWalkThrough/KeyChangesAxisOfThirds'
import { KeyChangeMap } from '../../MapBuilderWalkThrough/KeyChangeMap'
import { HKMKeyChordHelper } from '../../HKMap/HKMKeyChordHelper'
import { GiantStepsKeyChangeMap } from './GiantStepsKeyChangeMap'
import { GiantStepsColumn, GiantStepsColumns } from './GiantStepsKeys'
import { GiantStepsAnimator } from './GiantStepsAnimator'
import { FullChannel } from '../Layouts/FullChannel'
import { BaseRowBlock } from '../Layouts/TwoColumn'
import { SideBarBlock } from '../Layouts/SideBarBlock'

export function KeyChanges(props:CoursePageProps){
    const tonic:number = 3
    return(
        <div id="chapter-two" className="chapterBlock">
            <FullChannel >
                    <div>
                        In this chapter, we'll look at how we can use the HexMap to understand and analyze key changes.  We'll use each of the three axes (5ths, 3rds & 6ths) introduced in the previous chapter to demonstrate some common key change (modulation) techniques: Pivot Chords, Tranpostion, Parallel Modulation and Modal Modulation.
                    </div>
            </FullChannel >
        
            <div>
                <BaseRowBlock title='1) Pivot Chords along the Axis of Fifths'>
                    <div>
                        <p>Pivot chords are chords that are common to both keys in a modulation. This provides the listener with a smooth transition from one key to another. Pivot chords are commonly used along the axis of 5ths.</p>
                        <p>
                            Let's start by analyzing the common notes and chords shared by any two neighboring keys along the Axis of Fifths. We'll choose C and G.
                        </p>
                        <p>
                            The keys of C and G have 6 of their 7 notes in common: C, D, E, G, A, B. Only C's 4th note (F) is missing from G. Instead G contains an F# in the 7 position. (btw this pattern is why there's an increasing and then decreasing number of sharps/flats as we move along the cirlcle of fifths)
                        </p> 
                    </div>
                    <SideBarBlock title={'Common Notes'}>
                        <KeyChangeMap   tonic={0} 
                                    hiddenKeys={[]} 
                                    openKeys={[[0,0],[1,0]]}  
                                    selectedKeys={[]}
                                    selectedNotes={[0,1,2,4,5,6,7,8,9,10]}
                                    hilitedNotes={[]}
                                    rows={2}
                                    columns={1}
                                    preSetLabelNotes={[]}/>
                    </SideBarBlock>
                </BaseRowBlock> 

                <BaseRowBlock>
                    <div>
                    <h3>Common Chords</h3>
                        <p>
                            By using the chord type helper pattern introduced in the diatonic chords chapter, we can go through each of the six common notes and see if their corresponding chords are of the same type in both keys. Of the six common notes, we see that four of their corresponding chords are common to both keys and two are not. 
                        </p>
                        <p>
                            Common Chords
                        </p>
                        <ul>
                            <li>C major - the major 1 in C, the major 4 in G</li>
                            <li>E minor - the minor 3 in C, the minor 6 in G</li>
                            <li>G major - the major 5 in C, the minor 1 in G</li>
                            <li>A minor - the minor 6 in C, the minor 2 in G</li>
                        </ul>
                        Not Common:
                        <ul>
                            <li>D - The minor 2 in C, the major 5 in G</li>
                            <li>B - The half-diminished 7 in C, the minor 3 in G</li>
                        </ul>
                        <p>
                            One or more of these four common chords could be used as a pivot chord to create a transition from one key to the other. Because four of their seven chords are shared, a chord that's isn't shared (distinctive) is necessary to make the key change happen.
                        </p>
                    </div>
                    <SideBarBlock title={'The Chord Type Helper Pattern'}>
                            <HKMKeyChordHelper radius={60}/>
                    </SideBarBlock>
                    <SideBarBlock title={'The Four Common Chords of C and G'}>
                            <KeyChangeMap   tonic={0} 
                                    hiddenKeys={[]} 
                                    openKeys={[[0,0],[1,0]]}  
                                    selectedKeys={[]}
                                    selectedNotes={[]}
                                    hilitedNotes={[0,2,4,5,8,10]}
                                    rows={2}
                                    columns={1}
                                    preSetLabelNotes={[]}/>
                    </SideBarBlock>
                </BaseRowBlock>


                <h3>Example: Radio Head's Karma Police</h3>
                <div className="row">
                    <div className="leftChannel column">
                        <p>
                            Karma Police's verse is somewhat ambiguous and has prompted various theories as to which key it's actually in. Let's apply a simple rule of thumb when analyzing key changes: If a chord takes us out of a key and into a new one, we go to that new key and stay there until something pulls us out of that new key.</p>
                        <p>
                            It should be noted that a more orthodox view of the example below would be that there isn't a key change and that there's just a borrowed chord being used in the second line (F major). Not to worry. This is just a thought exercise meant to illustrate certain patterns and qualities of a key change along the axis of fifths. As they say 'An intelligent mind can entertain an idea without necessarily having to believe it.'
                        </p>
                        <p>
                            We'll see that this theoretical key change (modulation) from G to C and back to G can be viewed as a form of modal modulation as well. Modal modulation is when a song modulates between two different modes that exist in different keys but share a common root note. In this case the common root note is A. In the key of G the mode is A Dorian since A is in the 2nd postion in G. In the key of C the mode is A Aeolian since A is in the 6th position in C.
                        </p>
                    </div>
                    <div className="rightChannel column">
                        <div className="caption-title">A minor is the pivot chord</div>
                        <KeyChangeMap   tonic={0} 
                                hiddenKeys={[]} 
                                openKeys={[[0,0],[1,0]]} 
                                selectedKeys={[]}
                                selectedNotes={[]}
                                hilitedNotes={[5]}
                                rows={2}
                                columns={1}
                                preSetLabelNotes={[]}/>
                    </div>
                </div>
                <h4>First Line</h4>
                <div className="row">
                    <div className="leftChannel column">
                        <p>The chord progression in the first line is: <span className='no-break'>Am - D/F# - Em - G - Am</span> <span className='no-break'>(D/F# means a D major chord with an F# bass note added)</span></p>
                        <p>All four of these chords, including the F# bass note, are found in the key of G. Three of the four chords are shared with the key of C (Am, Em and G). D major is the distinctive chord.</p>
                        <p>Notice that the first line ends on a pivot chord, A minor. This sets the stage for the modulation that happens at the start of the 2nd line.</p>
                        <p>The mode appears to be A Dorian since the progression starts and resolves on A minor.</p>  
                    </div>           
                    <div className="rightChannel column">
                    <div className="caption-title">D major is the distinctive chord</div>
                    <KeyChangeMap   tonic={0} 
                                    hiddenKeys={[]} 
                                    openKeys={[[0,0], [1,0]]} 
                                    selectedNotes={[4,5,9,10]}
                                    hilitedNotes={[9]}
                                    selectedKeys = {[[1,0]]}
                                    rows={2}
                                    columns={1}
                                    preSetLabelNotes={[]}/>

                    </div>
                </div>
                <h4>The Second Line</h4>
                <div className="row">
                    <div className="leftChannel column">
                        <p>The chord progression in the second line of the verse is: <span className='no-break'>F - Em - G - Am</span> <span className='no-break'></span></p>
                        <p>All of these chords are found in the key of C. Three of the four chords are shared with G: E minor, G major, A minor. The distinctive chord is F major.</p>
                        <p>F major brings the song into C's tonality and we stay there since the other chords are also diatonic to C. The progression ends with a pivot chord, A minor, setting up the modulation back to G at the start of the third line.</p>
                        <p>The mode in the second line appears to be A Aeolian, since it resolves to A minor, at the sixth position in C - the Aeolian position.</p>  
                    </div>           
                    <div className="rightChannel column">
                        <div className="caption-title">F major is the distinctive chord</div>
                        <KeyChangeMap   tonic={0} 
                        hiddenKeys={[]} 
                        openKeys={[[0,0],[1,0]]} 
                        selectedKeys={[[0,0]]} 
                        selectedNotes={[2,3,4,5]}
                        hilitedNotes={[3]}
                        rows={2}
                        columns={1}
                        preSetLabelNotes={[]}/>
                    </div>
                </div>
                <h4>The Third Line</h4>
                <div className="row">
                    <div className="leftChannel column">
                        <p>The third and last line of the verse is: <span className='no-break'>D - G - D/F# - C - Em/B - Am - Bm - D</span>
                        </p>
                        <p>All six of these chords are found in the key of G. Of the six, four are shared with the G key: G, C, Em, Am. The two distinctive chords are D major and B minor. The progression starts with D major, taking the song back into G. The rest of the chords are diatonic to G, keeping us in G.</p>
                        <p>The mode is a bit trickier here. If we look at the progression, one could say it's D Mixolydian since it starts and ends on D (which is in the fifth position of G, the Mixolydian position). However the resolution of the vocal melody comes on Am, making it A Dorian. The last two chords seem to lift the tension instead of resolving. That's an interpretation.</p> 
                        <p></p> 
                    </div>           
                    <div className="rightChannel column">
                                <div className="caption-title">D major & B minor are distinct</div>
                                <KeyChangeMap   tonic={0} 
                                hiddenKeys={[]} 
                                openKeys={[[0,0], [1,0]]}  
                                selectedKeys={[[1,0]]}
                                selectedNotes={[4,5,7,8,9,10]}
                                hilitedNotes={[7,9]}
                                rows={2}
                                columns={1}/>
                    </div>
                </div>
                <h3>Example Conclusion</h3>
                <div className='row divider'>
                    <div className='fullChannel column'>
                        <p>We can see that a pattern emerges in the verse of Karma Police where the last chord of a line is a pivot chord, setting up a modulation. Then the first chord of the following line is a distinctive chord which establishes the new key. The pivot chord, A minor, makes this transition sound seamless.</p>
                        <p>We're blurring the lines a bit here between borrowed chords and key changes. This analysis is only one particular way to see this progression. Crucially, the HexMap provides with a means to actually see it and interpret the patterns we see with all the information and relationships in front of us.</p>
                        <p>In general we can say that pivot chords provide a means of transitioning between any two keys that share a common chord. We can use the HexMap and the Chord Type Helper pattern to determine which notes and chords are common between any two keys.</p>
                    </div>
                </div>
            </div>

            <div>
                <BaseRowBlock title='2) Parallel Key Modulation along the Axis of Sixths'>
                    <div>
                        <p>Parallel Key modulation is done by changing between the major and relative minor keys of a root note. We prefer to use modes instead of major keys and relative minors so let's describe it in those terms: Parallel Key modulation is done by switching from Ionian mode (1st position) of one key and the Aeolian mode (sixth position) of another key along the axis of sixths, using the same root note.</p>
                        <p>Example: Moving from E Ionian (aka E major) in the key of E to E Aeolian in the key of G (aka E minor). This can work in the opposite direction as well. This can also be considered a modal modulation since we're modulating between two modes of the same root note.</p>
                        <p>
                            Let's start by analyzing the common notes and chords shared by any two neighboring keys along the Axis of Sixths. We'll use G and E.
                        </p>
                        <p>
                            The keys of G and E have 4 of their 7 notes in common: A, B, E, {useNoteName(Music.Gb)}.
                        </p> 
                    </div>
                    <SideBarBlock title={'Major (ionian) to minor (aeolian)'}>
                        <KeyChangeMap   tonic={7} 
                                    hiddenKeys={[]} 
                                    openKeys={[[0,0],[0,1]]}  
                                    selectedKeys={[]}
                                    selectedNotes={[1,2,5,6,7,8]}
                                    hilitedNotes={[5,7]}
                                    rows={1}
                                    columns={2}
                                    preSetLabelNotes={[]}/>
                    </SideBarBlock>
                </BaseRowBlock> 

                <BaseRowBlock>
                    <div>
                    <h3>Common Chords</h3>
                        <p>
                            By using the chord type helper pattern introduced in the diatonic chords chapter, we can go through each of the four common notes and see if their corresponding chords are of the same type in both keys. Of the four common notes, none of their corresponding chords are common to both keys. 
                        </p>
                        Not Common:
                        <ul>
                            <li>A - The minor 2 in G, the major 4 in E</li>
                            <li>B - The minor 3 in G, the major 4 in E</li>
                            <li>E - The minor 6 in G, the major 1 in E</li>
                            <li>{useNoteName(Music.Gb)} - The half-diminished 7 in G, the major 1 in E</li>
                        </ul>
                        <p>
                            Since we don't have any common chords, one option is to just substitute an E minor for an E major and jump from E into G.</p>
                            <p>Or we can use a chord rooted at their common 5th note to create a smooth transition.</p>
                            <p>Let's dig into this using the HexKey Sequence... We can count from each root note and see that B is the 5th note in each scale: E Aeolian in G and E Ionian in E. The 5th-to-1st resolution is particularly strong and provides us with a way of transitioning between the two keys.</p>
                            <p>Even though the B chord is technically B minor in G and B major in E we can use either version of the B chord to set up the modulation into the opposing key. We would then land on the opposing E chord to complete the modulation with a 5-1 resolution.
                        </p>
                        <p>Note that A is the common 4th note in both E Aeolian and E Ionian. Though the resolution isn't quite as strong, we could use a 4th chord to create a transition as well.</p>
                        <p>A specific version of this 5th to 1st modulation technique is called a Picardy Third. In this case the song is in minor (Aeolian) but ends with the major version of the root note to end the progresssion.</p>
                        <p>It's called a Picardy Third because the minor 3rd of our minor chord is raised up an interval to a major 3rd to make the chord major.</p>
                    </div>
                    <SideBarBlock title={'The Chord Type Helper Pattern'}>
                            <HKMKeyChordHelper radius={60}/>
                    </SideBarBlock>
                    
                    <SideBarBlock title={'Major (ionian) to minor (aeolian)'}>
                        <KeyChangeMap   tonic={7} 
                                    hiddenKeys={[]} 
                                    openKeys={[[0,0],[0,1]]}  
                                    selectedKeys={[]}
                                    selectedNotes={[2]}
                                    hilitedNotes={[5,7]}
                                    rows={1}
                                    columns={2}
                                    preSetLabelNotes={[]}/>
                    </SideBarBlock>
                </BaseRowBlock>
                <BaseRowBlock title="Picardy Third Example">
                    <div>
                        <div>
                            <p>Emin-Cmaj-Amin-Emin-Gmaj-Bmin-Emaj</p>
                            <p>We see in this chord progression that the first six chords are in E minor (E Aeolian in the key of G). The sixth chord in the progression is B minor, rooted at the common 5th note. It sets up the switch to the last chord, E major (which is the one chord of E Ionian next door in E).</p>
                        </div>
                    </div>
                    <SideBarBlock title={'B minor sets up a Picardy Third ending'}>
                        <KeyChangeMap   tonic={7} 
                                hiddenKeys={[]} 
                                openKeys={[[0,0],[0,1]]}  
                                selectedKeys={[]}
                                selectedNotes={[0,1,2,3,5,7]}
                                hilitedNotes={[2,5,7]}
                                rows={1}
                                columns={2}
                                preSetLabelNotes={[]}/>
                    </SideBarBlock>
            </BaseRowBlock>
            </div>





            <div>
                <BaseRowBlock title='2) Key Changes along the Axis of 3rds'>
                    <div className='leftChannel column'>
                        <p>Let's start by looking at the common notes and chords between the keys of G and B along the Axis of Thirds. The two keys share only 3 of their 7 notes: B, E and {useNoteName(Music.Gb)}. </p>
                        <p>Of those 3 common notes, none have corresponding chords that are shared between G and B. 
                        </p>
                        <ul>
                                <li>B is the minor 3rd in G, the major 1st in B</li>
                                <li>E is the minor 6th in G, the major 4th in B</li>
                                <li>{useNoteName(Music.Gb)} is the diminished 7th in G, the major 5th in B</li>
                            </ul>
                        
                        <p>This means pivot chords aren't an option for modulations along this axis. Transpositions of a chord pattern from one key to another are possible. In a tranposition, we repeat a chord progression or melody in the new key, using the same position numbers. We'll look at a famous one by Jazz legend John Coltrane in his song Giant Steps.</p>
                    </div>
                    <SideBarBlock>
                        <KeyChangeMap   tonic={Music.G} 
                                    hiddenKeys={[[0,1],[1,0]]} 
                                    openKeys={[[0,0],[1,1]]} 
                                    selectedKeys={[[0,0],[1,1]]} 
                                    selectedNotes={[2,5,6,12]}
                                    hilitedNotes={[]}
                                    rows={2}
                                    columns={2}/>
                    </SideBarBlock>
                </BaseRowBlock>
                <BaseRowBlock>
                    <div>
                        <h3>Example: John Coltrane's Giant Steps</h3>
                        <p>The chord progression in Giant Steps uses transpostion to modulate back and forth between three keys along the Axis of Thirds.</p>
                        <p>Wikipedia describes it this way: 'Coltrane uses the cycle in descending major third tonal transpositions in the opening bars and then ascending ii–V–I progressions separated by a major third in the second section of "Giant Steps".</p>
                        <p>That might sound pretty intimidating. However, when we see this play out on the HexMap, it's actually pretty simple. The song moves up and down the Axis of Thirds, playing variations of a 2-5-1 chord progression.</p>
                        <p>A few notes:</p>
                        <ul>
                                <li>This song uses 7th chords quite a bit. For the purpose of this section we'll focus on the chord's root note and it's parent key position. For more on 7th chords, check out the chapter on diatonic chords.</li>
                                
                                <li>Common notation for this song contains specific flats and sharps (Eb rather than D#, F# instead of Gb). We've locked those in to avoid confusion.</li>
                                <li>When we say 'up' or 'down' we mean it in terms of direction, not in pitch. Technically we can move either up or down in pitch between any two keys.</li>
                            </ul>
                    </div>
                    <SideBarBlock title='The Three Keys of Giant Steps'>
                        <KeyChangeMap   tonic={Music.Eb} 
                                    hiddenKeys={[[0,1],[1,0],[2,0]]} 
                                    openKeys={[[0,0],[1,1],[2,1]]} 
                                    selectedKeys={[[0,0],[1,1],[2,1]]} 
                                    selectedNotes={[]}
                                    hilitedNotes={[]}
                                    rows={3}
                                    columns={2}
                                    preSetLabelNotes={[[0,'Eb'], [3,'Ab'], [4,'Bb'], [19,'F#'], [24,'C#'], [25, 'D#'], [26, 'G#'], [20, 'F#'], [27, 'A#']]}/>
                    </SideBarBlock>
                </BaseRowBlock>

                <div className='row giant-steps-row'>
                    <div className='one-flex-column column'>
                        <h4>First Step</h4>
                        The first key is B, with only a single chord in the progression - the 1 chord (Bmaj7).
                    </div>
                    <div className='one-flex-column  column'>
                        <h4>Second Step</h4>
                        Moving up the axis of thirds, the second key is G. The chord progression is 5-1 (D7-Gmaj7)
                    </div>
                    <div className='one-flex-column  column'>
                        <h4>Third Step</h4>
                        Moving upwards again, the third key is Eb. The chord progression is again, 5-1 (Bb7-Ebmaj7)
                    </div>
                </div>
                <div className='row giant-steps-row'>
                    <div className='one-flex-column column'>
                        <GiantStepsKeyChangeMap keyNote={Music.B} index={2} positions={[1]}/>
                    </div>
                    <div className='one-flex-column  column'>
                        <GiantStepsKeyChangeMap keyNote={Music.G} index={1} positions = {[5,1]}/>
                    </div>
                    <div className='one-flex-column  column'>
                        <GiantStepsKeyChangeMap keyNote={Music.Eb} index={1} positions = {[5,1]}/>
                    </div>
                </div>
                <div className='row giant-steps-row'>
                    <div className='one-flex-column column'>
                        <h4>Fourth Step</h4>
                        Moving back down, the fourth key is G. The chord progression is 2-5-1 (Am7-D7-GMaj7)
                    </div>
                    <div className='one-flex-column  column'>
                        <h4>Fifth Step</h4>
                        Moving up, the fifth key is Eb. The chord progression is 5-1 (Bb7-EbMaj7)
                    </div>
                    <div className='one-flex-column  column'>
                        <h4>Sixth Step</h4>
                        Moving up again, the sixth key is B. The chord progression is 5-1 (F#7-BMaj7)
                    </div>
                </div>
                <div className='row giant-steps-row'>
                    <div className='one-flex-column  column'>
                        <GiantStepsColumn>
                            <GiantStepsKeyChangeMap keyNote={Music.G} index={1} positions = {[2,5,1]}/>
                        </GiantStepsColumn>
                    </div>
                    <div className='one-flex-column column'>
                        <GiantStepsColumn>
                            <GiantStepsKeyChangeMap keyNote={Music.Eb} index={1} positions = {[5,1]}/>
                        </GiantStepsColumn>
                    </div>
                    <div className='one-flex-column  column'>
                        <GiantStepsColumn>
                            <GiantStepsKeyChangeMap keyNote={Music.B} index={1} positions = {[5,1]}/>
                        </GiantStepsColumn>
                    </div>
                </div>
                <div className='row giant-steps-row'>
                    <div className='one-flex-column column'>
                        <h4>Seventh Step</h4>
                        Moving down, the seventh key is Eb. The chord progression is 2-5-1 (Fmin7-Bb7-Ebmaj7)
                    </div>
                    <div className='one-flex-column  column'>
                        <h4>Eighth Step</h4>
                        Still moving down, the eigth key is G. The chord progression is 2-5-1 (Amin7-D7-Gmaj7)
                    </div>
                    <div className='one-flex-column  column'>
                        <h4>Ninth Step</h4>
                        Moving down some more, the ninth key is B. The chord progression is 2-5-1 (C#min7-F#7-Bmaj7)
                    </div>
                </div>
                <div className='row giant-steps-row'>
                    <div className='one-flex-column  column'>
                        <GiantStepsColumn>
                            <GiantStepsKeyChangeMap keyNote={Music.Eb} index={1} positions = {[2,5,1]}/>
                        </GiantStepsColumn>
                    </div>
                    <div className='one-flex-column column'>
                        <GiantStepsColumn>
                            <GiantStepsKeyChangeMap keyNote={Music.G} index={1} positions = {[2,5,1]}/>
                        </GiantStepsColumn>
                    </div>
                    <div className='one-flex-column  column'>
                        <GiantStepsColumn>
                            <GiantStepsKeyChangeMap keyNote={Music.B} index={2} positions = {[2,5,1]}/>
                        </GiantStepsColumn>
                    </div>
                </div> 
                
                <div className='row giant-steps-row'>
                    <div className='one-flex-column column'>
                        <h4>Tenth Step</h4>
                        Moving down, the tenth key is Eb. The chord progression is 2-5-1 (Fmin7-Bb7-Ebmaj7)
                    </div>
                    <div className='one-flex-column  column'>
                        <h4>Eleventh Step</h4>
                        Finally moving up, the eleventh and last key is B. The chord progression is 2-5 (C♯min7-F♯7)
                    </div>
                    <div className='one-flex-column  column'>
                        <h4>The Full Progression Animated</h4>
                        This animation shows us the full progression of keys and chords from start to finish. Click on the keys below to start the animation.
                    </div>
                </div>
                <div className='row giant-steps-row'>
                    <div className='one-flex-column  column'>
                        <GiantStepsColumn>
                            <GiantStepsKeyChangeMap keyNote={Music.Eb} index={2} positions = {[2,5,1]}/>
                        </GiantStepsColumn>
                    </div>
                    <div className='one-flex-column column'>
                        <GiantStepsColumn>
                            <GiantStepsKeyChangeMap keyNote={Music.B} index={2} positions = {[2,5]}/>
                        </GiantStepsColumn>
                    </div>
                    <div className='one-flex-column column giant-steps-animation'>
                        <GiantStepsAnimator/>
                    </div>
                </div> 
                <FullChannel>   
                    <h3>Example Conclusion</h3>
                    <p>We see that the pattern being used is pretty simple to follow visually, though complex to describe with just words. The song moves up and down the Axis of Thirds. The chord progressions are variations of a 2-5-1 chord progression in each of the three keys. When moving up, the pattern is 5-1, when moving down the pattern is 2-5-1. There are two exceptions: the first progression in just a single 1 chord in B and the last progression is a 5-2 in B.</p> 
                    <p>The song seems to be in continuous motion, not resting in any key for very long. Memorizing this progression by chord names would be a fairly challenging task. However, looking at it visually using position numbers, the pattern of key changes and chord progressions is pretty easy to understand and memorize</p>
                    <p>Repeating the same pattern in different keys is called 'Transposition Modulation', this can occur between any two keys. Using the Axis of Thirds gives this type of modulation a signature sound.</p>
                </FullChannel> 
            </div>
        </div>
    )
}

