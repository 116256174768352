import '../chapters.css'
import { MapBuilderExerciseHelpers } from '../../Exercises/MapBuilderExercise/MapBuilderExerciseHelpers'
import { useNoteName } from '../../Music/Accidentals/Accidentals'
import { Link } from 'react-router-dom'
import { MapBuilderBase } from '../../MapBuilderWalkThrough/MapBuilderBase'
import { Music } from '../../Music/Music'
import { CoursePageNavigationLink, CoursePageProps } from '../../Course/CourseNavigation'
import { SideBarBlock } from '../Layouts/SideBarBlock'
import { BaseRowBlock, FiveFiveColumns } from '../Layouts/TwoColumn'
import { ChapterSubtitle, FullChannel } from '../Layouts/FullChannel'

export function HexMap(props:CoursePageProps){
    const tonic:number = 3
    return(
        <div id="chapter-two" className="chapterBlock">
            <BaseRowBlock>
                <div>
                    <p>In this chapter we'll see how to extend the HexKey pattern to build out all twelve parent keys in the major scale universe. We call this larger pattern the HexMap.
                    </p>
                    <p>The HexMap suggests a different way of viewing keys, chords and notes. We can view them as places within a greater musical map. Using this metaphor we can think of songs as a series of steps from place to place.</p>
                    <p>When a song changes keys or introduces a chord that's outside the key, we are stepping out of that key and into another - moving from one location to another. We can map out these musical steps using the full HexMap.</p>

                    <p>The HexMap pattern provides us with a grouping of all twelve parent keys. They can all be found clustered together in a 3 row x 4 column pattern. You'll see that several useful patterns emerge from this structure, like the circle of fifths. Knowing how to build an individual HexKey structure is a pre-requisite to explore this section.</p>

                    <p>You may have noticed that the HexMap's structure is similar to carbon's molecular structure. This turns out to be useful. If you'd like to practice drawing sections of the HexMap with pencil and paper (highly recommended), you can buy hexagonal graph paper, meant for organic chemistry students.</p>

                    <p>In this chapter we'll follow the same logic we used to build a single HexKey in order build out the full HexMap. In a following chapter we'll examine a way to define a key's neighbor keys without necessarily plotting out all of it's notes. But for now we'll focus on building out the full HexMap using the HexKey pattern and the shared notes between keys.</p>
                </div>
                <SideBarBlock title='The HexMap'>
                    <MapBuilderBase tonic={tonic+4} 
                                        radius={30}
                                        rows={7}
                                        columns={7}

                                        defineAllKeys={true}
                                            selectedKeys={[[2,2],[2,3],[2,4],[2,5],
                                                [3,2],[3,3],[3,4],[3,5],
                                                [4,1],[4,2],[4,3],[4,4]]} 
                                            hilitedKeys={[]}
                                            openKeys={[]} 
                                            selectedNotes={[]}
                                            definedKeys={[]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <FullChannel>
                <div>
                    <ChapterSubtitle>Building the HexMap</ChapterSubtitle>
                    <p>
                        We start by pre-defining an initial key. We'll use the key of E. Since the HexMap pattern is infinite, we can start with any key in any position. We'll use the notes of our initial key as starting points to build out it's neighbor keys. We can continue this process until we've defined all twelve keys.
                    </p>
                </div>
            </FullChannel>
            <FullChannel>
                    <MapBuilderBase tonic={tonic} 
                        selectedKeys={[[1,2]]} 
                        hilitedKeys={[]}
                        openKeys={[[1,2]]} 
                        selectedNotes={[]}/>
            </FullChannel>

            <FullChannel>
                <div>
                    <ChapterSubtitle>The Bottom Left Key</ChapterSubtitle>
                    <p>Let's now focus on the key to the bottom left of E. Notice that the 5 note in the E key (B) sits in the 1 note position for this new key. This tells us that this is the key of B. The 2 note ({useNoteName(Music.Db)}) is predefined as well. Now we can use the same key-building process to define the rest of the notes, starting with the 3 note and moving through the sequence.</p>
                </div>
            </FullChannel>
            <FiveFiveColumns>
                    <MapBuilderBase tonic={tonic} 
                                    selectedKeys={[[1,2]]} 
                                    hilitedKeys={[[2,1]]}
                                    openKeys={[[1,2]]} 
                                    selectedNotes={[32,36]}
                                    definedKeys={[]}/> 
                    <MapBuilderBase tonic={tonic} 
                                    selectedKeys={[[1,2],[2,1]]}  
                                    hilitedKeys={[[2,1]]}
                                    openKeys={[[1,2],[2,1]]} 
                                    selectedNotes={[32,36,48,33,44,49,50]}
                                    definedKeys={[]}/>
            </FiveFiveColumns>
             <FullChannel>
                <div>
                    <ChapterSubtitle>The Bottom Right Key</ChapterSubtitle>
                    <p>Now let's focus on the key to the bottom right of E. We can see that it's going to be the key of {useNoteName(Music.Ab)}. We see that the 1, 4 and 5 notes are predifined. We can define the 2 and 3 by starting at 1. We can define the 6 and 7 by starting at the 5.</p>
                </div>
            </FullChannel>
            <FiveFiveColumns>
                    <MapBuilderBase tonic={tonic} 
                                    selectedKeys={[[1,2],[2,1], [2,2]]} 
                                    hilitedKeys={[[2,2]]}
                                    openKeys={[[1,2], [2,1]]} 
                                    selectedNotes={[35,36,48]}
                                    definedKeys={[]}/> 

                    <MapBuilderBase tonic={tonic} 
                                    selectedKeys={[[1,2],[2,1], [2,2]]} 
                                    hilitedKeys={[[2,2]]} 
                                    openKeys={[[1,2],[2,1], [2,2]]} 
                                    selectedNotes={[35,36,48,39,51,52,53]}
                                    definedKeys={[]}/> 
            </FiveFiveColumns>

            <FullChannel>
                    <ChapterSubtitle>The Top Right Key</ChapterSubtitle>
                    <p>One more... Let's build the key to the top right, using the E key's 1 (E) and 2 ({useNoteName(Music.Gb)}) notes. They become the 5 and 6 notes of this new key.  We can start with the 6 note and define the 7, then loop around to the 4. We see from it's 1 note that this is the key of A.</p>
            </FullChannel>

            <FiveFiveColumns>
                <MapBuilderBase tonic={tonic} 
                                selectedKeys={[[1,2],[2,1],[2,2],[0,2]]} 
                                hilitedKeys={[[0,2]]}
                                openKeys={[[1,2],[2,1],[2,2]]} 
                                selectedNotes={[9,15]}
                                definedKeys={[]}/> 
                <MapBuilderBase tonic={tonic} 
                    selectedKeys={[[1,2],[2,1],[2,2],[0,2]]} 
                    hilitedKeys={[[0,2]]} 
                    openKeys={[[1,2],[2,1],[2,2],[0,2]]} 
                    selectedNotes={[9,15,8,12,13,14,16]}
                    definedKeys={[]}/> 
            </FiveFiveColumns>

            <FullChannel>
                    <ChapterSubtitle>A full 12-key HexMap</ChapterSubtitle>
                    <div>
                        <p>In order to fill out the other keys, we follow the same process.</p>
                        <p>Below is a portion of the HexMap with all twelve keys defined. We can extend this pattern in any direction infinitely. Notice that each key shares notes 1-6 with it's neighbor keys. The 7, ever the outlier, is not shared.</p>
                        <p>It's certainly a lot to look at all at once. Generally we don't view the HexMap this way. We work with only a portion of the map's notes visible. Usually 2 or 3 keys at once, depending on the task.</p>
                        <p>The version on the right is the simplified HexMap. In the next chapter we'll show you how to quickly build this simplified version by equating direction with intervals.</p>
                        <p className='bottom-block-spacing'>Practice building the HexMap with this exercise: <Link to='/MapBuilderExercisePage'>The Map Builder Exercise</Link></p>
                    </div>
             </FullChannel>  
             <FiveFiveColumns>
                <MapBuilderBase tonic={tonic} 
                    selectedKeys={[[0,1],[0,2],[0,3],[0,4],[1,1],[1,2],[1,3],[1,4],[2,0],[2,1],[2,2],[2,3]]} 
                    openKeys={[[0,1],[0,2],[0,3],[0,4],[1,1],[1,2],[1,3],[1,4],[2,0],[2,1],[2,2],[2,3]]} 
                    selectedNotes={[]}
                    definedKeys={[]}/>
                <MapBuilderBase tonic={tonic} 
                    defineAllKeys={true}
                    selectedKeys={[]} 
                    openKeys={[]} 
                    selectedNotes={[]}
                    definedKeys={[]}/>
            </FiveFiveColumns>
            <FullChannel>
                <h3 className='top-block-spacing'>Additional Exercise</h3>
                <p>With paper and pencil draw out all twelve keys with their corresponding notes, one by one, until all twelve keys have been created.</p>
            </FullChannel>
        </div>
    )
}

