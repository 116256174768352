import React from "react";
import { useNoteName } from "../../Music/Accidentals/Accidentals";
import { HKMNoteData } from "../../HKMap/HKMNoteData";
// origin keys notename
export interface HKMNoteCompProps{
    noteData:HKMNoteData
}

export class HKMIntervalLabelComp extends React.Component{
    props!:HKMNoteCompProps
    note:HKMNoteData
    constructor(props:HKMNoteCompProps){
        super(props)
        this.note = props.noteData
    }
    getTransform = () => {
        let x:number = 0
        let y:number = 0
        switch(this.note.noteIndex) { 
            case 0: { 
                x = this.note.x + this.note.keyRadius * .1 
                y = this.note.y + this.note.keyRadius * .5
               break; 
            } 
            case 1: { 
                x = this.note.x - (this.note.keyRadius * .35)
                y = this.note.y - (this.note.keyRadius * .35)
               break; 
            } 
            case 2: { 
                x = this.note.x + (this.note.keyRadius * .15)
                y = this.note.y - (this.note.keyRadius * .35)
               break; 
            } 
            case 3: { 
                x = this.note.x + (this.note.keyRadius * .35)
                y = this.note.y + (this.note.keyRadius * .1)
               break; 
            } 
            case 4: { 
                x = this.note.x - (this.note.keyRadius * .25)
                y = this.note.y - (this.note.keyRadius * .35)
               break; 
            }  
            case 5: { 
                x = this.note.x - (this.note.keyRadius * .55)
                y = this.note.y // - (this.note.keyRadius * .35)
               break; 
            }  
            case 6: { 
                x = this.note.x + this.note.keyRadius * .1 
                y = this.note.y + this.note.keyRadius * .5
               break; 
            } 
            default: {  
               break; 
            } 
         }
        const translate:string = 'translate(' + x + ' ' + y + ')'
        return translate
    }
    getLabel = () => {
        const label:string = (this.note.noteIndex === 3 || this.note.noteIndex == 0) ? '1' : '2'
        return label
    }
    render(){
        const intervalClassName:string = (this.getLabel() === '1') ? 'one' : 'two'
        const selectClassName:string = (this.note.selected) ? "selected " : ''
        const className:string = 'HKM-interval-label ' + selectClassName + intervalClassName
        return  <g className={className}>
                    <g transform={this.getTransform()}>
                        <text>{this.getLabel()}</text>
                    </g>
                </g>
    }
}

