import React from "react";
import { HKMapComponent } from "../HKMap/HKMapComponent";
import { HKMapData } from "../HKMap/HKMapData";
import { HKMapWrapperBase } from "../HKMap/HKMapWrapperBase";
import { HKMNoteData } from "../HKMap/HKMNoteData";

export interface KeyChangeMapProps{
    tonic:number
    hiddenKeys:number[][]
    openKeys:number[][]
    selectedNotes:number[]
    hilitedNotes:number[]
    selectedKeys:number[][]
    preSetLabelNotes?:[number,string][]
    rows:number
    columns:number
}
export class KeyChangeMap extends React.Component{
    radius:number = 45
    mapData:HKMapData
    props!:KeyChangeMapProps
    constructor(props:KeyChangeMapProps){
        super(props)
        this.props = props
        this.mapData = this.getMapData()
    }
    onKeyClick = () => {}
    onNoteClick = () => {}
    getMapData = () => {
        let mapData:HKMapData = new HKMapData(this.props.rows, this.props.columns, this.props.tonic, this.radius, this.onKeyClick, this.onNoteClick)
        mapData.hideAllNotes()
        mapData.hideKeys(this.props.hiddenKeys)
        mapData.openKeys(this.props.openKeys)
        mapData.setSelectedKeys(this.props.selectedKeys)
        mapData.showNotesForOpenKeysOnly()
        mapData.selectNotes(this.props.selectedNotes)
        mapData.hiliteNotes(this.props.hilitedNotes)
        if(this.props.preSetLabelNotes){
            this.props.preSetLabelNotes.forEach(([noteIndex, name]:[number, string])=>{
                mapData.notes[noteIndex].presetLabel = name

            })
        }
        return mapData
    }
    getVisibleKeys = () => {return this.mapData.getVisibleKeys()}
    getVisibleNotes = () => {return this.mapData.getVisibleNotes()}
    render = () =>{
        const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(this.radius, this.props.rows, this.props.columns)
        return <div className='map-builder-exercise'>
                        <HKMapComponent compWidth={compWidth} 
                                compHeight={compHeight} 
                                visibleKeys={this.mapData.getVisibleKeys()} 
                                visibleNotes={this.mapData.getVisibleNotes()}/>
                    </div>
    }     
}
