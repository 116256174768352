import '../chapters.css'
import { HKMSingleKey } from '../../HKMap/HKMSingleKey'
import { HKMKeyPositions } from '../../HKMap/HKMKeyPositions'
import { HKMIntervalWrapper } from '../../Helpers/IntervalSequence/HKMIntervalWrapper'
import { HKMSequenceFlowWrapper } from '../../Helpers/IntervalSequence/HKMSequenceFlowWrapper'
import { Link } from 'react-router-dom'
import { useNoteName } from '../../Music/Accidentals/Accidentals'
import { CoursePageProps } from '../../Course/CourseNavigation'
import { BaseRowBlock} from '../Layouts/TwoColumn'
import { Music } from '../../Music/Music'
import { SideBarBlock } from '../Layouts/SideBarBlock'
import { FullChannel } from '../Layouts/FullChannel'

export function HelperPatterns(props:CoursePageProps){
    return(
        <div id="chapter-two" className="chapterBlock">
            <BaseRowBlock>
                <div>
                    <p>
                        In <span className="bold">HexKey Musical Geometry</span> we arrange the notes of a key in a hexagonal pattern, the <span className="bold">HexKey Structure</span>. We assign each note in the key's scale a spatial position. This provides us with a visual representation that will help to illustrate the relationships between a given key and it's notes and chords. 
                    </p>
                    <p>
                        We can build the HexKey Structure for any key using the <span className='bold'>Musical Alphabet</span> and two helper patterns: the <span className="bold">HexKey Sequence</span> and the <span className="bold">HexKey Interval Pattern</span>. In the next chapter we'll show you how to use these two helper patterns to create a HexKey Structure.
                    </p> 
                   <p>
                        Once you're familiar with the musical alphabet and these two helpers patterns, you'll be well equipped to work with HexKey Musical Geometry in a number of ways. Both helper patterns are pretty simple to learn.
                    </p>
                </div>
                <div>
                    <SideBarBlock>
                        <div className="caption-title">
                            The Key of {useNoteName(Music.D)}
                        </div>
                        <HKMSingleKey tonic={Music.D}/>
                        <div className="caption">
                            HexKeys are built by applying the HexKey Sequence Pattern to position each note and the HexKey Interval Pattern to define each note.
                        </div>
                    </SideBarBlock>
                </div>
            </BaseRowBlock>
            <FullChannel>
                <h2>The Musical Alphabet</h2>
                <p>We can represent the musical alphabet using sharps or flats -or a combination of both:</p>
                <ul>
                    <li>A-A#-B-C-C#-D-D#-E-F-F#-G-G#</li>
                    <li>A-Bb-B-C-Db-D-Eb-E-F-Gb-G-Ab</li>
                </ul>
                <p>This can be confusing to a music theory beginner. First we see that there isn't a note with a sharp/flat (the formal term is accidental) between B & C and E & F. That's a pattern exception we just have to memorize.</p>
                <p>Another question is: Are A# and Bb the same note? Yes. For our purposes they're interchangeable. This means we can choose flats or sharps from the toggle buttton in the upper right corner of this site, and not worry too much about this distinction.</p>
                <p>A bit of background: The use of flats and sharps has to do with an earlier approach to notes and octaves. In the modern approach (called 12 equal temperament), each note is equal to the others. We could just as easily use the first twelve letters of the alphabet to name our notes and the patterns would hold up just fine.</p>
                <p>In music theory there is a naming convention that requires each letter (a,b,c,d,e,f,g) to be present in each key. This is where the sharps and flats come in. Again this is a naming convention and not representative of a tangible musical pattern. Since we're focused on the patterns, we don't have to worry about that. For the time being we can just stick with either flats or sharps and sort out the naming convention specifics after we've learned about the patterns.</p>
                
            </FullChannel>
            
            <BaseRowBlock title='The HexKey Sequence'>
                <div>
                    <p>
                        The HexKey Sequence shows us the position of each note in the HexKey Structure. 
                        You’ll notice that the sequence starts at the top and flows in a figure-eight pattern, creating a loop.
                    </p> 
                    <p>
                        The notes and chords of each key are often referred to by their number. This is sometimes called <span className="bold">'Nashville numbering'</span>, since it's the preferred method for session players in the music industry.
                    </p>
                    <p>
                        By using numbers instead of note names, we can easily transpose music to any other key. It's a way to focus on the musical pattern instead of the literal notes. If someone wanted to play a song in a different key, the numbers and patterns stay the same, but the notes shift. Its the cleanest, most insightful way think about musical patterns, especially if you're just learning music theory. 
                    </p>
                    <p>
                        There's an exercise to help you memorize the HexKey Sequence. Take a few minutes to learn this pattern. There's a 'back' link on the exercise page that will return you here when you're done.
                    </p>
                    <p>
                        <span className='bold'>
                            <Link to='/SequenceMemorizer'>The HexKey Sequence Exercise</Link>
                        </span>
                    </p>
                    <p>The exercise can also be found along with all the other exercises in the table of contents.
                    </p>
                </div>
                <div>
                    <SideBarBlock>
                        <div className="caption-title">The HexKey Sequence Pattern</div>
                        <HKMKeyPositions/>
                        <HKMSequenceFlowWrapper/>
                    <div className="caption">The HexKey Sequence flows in a figure eight-shaped loop</div>
                    </SideBarBlock>
                </div>
            </BaseRowBlock>
            <BaseRowBlock title='The HexKey Interval Pattern' omitDivider={true}>
                <div>
                    <p>
                        The HexKey Interval Pattern is an overlay of the major scale interval sequence 2212221 onto the HexKey sequence.  
                    </p> 
                    <p>
                        The major scale interval sequence defines the number of interval steps from one note to the next. For example: the first number in the interval sequence is 2, this tells us there is a 2-interval step from the first note to the second note.</p>
                    <p> 
                        If the first note is A, the second note is B. [<span className='bold'>A, {useNoteName(10)}, B...</span> ] This is how we define each note in the key. Note that we prefer to use the term interval instead of semi-tone or half-step. It has the same meaning, but is a more generic term.
                    </p>
                    <p>
                        Notice that the interval steps along the perimeter of the hexagon are all twos. There are only a couple instances where we see ones: the crossover from position three (bottom right) to position four (top left) and the connection from postition seven in the center back to position one on top.
                    </p> 
                    <p>
                        If you're mindful of the places where the ones are, it'll be easy to memorize this pattern. You’ll be using this pattern on a regular basis for a variety of reasons.</p>
                    <p>
                        Here's the exercise: <span className='bold'>
                            <Link to='/IntervalMemorizer'>The HexKey Interval Exercise</Link>
                        </span>.
                    </p> 
                    <p>
                        Take a few minutes to work through this exercise and you'll be ready to start building HexKeys.
                    </p>
                </div>
                <div>
                    <SideBarBlock>
                        <div className='caption-title'>The HexKey Interval Pattern</div>
                        <HKMIntervalWrapper/>
                        <div className="caption">
                            Two-interval steps are shown in black, one-interval steps are in grey.
                        </div>
                    </SideBarBlock>

                </div>
            </BaseRowBlock>
        </div>
    )
}

