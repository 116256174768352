import '../chapters.css'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { Link } from 'react-router-dom'
import { useNoteName } from '../../Music/Accidentals/Accidentals'
import { ChordBuilderBase } from '../../KeyBuilderWalkThrough/ChordBuilderBase'
import { HKMKeyChordHelper } from '../../HKMap/HKMKeyChordHelper'
import { Music } from '../../Music/Music'
import { CoursePageNavigationLink, CoursePageProps } from '../../Course/CourseNavigation'
import { FullChannel } from '../Layouts/FullChannel'
import { BaseRowBlock } from '../Layouts/TwoColumn'
import { SideBarBlock } from '../Layouts/SideBarBlock'

export function DiatonicChords(props:CoursePageProps){
    return(
        <div id="diatonic-chords" className="chapterBlock">
            <FullChannel>
                <h3>The 1-3-5 Formula</h3>
                <p>In this chapter we build the diatonic chords of a key. If you've ever searched to find out which chords belong in a particular key, you may have wondered 'why those chords?' and 'why are some major and other minor?'. It turns out it's not a rule that's being imposed, it's a pattern that emerges from the major scale interval pattern. We're going to explore the process of building the chords of a key and illustruate why some are major and others minor.</p> 
                <p>To build the diatonic chords of any key, we chose each note in the key as <a href=""></a> 'root note' and apply the 1-3-5 formula - the root note is the 1. There are 7 notes so there are seven basic chords in a key.</p>
                <p>We'll use the key of D as an example...</p>     
            </FullChannel>
            <BaseRowBlock title='The One Chord'>
                <div>
                    <p>D is the root note for our 1 chord, since it's the 1 note in the key. Following the HexKey Sequence from D we see that, {useNoteName(6)} is the 3rd note in the sequence and A is the 5th note. Remember, the sequence starts at the root note. The arrows of the modified HexKey show the path we've taken. (We'll address why some are highlighted below)</p>
                    <p>We can see that the 1 chord in the Key of D is comprised of D, {useNoteName(Music.Gb)} and A. But is it major or minor?</p>
                    <p>In music theory a chord is major if the interval distance from the root note to the 3rd is 4. A chord is minor if the interval distance from the root note to the 3rd is 3</p>
                    <p>If we look at the HexKey's highlighted arrows we see that the interval from D to E is 2. The interval from E to {useNoteName(Music.Gb)} is 2. Add them together and we find that the interval from 1st to 3rd is 4. It's a major chord. D major is the 1 chord of the D key.</p>      
                </div>
                <SideBarBlock title='The One Chord: D major'>
                    <ChordBuilderBase 
                        radius={80} 
                        hilitedNotes={[0]}
                        selectedNotes={[0,2,4]}  
                        definedNotes={[0,1,2,3,4,5,6]}
                        definedArrows={[1,2,3,4]}
                        selectedArrows={[1,2]}/>
                </SideBarBlock>
            </BaseRowBlock>

            <BaseRowBlock title='The Two Chord'>
                <div>
                    <p>Now we'll use the same methodology to define the 2 chord. E is the 2 note so it's the root note of the 2 chord. G is the 3rd note and B is the 5th note. The modified HexKey shows the path we've taken.</p>
                    <p>The 2 chord is comprised of E, G and B.</p>
                    <p>To determine if it's major or minor we can again reference the HexKey Interval Pattern. The highlighted arrows show that the interval distance from E to G is 3. (2 + 1). So the 2 chord is E minor.</p>     
                </div>
                <SideBarBlock title='E minor, the Two Chord'> 
                    <ChordBuilderBase 
                                radius={80} 
                                hilitedNotes={[1]}
                                selectedNotes={[1,3,5]}  
                                definedNotes={[0,1,2,3,4,5,6]}
                                definedArrows={[2,3,4,5]}
                                selectedArrows={[2,3]}/>
                 </SideBarBlock>
            </BaseRowBlock>



            <BaseRowBlock title='The Three Chord'>
                <ul>
                    <li>The 3 chord is rooted at the 3 position: {useNoteName(Music.Gb)}</li>
                    <li>It's 3rd is A</li>
                    <li>It's 5th is {useNoteName(Music.Db)}</li>
                    <li>The interval between 1st and 3rd is 3. It's a minor chord.</li>
                    <li>The 3 chord is F# minor ({useNoteName(Music.Gb)}, A, {useNoteName(Music.Db)})</li>
                </ul>
                <SideBarBlock title={useNoteName(Music.Gb) + ' minor, the Three Chord'}> 
                    <ChordBuilderBase 
                                    radius={80} 
                                    hilitedNotes={[2]}
                                    selectedNotes={[2,4,6]}  
                                    definedNotes={[0,1,2,3,4,5,6]}
                                    definedArrows={[3,4,5,6]}
                                    selectedArrows={[3,4]}/>
                    </SideBarBlock>
            </BaseRowBlock>

            <BaseRowBlock title='The Four Chord'>
                    <ul>
                        <li>The 4 chord is rooted at the 4 position: G</li>
                        <li>It's 3rd is B</li>
                        <li>It's 5th is D</li>
                        <li>The interval between 1st and 3rd is 4. It's a major chord.</li>
                        <li>The 4 chord is F# major (G, B, D)</li>
                    </ul>
                <SideBarBlock title={useNoteName(Music.G) + ' major, the Four Chord'}> 
                    <ChordBuilderBase 
                                    radius={80} 
                                    hilitedNotes={[3]}
                                    selectedNotes={[3,5,0]}  
                                    definedNotes={[0,1,2,3,4,5,6]}
                                    definedArrows={[4,5,6,0]}
                                    selectedArrows={[4,5]}/>
                </SideBarBlock>
            </BaseRowBlock>   

            <BaseRowBlock title='The Five Chord'>
                    <ul>
                        <li>The 5 chord is rooted at the 5 position: A.</li>
                        <li>It's 3rd is {useNoteName(Music.Db)}</li>
                        <li>It's 5th is E</li>
                        <li>The interval between 1st and 3rd is 4. It's a a major chord.</li>
                        <li>The 5 chord is A major. (A, {useNoteName(Music.Db)}, E)</li>
                    </ul>
                <SideBarBlock title={useNoteName(Music.A) + ' major, the Five Chord'}> 
                    <ChordBuilderBase 
                            radius={80} 
                            hilitedNotes={[4]}
                            selectedNotes={[4,6,1]}  
                            definedNotes={[0,1,2,3,4,5,6]}
                            definedArrows={[5,6,0,1]}
                            selectedArrows={[5,6]}/>
                </SideBarBlock>
            </BaseRowBlock>      

            <BaseRowBlock title='The Six Chord'>
                <ul>
                    <li>The 6 chord is rooted at the 6 position: B</li>
                    <li>It's 3rd is D</li>
                    <li>It's 5th is {useNoteName(Music.Gb)}</li>
                    <li>The interval between 1st and 3rd is 3. It's a minor chord.</li>
                    <li>The 6 chord is B minor. (B, D, {useNoteName(Music.Gb)})</li>
                </ul>
                <SideBarBlock title={useNoteName(Music.B) + ' minor, the Six Chord'}> 
                    <ChordBuilderBase 
                        radius={80} 
                        hilitedNotes={[5]}
                        selectedNotes={[5,0,2]} 
                        definedNotes={[0,1,2,3,4,5,6]}
                        definedArrows={[6,0,1,2]}
                        selectedArrows={[6,0]}/>
                </SideBarBlock>
            </BaseRowBlock>      

            <BaseRowBlock title='The Seven Chord'>
                    <ul>
                        <li>The 7 chord is rooted at the 7 position: {useNoteName(Music.Db)}</li>
                        <li>It's 3rd is E</li>
                        <li>It's 5th is G</li>
                        <li>The interval from 1st to 3rd is 3, but there's a twist. The interval from 1st to 5th ({useNoteName(Music.Db)} to G) is 6 (1 + 2 + 2 + 1). All minor and major chords have an interval from 1st to 5th of 7 (a perfect 5th). The term for a chord without a perfect 5th is <span className='bold'>half-diminished</span>.</li>
                        <li>The 7 chord is {useNoteName(Music.Db)} half-diminished ({useNoteName(Music.Db)}, E, G)</li>
                    </ul>
                <SideBarBlock title={useNoteName(Music.Db) + ' diminished, the Seven Chord'}> 
                        <ChordBuilderBase 
                            radius={80} 
                            hilitedNotes={[6]}
                            selectedNotes={[6,1,3]} 
                            definedNotes={[0,1,2,3,4,5,6]}
                            definedArrows={[0,1,2,3]}
                            selectedArrows={[0,1,2,3]}/>
                </SideBarBlock>
            </BaseRowBlock>
            <BaseRowBlock title='Chord types for all keys'>
                <div>
                    <p>Since all keys have the same interval pattern, all chords (1 through 7) in a major scale key are of the same type, minor, major or half-diminished.</p>                  
                    <p>We can derive a rule of thumb for all keys:</p> 
                    <ul>
                        <li>All 1, 4 and 5 chords are major.</li>
                        <li>All 2, 3, and 6 chords are minor.</li>
                        <li>All 7 chords is half-diminished.</li>
                    </ul>
                    <p>There's a visual helper pattern we can use to remember this. If we draw a diagonal line through the HexKey Structure from top right to bottom left we see that:</p>
                    <ul>
                        <li>All the majors are on the top left side of the line (1, 4, 5)</li>
                        <li>All the minors are on the bottom right side (2, 3, 6)</li>
                        <li>The half-diminished chord is in the center (7)</li>
                    </ul>
                    <p>This helper pattern will come in handy as you get to know HexKey Musical Geometry.</p>   
                </div>
                <SideBarBlock title='The Chord Type Helper Pattern'>
                    <HKMKeyChordHelper radius={75}/>
                     
                    <div className='caption'>Majors on the top left, Minors on the bottom right, Half-Diminished in the center</div>
                </SideBarBlock>
            </BaseRowBlock>
        </div>
    )
}

