import '../../Chapters/chapters.css'
import '../../KeyBuilderWalkThrough/KeybuilderWalkthrough.css'
import { Link } from 'react-router-dom'
import { MusicalAlphabet } from '../KeyBuilderExercise/MusicalAlphabet'
import { KeyBuilderBase } from '../../KeyBuilderWalkThrough/KeyBuilderBase'
import { IntervalBase } from '../../KeyBuilderWalkThrough/IntervalBase'
import { SequenceBase } from '../../KeyBuilderWalkThrough/SequenceBase'
import { useNoteName } from '../../Music/Accidentals/Accidentals'
import { ChordBuilderBase } from '../../KeyBuilderWalkThrough/ChordBuilderBase'
import { ChordIntervalBase } from '../../KeyBuilderWalkThrough/ChordIntervalBase'
import { HKMKeyChordHelper } from '../../HKMap/HKMKeyChordHelper'
import { KeyBuilderHelpers } from '../../KeyBuilder'
import { BaseRowBlock } from '../../Chapters/Layouts/TwoColumn'
import { SideBarBlock } from '../../Chapters/Layouts/SideBarBlock'
import { FullChannel } from '../../Chapters/Layouts/FullChannel'
import { KeyBuilderExercise } from '../KeyBuilderExercise/KeyBuilderExercise'
import { Helpers } from '../KeyBuilderExercise/Helpers'
import { HexKeyMemorizerHelpers } from './HexKeyMemorizerHelpers'
import { useState } from 'react'
import { Music } from '../../Music/Music'

export function HexKeyMemorizerPage(){
    const [tonic, setTonic] = useState(0)
    const updateTonic = (tonic:number) => {
        setTonic(tonic)
    }
    return(
        <div id="key-memorizer-exercise-page" className="chapterBlock">
            <h2>The HexKey Memorizer</h2>
            <FullChannel>
                <p>This exercise will help you memorize a given key by asking you to identify it's seven positions one at a time. Select any key from the dropdown menu.</p>
                <p>We recommend staying on one key until you know it very well. Try a single key for one session and then repeat that key in another session several hours later or the next day.</p>
                <p>We also recommend pairing this exercise with the <Link to={'/NeighborMemorizer'}>HexMap Neighbor Memorizer</Link> exercise. Once you know the notes of a key, you can use the HexMap Compass to define it's neighbor keys. This will help to reinforce your memory of the key and also to memorize a section of the HexMap as well.</p>
                <p>One efficient way of progressing through the keys is to start with the whole notes (A, B, C, D, D, F, G). Once you know D, for example, learning D# will come easy since all the notes of D are sharpened by one interval in D#. This also works if you choose to work with flats instead of sharps.</p>
                <p>You might also want to choose to follow an axis in the HexMap, like the Axis of 6ths. For example: G-E-{useNoteName(Music.Db)}-{useNoteName(Music.Bb)}</p>
            </FullChannel>
            <BaseRowBlock omitDivider={true}><span></span><span></span></BaseRowBlock>
            <BaseRowBlock omitDivider={true}>
                <div>
                    <div className='centered'>
                        <KeyBuilderExercise quizMode={true} updateTonic={updateTonic}/>
                    </div>
                </div>
                <SideBarBlock>
                    <HexKeyMemorizerHelpers tonic={tonic}/>
                </SideBarBlock>
            </BaseRowBlock>
            <FullChannel>
                <h3>Additional Exercises:</h3>
                <p>Visualize the key you've been memorizing that day. Cycle through the positions of the key and repeat to yourself the position number and the note. For example "The 5th of C is G."</p>
                <p>After you've got a key's notes and positions well-memorized, look on-line for songs in that key. For example if you've memorized the key of C, look for songs in C major. Transcribe the chords of that song into their corresponding parent key positions and see what kind of chord progressions are at work. For example, is it a 1-4-5 progression? The more you work with a key in a practical way, the stronger your memory will be.</p>
                <p>Later, when you're more advanced, switch to the other six modes of the key. For example the Aeolian mode in C is A Aeolian. This is commonly labeled as 'A minor', the relative minor. The Dorian mode in C is D Dorian. Use roman numeral notation to label the notes (their position is relative to the root of the mode, the numbers are upper case for major and lower case for minor)</p>
            </FullChannel>
        </div>
    )
}

