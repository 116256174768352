import { useEffect } from "react"
import gsap from 'gsap'
import { CourseState } from "./CourseState"
import { useCourseStore } from "./CourseStore"
import { HashRouter as Router, Route, Link, Routes, BrowserRouter, useLocation } from 'react-router-dom'
import { CourseNavigation, CoursePage } from "./CourseNavigation"
import { FullChannel } from "../Chapters/Layouts/FullChannel"


export interface TableOfContentsProps{
    isOpen:boolean
}
export function TableOfContents(){
    const location = useLocation()
    const isOpen:boolean = useCourseStore((state:CourseState)=>{
        return state.tocIsOpen
      }) 

    const getClassNames = () => {
        let classes:string[] = ['toc']
        if(isOpen) classes.push('open')
        return classes.join(' ')
    }
    const getChapters = () => {
        const chapters:CoursePage[] = CourseNavigation.getChapters()
        return chapters.map((chapter:CoursePage, index:number) => {
            return <li key={index}><Link to={chapter.path}><div>{chapter.title}</div></Link></li>
        })
    }
    const getConceptualExercises = () => {
        const exercises:CoursePage[] = CourseNavigation.getConceptualExercises()
        return exercises.map((exercise:CoursePage, index:number) => {
            return <li key={index}><Link to={exercise.path}><div>{exercise.title}</div></Link></li>
        })
    }

    const getMemorizationExercises = () => {
        const exercises:CoursePage[] = CourseNavigation.getMemorizationExercises()
        return exercises.map((exercise:CoursePage, index:number) => {
            return <li key={index}><Link to={exercise.path}><div>{exercise.title}</div></Link></li>
        })
    } 

    const getHelperPatternExercises = () => {
        const exercises:CoursePage[] = CourseNavigation.getHelperPatternExercises()
        return exercises.map((exercise:CoursePage, index:number) => {
            return <li key={index}><Link to={exercise.path}><div>{exercise.title}</div></Link></li>
        })
    }  
    const onTocClick = (e:React.MouseEvent) => {
        e.stopPropagation()
    }
    const closeToc:Function = useCourseStore((state:CourseState)=>{
        return ()=>{
            if(state.tocIsOpen){state.closeToc(state)}
        }
    })
    const onCloseClick = (e:React.MouseEvent) => {
        e.stopPropagation()
        closeToc()
    }
    const getTOC = () => {
        if(!isOpen){
            return null
        }
        else{
            return  <div className={getClassNames()} onClick={onTocClick}>
                        <div className='toc-inner'>
                            <div className='row'>
                                <div className='toc-channel'>
                                    <div className='bold'>Chapters:</div>
                                    <ol>
                                        {getChapters()}
                                    </ol>
                                </div>
                                <div className='toc-channel'>
                                    <div className='bold'>Conceptual Exercises:</div>
                                    <ol> 
                                        {getConceptualExercises()}
                                    </ol>
                                    <div className='bold'>Memorization Exercises</div>
                                    <ol> 
                                        {getMemorizationExercises()}
                                    </ol>
                                </div>
                                <div className='toc-channel'>
                                    <div className='bold'>Helper Pattern Exercises:</div>
                                    <ol> 
                                        {getHelperPatternExercises()}
                                    </ol>
                                </div>

                            </div>
                            <div className='centered close-button' onClick={onCloseClick}>
                                <button type='button'>close</button>
                            </div>
                        </div>
                    </div>
        }
    }

    return getTOC()

}