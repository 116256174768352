export interface IntervalButtonsProps{
    onClick:Function
}
export interface IntervalButtonProps{
    onClick:Function
    row:number
    column:number
    label:string
} 

export function IntervalButton(props:IntervalButtonProps){
    const onButtonClick = (e:React.MouseEvent) => {
        e.stopPropagation(); 
        props.onClick(props.row,props.column,props.label)  
    }
    return <button className="interval-button" 
        onClick={onButtonClick} type="button">
            {props.label}
        </button>
                    
}

export function IntervalButtons(props:IntervalButtonsProps){
        const className:string = 'interval-buttons'
        return  <div className={className}>
                    <IntervalButton onClick={props.onClick} row={0} column={0} label='b6th'/>
                    <IntervalButton onClick={props.onClick} row={0} column={1} label='4th'/>
                    <IntervalButton onClick={props.onClick} row={1} column={0} label='b3rd'/>
                    <IntervalButton onClick={props.onClick} row={1} column={2} label='6th'/>
                    <IntervalButton onClick={props.onClick} row={2} column={0} label='5th'/>
                    <IntervalButton onClick={props.onClick} row={2} column={1} label='3rd'/>
                 </div>
}