import { CourseNavigation, CourseNavigationLinkProps, CoursePage, CoursePageNavigationLink } from "../../Course/CourseNavigation";

export function ChapterShell(props:any){
    
    return <div className='chapter-shell'>
                <CoursePageNavigationLink index={props.index}/>
                <ChapterTitle index={props.index}/>
                {props.children}
                <CoursePageNavigationLink index={props.index}/>
            </div>
}

function ChapterTitle(props:CourseNavigationLinkProps){
    const title = CourseNavigation.getChapters()[props.index].title
    return <h1>{title}</h1>
}