import { KeyChangeMap } from "../../MapBuilderWalkThrough/KeyChangeMap";
import { Music } from "../../Music/Music";
import { GiantStepsKeyChangeMap, GiantStepsKeyChangeMapProps } from "./GiantStepsKeyChangeMap";


export function GiantStepsColumns(){
    const keyProgressions:GiantStepsKeyChangeMapProps[] = [ 
        {keyNote:Music.B, index:2, positions:[1]},
        {keyNote:Music.G, index:1, positions:[5,1]},
        {keyNote:Music.Eb, index:1 ,positions:[5,1]},
        {keyNote:Music.G, index:1, positions:[2,5,1]},
        {keyNote:Music.Eb, index:1, positions:[5,1]},
        {keyNote:Music.B, index:1, positions:[5,1]},
        {keyNote:Music.Eb, index:1, positions:[2,5,1]},
        {keyNote:Music.G, index:1, positions:[2,5,1]},
        {keyNote:Music.B, index:2, positions:[2,5,1]},
        {keyNote:Music.Eb, index:2, positions:[2,5,1]},
        {keyNote:Music.B, index:2, positions:[2,5]}
    ]
    const getProgressionMaps = function(index:number){
        const progression:GiantStepsKeyChangeMapProps = keyProgressions[index]
        if(progression === undefined) return null
        return <GiantStepsKeyChangeMap {...progression}/>
    }
    const getRow = function(index:number){
        return   <GiantStepsRow>
                    {getColumnsForRow(index)}
                </GiantStepsRow>
    }
    const getColumnsForRow = function(rowIndex:number){
        const columnsInRows:number = 3
        let columns:JSX.Element[] = []
        for(let columnIndex = 0; columnIndex < columnsInRows; columnIndex++){
            const progression:JSX.Element | null = getProgressionMaps((rowIndex * columnsInRows) + columnIndex)
            if(progression){
                const columnComp:JSX.Element = <GiantStepsColumn>{progression}</GiantStepsColumn> 
                columns.push(columnComp)         
            }
        }
        return columns
    }

    const getAllRowsOfComps = () => {
        let allRows:JSX.Element[] = []
        const numberOfRows:number = Math.ceil(keyProgressions.length/3)
        for(let i:number = 0; i < numberOfRows; i++){
            allRows.push(getRow(i))
        }
        return  <>
                    {allRows}
                </>
    }

    return  <div className='giant-steps-columns'>
                {getAllRowsOfComps()}
            </div>

}

export function GiantStepsColumn(props:GiantStepsColumnsProps){
    return <div className='giant-steps-column'>
                {props.children}
            </div>
}

export interface GiantStepsColumnsProps{
    children:React.ReactNode
}

export interface GiantStepsRowProps{
    children:React.ReactNode
}

export function GiantStepsRow(props:GiantStepsRowProps){
        return  <div className='row giant-steps'>
                    {props.children}
                </div>
}