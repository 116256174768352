import '../chapters.css'
import { useNoteName } from '../../Music/Accidentals/Accidentals'
import { MapBuilderBase } from '../../MapBuilderWalkThrough/MapBuilderBase'
import { Music } from '../../Music/Music'
import { MapBuilderKeyDirections } from '../../MapBuilderWalkThrough/MapBuilderKeyDirections'
import { MapBuilderAxisOfFifths } from '../../MapBuilderWalkThrough/MapBuilderAxisOfFifths'
import { MapBuilderAxisOfThirds } from '../../MapBuilderWalkThrough/MapBuilderAxisOfThirds'
import { MapBuilderAxisOfSixths } from '../../MapBuilderWalkThrough/MapBuilderAxisOfSixths'
import { MapBuilderAxisOfSixthsExpanded } from '../../MapBuilderWalkThrough/MapBuilderAxisOfSixthsExpanded'
import { MapBuilderAxisOfThirdsExpanded } from '../../MapBuilderWalkThrough/MapBuilderAxisOfThirdsExpanded'
import { CoursePageProps } from '../../Course/CourseNavigation'
import { HexKeyCompass } from '../../Helpers/HexKeyCompass/HexKeyCompass'
import { BaseRowBlock, ReverseRowBlock } from '../Layouts/TwoColumn'
import { SideBarBlock } from '../Layouts/SideBarBlock'
import { FullChannel } from '../Layouts/FullChannel'
import { Link } from 'react-router-dom'

export function SimplifiedHexMap(props:CoursePageProps){
    const tonic:number = 3
    return(
        <div className="chapterBlock">
            <ReverseRowBlock>
                <div>
                    <h3>Using Directions As Intervals</h3>
                    <p>In the previous chapter we introduced the simplified HexMap. We can build this simplified version of the HexMap by analyzing the spatial relationships of the keys and correlating those with their interval distances - their harmonic relationships.</p>
                    <p>This allows us to equate a directional step with an interval step. Using this technique we can determine a key's neighbors much faster and easier than plotting out their notes. Let's examine a key's relationship with its neighbors.</p>    
                </div>
                <SideBarBlock title="The Simplified HexMap">
                    <MapBuilderBase tonic={tonic}
                        selectedKeys={[]} 
                        openKeys={[]} 
                        selectedNotes={[]}
                        defineAllKeys={true}/>
                </SideBarBlock>
            </ReverseRowBlock>
            <ReverseRowBlock>
                <div>
                    <h3>The HexMap Compass</h3>
                    <p>As we proceed through this chapter, correlating direction with interval distance, we're going to build a new helper pattern: The HexMap Compass</p>
                    <p>We can use the Hexmap Compass to define any key's neighbors without defining their individual notes. We can also use it to understand the harmonic relationships between keys, informing our understanding of key changes. The complete HexMap Compass is shown to the right.</p>    
                </div>
                <SideBarBlock title="The Full HexMap Compass">
                    <HexKeyCompass/>
                </SideBarBlock>
            </ReverseRowBlock>
            <ReverseRowBlock>
                <div>
                    <h3>Moving Down and Left: Moving in 5ths</h3>
                    <p>Let's look at the relationship between the key of E and it's neighbor to the bottom left.</p>
                    <p>It's the key of B, E's fifth note. We can conclude that moving down and left by one key is equivalent to moving a major fifth, 7 intervals. (btw/ This is why the circle of fifths emerges in this direction.) </p>
                     <p>This means we can define the bottom left key of the HexMap Compass as a 5th interval.</p>
                    <p>We can apply this technique to define each of the six possible directional steps.</p>
                    <p>We see that the 2 note of E ({useNoteName(Music.Gb)}) and the 2 note of B ({useNoteName(Music.Db)}) are also a fifth away. Each corresponding position in B is a fifth above it's counterpart in E. This illustrates that each key is a shifted version of it's neighbor. In this case a fifth.</p>
                    <p>In this geometric space, a vector pointing in the lower left direction of one key length is equivalent to moving by a fifth (7 intervals).</p>
                </div>
                <SideBarBlock>
                    <MapBuilderBase tonic={tonic} 
                        selectedKeys={[[1,2],[2,1]]} 
                        openKeys={[[1,2]]} 
                        selectedNotes={[32]}
                        defineAllKeys={true}/>
                </SideBarBlock>
                <SideBarBlock title='The 5th Direction of the HexMap Compass'>
                    <MapBuilderKeyDirections tonic={tonic} 
                        radius={35}
                        selectedKeys={[[2,1]]} 
                        hilitedKeys={[[2,1]]}
                        openKeys={[]} 
                        selectedNotes={[]}
                        definedKeys={[[2,1]]}/>
                </SideBarBlock>
            </ReverseRowBlock>
            <ReverseRowBlock>
                <div>
                    <h3>Moving up and right: Moving in 4ths</h3>
                    <p>If we move in the opposite direction, we see E's neighbor key to the top right is A. The key of E's 4th note is A.</p>
                    <p>We can conclude that moving up and right by one key is equivalent to moving by a fourth (5 intervals).</p>
                    <p>We can define a key's neighbor to the top right by knowing the fourth of the original key. </p>
                    <p>We can also define the top right direction in the HexMap Compass as a step of 5 intervals, a fourth. </p>
                 </div>
                 <SideBarBlock>
                    <MapBuilderBase tonic={tonic} 
                        selectedKeys={[[1,2],[0,2]]} 
                        openKeys={[[1,2]]} 
                        selectedNotes={[10]}
                        defineAllKeys={true}/>
                </SideBarBlock>
                <SideBarBlock title="The 4th Direction of the HexMap Compass">
                    <MapBuilderKeyDirections tonic={tonic} 
                        radius={35}
                        selectedKeys={[[2,1], [0,2]]} 
                        openKeys={[]} 
                        selectedNotes={[]}
                        hilitedKeys={[[0,2]]}
                        definedKeys={[[2,1], [0,2]]}/>
                </SideBarBlock>
            </ReverseRowBlock>
            <ReverseRowBlock>
                <div>
                    <h3>The Axis of 5ths</h3>
                    <p>We see that by moving in 4ths or 5ths we move along the same diagonal axis. If we extend the pattern we see that this axis repeats after twelve keys. For brevity sake we can label it as the Axis of 5ths.</p> 
                    <p>One twelve-key column along this axis reveals the full circle of 5ths.</p>
                    <p>If we compare the intervals going up and down the axis of 5ths we see that the intervals add up to 12 (4ths are 5 intervals, 5ths are 7 intervals).</p>
                    <p>This is the case for each axis. Their opposing directions, as intervals, add up to 12.</p>
                </div>
                <SideBarBlock title="The Circle of 5ths in the Axis of 5ths">
                    <MapBuilderAxisOfFifths/>
                </SideBarBlock>
            </ReverseRowBlock>

            <ReverseRowBlock>
                <div>
                    <h3>Moving down and right: Moving in 3rds</h3>
                    <p>E's neighbor key to the bottom right is {useNoteName(Music.Ab)}, E's 3rd note is {useNoteName(Music.Ab)}. Its also the 1 note of {useNoteName(Music.Ab)}.</p>
                    <p>We can conclude that moving down and right by one key is equivalent to moving a major third (4 intervals). We can define a key's neighbor to the bottom right by knowing the third of that key. </p>
                    <p> We can add a major third to the compass in the bottom right direction.
                    Whenever we move in the bottom right direction, we are moving up a major third (4 intervals)</p>
                </div>
                <SideBarBlock>
                    <MapBuilderBase tonic={tonic} 
                                    selectedKeys={[[1,2],[2,2]]} 
                                    openKeys={[[1,2]]} 
                                    selectedNotes={[35]}
                                    defineAllKeys={true}/>
                </SideBarBlock>
                <SideBarBlock title="The 3rd Direction of the HexMap Compass">
                    <MapBuilderKeyDirections tonic={tonic} 
                            radius={35}
                            selectedKeys={[[2,1],[2,2], [0,2]]} 
                            openKeys={[]} 
                            selectedNotes={[]}
                            hilitedKeys={[[2,2]]}
                            definedKeys={[[2,1],[2,2], [0,2]]}/>
                </SideBarBlock>
            </ReverseRowBlock>
            <ReverseRowBlock>
                <div>
                    <h3>Moving up and left: Moving in flat 6ths</h3>
                    <p>E's neighbor key to the top left is C. C doesn't exist in the key of E but E's 6th note is {useNoteName(Music.Db)}. If we flatten E's sixth note, we get C. We can conclude that moving up and left by one key is equivalent to moving a flat sixth (8 intervals).</p>
                    <p>We can define a key's neighbor to the upper left by flattening the sixth of that original key by one interval. </p>
                    <p>We can add a flat 6th to the HexMap Compass in the upper left direction.</p>
                </div>
                <SideBarBlock>
                    <MapBuilderBase tonic={tonic}
                        selectedKeys={[[1,2],[0,1]]} 
                        openKeys={[[1,2]]} 
                        selectedNotes={[36]}
                        defineAllKeys={true}/>
                </SideBarBlock>
                <SideBarBlock title='The Flat Sixth Direction of the HexMap'>
                    <MapBuilderKeyDirections tonic={tonic} 
                        radius={35} 
                        selectedKeys={[[2,1],[2,2], [0,2],[0,1]]} 
                        openKeys={[]} 
                        selectedNotes={[]}
                        hilitedKeys={[[0,1]]}
                        definedKeys={[[2,1],[2,2],[0,2],[0,1]]}/>
                </SideBarBlock>
            </ReverseRowBlock>

            <ReverseRowBlock>
                <div>
                        <h3>The Axis of 3rds</h3>
                        <p>When moving in 3rds or flat 6ths, we're moving along the same diagonal axis. This axis, from top left, to bottom right, repeats after only 3 keys.</p>
                        <p>We see that four consecutive diagonal columns represent the 4 cycles of 3rds, each cycle consists of only 3 keys. They are, in no specific order:</p>
                        <ul>
                            <li>{useNoteName(Music.Eb)}, G, B</li>
                            <li>C, E,{useNoteName(Music.Ab)}</li>
                            <li>A, {useNoteName(Music.Db)}, F</li>
                            <li>{useNoteName(Music.Gb)}, {useNoteName(Music.Bb)}, D</li>
                        </ul> 

                        <p>The intervals moving along this axis are: 4 (3rds) and 8 (flat 6ths) </p>
                    </div>
                    <SideBarBlock title='A Single Column: One Cycle of 3rds'>
                        <MapBuilderAxisOfThirds/>
                    </SideBarBlock>
                    <SideBarBlock title='Four Consecutive Columns: The Four Cycles of 3rds'>
                        <MapBuilderAxisOfThirdsExpanded/>
                    </SideBarBlock>
            </ReverseRowBlock>

            <ReverseRowBlock>
                <div>
                    <h3>Moving right: Moving in 6ths</h3>
                    <p>E's neighbor key to the right is {useNoteName(Music.Db)}, E's sixth note is {useNoteName(Music.Db)}.</p>
                    <p>We can conclude that moving to the right by one key is equivalent to moving a sixth. We can define a key's neighbor to the right by knowing the sixth of that key. </p>
                    <p>We can add a 6th to the compass in the right direction. Whenever we move in the right direction, we are moving up by a 6th (9 intervals)
                    </p>
                </div>
                <SideBarBlock>
                    <MapBuilderBase tonic={tonic} 
                                    selectedKeys={[[1,2],[1,3]]} 
                                    openKeys={[[1,2]]} 
                                    selectedNotes={[36]}
                                    defineAllKeys={true}/>

                </SideBarBlock>
                <SideBarBlock title='The 6th Direction of the HexMap Compass'>
                    <MapBuilderKeyDirections tonic={tonic}
                        radius={35} 
                        selectedKeys={[[2,1],[2,2], [0,2],[0,1],[1,3]]} 
                        openKeys={[]} 
                        selectedNotes={[]}
                        hilitedKeys={[[1,3]]}
                        definedKeys={[[2,1],[2,2],[0,2],[0,1],[1,3]]}/>
                </SideBarBlock>
            </ReverseRowBlock>           
            <ReverseRowBlock>
                <div>
                    <h3>Moving left: Moving in flat 3rds (minor 3rds)</h3>
                    <p>E's neighbor key to the left is G, E's third note is {useNoteName(Music.Ab)}. </p>
                    <p>If we flatten E's third we get G. We can conclude that moving to the left by one key is equivalent to moving a flat third (aka a minor third). We can define a key's neighbor to the left by knowing the third of that key and flattening it by one interval. </p>
                    <p>We can add a flat 3rd to the compass in the W direction.
                    Whenever we move in the left direction, we are moving up by a flat 3rd (3 intervals)</p>
                </div>
                <SideBarBlock>
                    <MapBuilderBase tonic={tonic} 
                                    selectedKeys={[[1,2],[1,1]]} 
                                    openKeys={[[1,2]]} 
                                    selectedNotes={[35]}
                                    defineAllKeys={true}/>
                </SideBarBlock>
                <SideBarBlock title="The flat 3rd Direction of the HexMap Compass">
                    <MapBuilderKeyDirections tonic={tonic} 
                        radius={35}
                        selectedKeys={[[2,1],[2,2], [0,2],[0,1],[1,1],[1,3]]} 
                        openKeys={[]} 
                        selectedNotes={[]}
                        hilitedKeys={[[1,1]]}
                        definedKeys={[[2,1],[2,2],[0,2],[0,1],[1,1],[1,3]]}/>
                </SideBarBlock>
            </ReverseRowBlock>
            <ReverseRowBlock>
                <div>
                    <h3>The Axis of 6ths</h3>
                    <p>Moving in 6ths and flat 3rds, we're moving along the same horizontal axis. This axis repeats after 4 keys. There are 3 cycles of 6ths, each with 4 keys. This allows us to represent all twelve keys as three rows of four, in any arrangement. </p>
                    <p>The intervals for the flat 3rd and 6th directions are 3 and 9 respectively.</p>
                    <p>The notes of the three cycles of sixths are, in no specific order:</p>
                    <ul>
                        <li>{useNoteName(Music.Eb)}, C, A {useNoteName(Music.Gb)}</li>
                        <li>G, E,{useNoteName(Music.Db)}, {useNoteName(Music.Bb)}</li>
                        <li>D, B, {useNoteName(Music.Ab)}, F</li>
                    </ul>
                </div>
                <SideBarBlock>
                    <MapBuilderAxisOfSixths/>
                </SideBarBlock>
                <SideBarBlock>
                    <MapBuilderAxisOfSixthsExpanded/>
                </SideBarBlock>
             </ReverseRowBlock>
            <ReverseRowBlock>
                <div className="leftChannel flip column">
                    <h3>The HexMap Compass</h3>
                    <p>Equipped with the information above, we can define all of a key's neighbors. Here's the full HexMap Compass, illustrating all six directional intervals.</p>
                    <p>Our compass intervals presumes upward pitch movements - moving up the scale. If we want to descend in pitch we use the opposite direction's interval. For example if we descend in pitch moving to the right we'd be descending by a flat 3rd, instead of ascending by a 6th. Note that our destination key is the same either way.</p>
                    <p>Generally, key changes aren't pitch-specific so we don't have to worry about this detail too much. However, when we transpose melodies from one key to another, that change is likely to be pitch-specific.</p>
                </div>
                <SideBarBlock title='The Full HexMap Compass'>
                    <HexKeyCompass/>
                </SideBarBlock>
            </ReverseRowBlock>
            <FullChannel>
                <div className=''>
                    <h3>Exercises</h3>
                    <p>There are four exercises related to this chapter.</p>
                    <ul>
                        <li><Link to='/HexKeyCompassMemorizer'>The HexKey Compass Memorizer</Link> will help you memorize the HexKey Compass.</li>
                        <li><Link to='/NeighborMemorizer'>The HexKey Neighbor Memorizer</Link> will help you memorize a key's neighbor keys.</li>
                        <li><Link to='/SimplifiedMapExercisePage'>The Simplified HexMap Builder Exercise</Link> will sharpen your Simplified HexMap skills.</li>
                        <li><Link to='/WanderingKeyExercise'>The Wandering Key Exercise</Link> will test your knowledge of the HexMap (advanced).</li>
                    </ul>
                </div>
            </FullChannel>
        </div>
    )
}

