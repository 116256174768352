import React from "react";
import { HKMapComponent } from "../HKMap/HKMapComponent";
import { HKMapData } from "../HKMap/HKMapData";
import { HKMapWrapperBase } from "../HKMap/HKMapWrapperBase";
import { HKMKeyData } from "../HKMap/HKMKeyData";
import { HKMNoteData } from "../HKMap/HKMNoteData";
export class MapBuildereState{
    visibleNotes:HKMNoteData[]
    visibleKeys:HKMKeyData[]
    completed:boolean = false
    constructor(visibleKeys:HKMKeyData[], visibleNotes:HKMNoteData[]){
        this.visibleKeys = visibleKeys
        this.visibleNotes = visibleNotes
    }
}
export interface MapBuilderBaseProps{
    tonic?:number
    selectedKeys?:number[][]
    openKeys?:number[][]
    selectedNotes?:number[]
    definedKeys?:number[][]
    hiddenKeys?:number[][]
    hilitedKeys?:number[][]
    rows?:number
    columns?:number
    radius?:number
    defineAllKeys?:boolean
    classNames?:string[]
}
export class MapBuilderBase extends React.Component{
    tonic:number = 0
    selectedNoteIndex:number = -1
    rows:number = 3
    columns:number = 5 
    radius:number = 45
    hiddenKeys:number[][] = [[0,0], [1,0], [2,4]]
    mapData:HKMapData
    state:MapBuildereState
    props:MapBuilderBaseProps
    defineAll?:boolean

    constructor(props:MapBuilderBaseProps){
        super(props)
        this.props = props
        if(props.tonic) this.tonic = props.tonic
        if(props.radius) this.radius = props.radius
        if(props.rows) this.rows = props.rows
        if(props.columns) this.columns = props.columns
        if(props.hiddenKeys) this.hiddenKeys = props.hiddenKeys
        this.mapData = this.getMapData()
        this.customizeData()
        this.state = this.getNewState()
    }
    onKeyClick = () => {}
    onNoteClick = () => {}
    getNewState = () => {
        return new MapBuildereState(this.getVisibleKeys(), this.getVisibleNotes())
    }
    customizeData = () => {}
    getMapData = () => {
        let mapData:HKMapData = new HKMapData(this.rows, this.columns, this.tonic, this.radius, this.onKeyClick, this.onNoteClick, this.radius/-2)
        mapData.hideAllNotes()
        if(this.props.hiddenKeys){ mapData.hideKeys(this.props.hiddenKeys) }
        //const defineAllKeys:boolean = !(this.props.selectedKeys.length > 0 && !this.props.defineAllKeys)
        //mapData.defineAllKeysAs(defineAllKeys)
        mapData.defineAllNotesAs(false)
        if(this.props.selectedKeys){mapData.setSelectedKeys(this.props.selectedKeys)}
        if(!this.props.defineAllKeys) mapData.defineAllKeysAs(false)
        if(this.props.definedKeys){
            this.props.definedKeys.forEach(
                (keyCoords:number[])=>{
                    mapData.keys[keyCoords[0]][keyCoords[1]].defined = true
                }
            )
        }
        if(this.props.hilitedKeys){
            this.props.hilitedKeys.forEach(
                (keyCoords:number[])=>{
                    mapData.keys[keyCoords[0]][keyCoords[1]].hilited = true
                }
            )
        }
        if(this.props.selectedNotes){mapData.selectNotes(this.props.selectedNotes)}
        if(this.props.openKeys){ 
            mapData.openKeys(this.props.openKeys) 
            this.props.openKeys.forEach(
                (keyCoords:number[])=>{
                    mapData.defineAllNotesForKey(keyCoords[0], keyCoords[1])
                }
            )
        }
        return mapData
    }
    getVisibleKeys = () => {return this.mapData.getVisibleKeys()}
    getVisibleNotes = () => {return this.mapData.getVisibleNotes()}
    isSingle = () => {
        return (this.props.rows === 1 && this.props.columns === 1)
    }
    getClassNames = () => {
        let classNames:string[] = ['map-builder-exercise']
        if(this.props.classNames){ classNames = classNames.concat(this.props.classNames)}
        return classNames.join(' ')
    }
    render = () =>{
        
        const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(this.radius, this.rows, this.columns)

        return <div className={this.getClassNames()}>
                    <HKMapComponent compWidth={compWidth} 
                                compHeight={compHeight} 
                                visibleKeys={this.state.visibleKeys} 
                                visibleNotes={this.state.visibleNotes}/>
            </div>
    }     
}
