import { useState } from "react"
import { HKMapComponent } from "./HKMapComponent"
import { HKMapData } from "./HKMapData"
import { HKMapWrapperBase } from "./HKMapWrapperBase"
import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteData } from "./HKMNoteData"

export interface SetTonic{
    tonic:number
    radius?:number
}
export function HKMSingleKeySetTonic(props:SetTonic){
    const rows:number = 1
    const columns:number = 1 
    const radius:number = (props.radius) ? props.radius : 80
    const onKeyClick = (key:HKMKeyData) => {}
    const onNoteClick = (note:HKMNoteData) => {}
    const getMapData = () => {
        return new HKMapData(rows, columns, props.tonic, radius, onKeyClick, onNoteClick)
    }
    const [compWidth, compHeight] = HKMapWrapperBase.getCompDimensions(radius, rows, columns)
    const mapData:HKMapData = getMapData()
    return <HKMapComponent  compWidth={compWidth} 
                            compHeight={compHeight} 
                            visibleKeys={mapData.getVisibleKeys()} 
                            visibleNotes={mapData.getVisibleNotes()}/>
}