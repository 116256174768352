import { useState } from "react";
import { Music } from "../../Music/Music";
import { GiantStepsKeyChangeMap, GiantStepsKeyChangeMapProps as Progression } from "./GiantStepsKeyChangeMap";
import gsap from 'gsap'
import { PositionInKeyState } from "../../PositionInKey";
import React from "react";

export class GiantStepsAnimatorState{
    keyNote:number
    position:number
    keyIndex:number
    paused:boolean
    constructor(keyNote:number, index:number, position:number){
        this.keyNote = keyNote
        this.position = position
        this.keyIndex = index
        this.paused = true
    }
}

export class GiantStepsAnimator extends React.Component{
    static keyProgressions:Progression[] = [ 
        {keyNote:Music.B, index:2, positions:[1]},
        {keyNote:Music.G, index:1, positions:[5,1]},
        {keyNote:Music.Eb, index:1, positions:[5,1]},
        {keyNote:Music.G, index:1, positions:[2,5,1]},
        {keyNote:Music.Eb, index:1, positions:[5,1]},
        {keyNote:Music.B, index:1, positions:[5,1]},
        {keyNote:Music.Eb, index:1, positions:[2,5,1]},
        {keyNote:Music.G, index:1, positions:[2,5,1]},
        {keyNote:Music.B, index:2, positions:[2,5,1]},
        {keyNote:Music.Eb, index:2, positions:[2,5,1]},
        {keyNote:Music.B, index:2, positions:[2,5]}
    ]
    state:GiantStepsAnimatorState
    timeline:gsap.core.Timeline
    paused:boolean = true
    constructor(props:any){
        super(props)
        this.state = new GiantStepsAnimatorState(-1, -1, -1)
        this.timeline = this.buildTimeline()
    } 
    buildIndividualSteps = () => {
        let progressionSteps:Progression[] = []
        GiantStepsAnimator.keyProgressions.forEach((progression:Progression) => {
            const key:number = progression.keyNote
            progression.positions.forEach((position:number) => {
                let index:number|undefined = progression.index
                if(index === undefined) index = 1
                progressionSteps.push({keyNote:key, index:progression.index, positions:[position]})
            })

        })
        return progressionSteps
    }

    onTimelineComplete =() => {
        this.timeline.seek(0)
        this.pause()
    }
    showStep = (nextKeyNote:number, index:number, nextPosition:number) => {
        this.setState(new GiantStepsAnimatorState(nextKeyNote, index, nextPosition))
    }
    buildTimeline = () => {
        let tl = gsap.timeline({onComplete: this.onTimelineComplete, paused:true});
        const steps:Progression[] = this.buildIndividualSteps()
        steps.forEach((progression:Progression, index:number) => {
            const cueTime:number = index * 2
            const position:number = progression.positions[0]
            tl.add( tl.call(this.showStep, [progression.keyNote, progression.index, position], cueTime) );
        })
        return tl
    }
    getMap = () => {
            return <GiantStepsKeyChangeMap 
                    keyNote={this.state.keyNote} 
                    index={this.state.keyIndex}
                    positions={[this.state.position]}
                    showPositions={true}/>
    }
    onMapClick = (e:React.MouseEvent) => {
        e.stopPropagation()
        const action:Function = (this.paused) ? this.play : this.pause
        action()
    }
    setPaused = (b:boolean) => {
        this.paused = b
        const label:string = (this.paused) ? 'play' : 'pause'
        const labelDiv = document.getElementById('giant-steps-play-botton')
        if(labelDiv){
            labelDiv.innerHTML = label
        }
    }
    play = () => {
        this.timeline.play()
        this.setPaused(false)   
    }
    pause = () => {
        this.timeline.pause()
        this.setPaused(true)
    }

    render(): React.ReactNode {
        return  <GiantStepsRow>
                     <GiantStepsColumn>
                        {this.getMap()}
                        <div className='centered'>
                            <button id='giant-steps-play-botton' 
                                    className='centered' 
                                    onClick={this.onMapClick}>play</button>
                        </div>
                        </GiantStepsColumn>
                </GiantStepsRow>    
    }

}

export function GiantStepsColumn(props:GiantStepsColumnsProps){
    return <div className='giant-steps-column'>
                {props.children}
            </div>
}

export interface GiantStepsColumnsProps{
    children:React.ReactNode
}

export interface GiantStepsRowProps{
    children:React.ReactNode
}

export function GiantStepsRow(props:GiantStepsRowProps){
        return  <div className='row giant-steps'>
                    {props.children}
                </div>
}