import { CourseState } from "../../Course/CourseState";
import { useCourseStore } from "../../Course/CourseStore";
export interface SequenceButtonsProps{
    onClick:Function
}
export function SequenceButtons(props:SequenceButtonsProps){
        let firstRowButtons:JSX.Element[] = []
        const firstRow:number[] = [0,1,2,3,4,5,6]
        firstRow.forEach((position:number) => {
          let b:JSX.Element = <SequenceButton position={position} onClick={props.onClick} key={position}/>
          firstRowButtons.push(b)
        })
        const accidental:number = useCourseStore((state:CourseState)=>{
          return state.accidental
        })
        const accidentalClassName:string = (accidental === 0) ? 'flat' : 'sharp'
        const className:string = 'note-buttons ' + accidentalClassName
        return  <div className={className}>
                  <div className='first-row'>{firstRowButtons}</div>
                </div>
}
export interface SequenceButtonProps{
  position:number
  onClick:Function
}

export function SequenceButton(props:SequenceButtonProps){
  return  <button className="sequence-button" onClick={(e:React.MouseEvent)=>{e.stopPropagation(); props.onClick(props.position)}} type="button">
            {props.position + 1}
          </button>
}