import React from "react";
import { CourseState, LabelTypes } from "../Course/CourseState";
import { useCourseStore } from "../Course/CourseStore";
import { AccidentalSplit, SVGAccidentalSplit, useNoteName } from "../Music/Accidentals/Accidentals";
import { HKMNoteData } from "./HKMNoteData";
// origin keys notename
export interface HKMNoteCompProps{
    noteData:HKMNoteData
}

export class HKMNoteComp extends React.Component{
    props!:HKMNoteCompProps
    static circleToRadiusRatio:number = 1/3.15
    constructor(props:HKMNoteCompProps){
        super(props)
    }
    getTransform = () => {
        return 'translate(' + this.props.noteData.x + ' ' + this.props.noteData.y + ')'
    }
    componentDidMount(){}
    componentDidUpdate(){}

    getCircleClassName = () => {
        let classes:string[] = ['HKM-note-circle']
        if (this.props.noteData.selected) classes.push('selected')
        if (this.props.noteData.hilited) classes.push('hilited')
        return classes.join(' ')
    }
    onClick = (e:React.MouseEvent) => {
        e.stopPropagation()
        this.props.noteData.onNoteClick(this.props.noteData)
    }
    getCircleRadius = () => {
        let radius:number = this.props.noteData.keyRadius * HKMNoteComp.circleToRadiusRatio
        return radius
    }
    onMouseOver = (event:React.MouseEvent) => {
        event.stopPropagation()
    }
    render(){
        return  <g className='HKM-note' onMouseOver={this.onMouseOver}>
                    <g transform={this.getTransform()}>
                        <circle onClick={this.onClick} className={this.getCircleClassName()} r={this.getCircleRadius()} cy={0} cx={0}/>
                        <FieldNoteCompLabel {...this.props.noteData}/>
                    </g>
                </g>
    }
}
export function FieldNoteCompLabel(props:HKMNoteData){
    const noteName:string = useNoteName(props.tonic)
    const lableType:number = useCourseStore((state:CourseState)=>{
        return state.labelType
    })
    const getFontSize = () => {
        const minimumSize:number = 18
        const circleRadius = props.keyRadius * HKMNoteComp.circleToRadiusRatio
        let fontSize:number = circleRadius * .85
        if(fontSize < minimumSize) fontSize = minimumSize
        return fontSize
    }
    const getPositionOfNote = (note:HKMNoteData) => {
        return('x')
        }    
    const getLabel = function(){
        if(!props.defined){return ''}
        if(props.presetLabel !== null){
            return <text>{props.presetLabel}</text>
        }
        switch(lableType) { 
            case LabelTypes.byNoteName: { 
               return <SVGAccidentalSplit note={props.tonic}/>
            } 
            case LabelTypes.byNotePosition: { 
                const position:number | undefined = props.getSelectedKeyPosition()
               const notePosition:string = ((position !== undefined) ? String(position) : 'x' ) 
               return<text>{notePosition}</text>
               break
            }  
            case LabelTypes.byNoteIndex: { 
               return <text>{String(props.noteIndex)}</text>
               break
            } 
            default: { 
                return <SVGAccidentalSplit note={props.tonic}/>  
            } 
         } 
    }
    const getLabelTranslate = () => {
        const y:number = getFontSize()/3.5
        return 'translate(0,' + y + ')'
    }
    
    let className:string = "HKM-note-name hkmnotecomp"
    return  <g transform={getLabelTranslate()} fontSize={getFontSize()} 
                    className={className}>
                        {getLabel()} 
            </g>
}

