import { MapBuilderKeyDirections } from "../../MapBuilderWalkThrough/MapBuilderKeyDirections";
import './HexKeyCompass.css'
export function HexKeyCompass(){
    return <MapBuilderKeyDirections tonic={0} 
                classNames={['key-compass']}
                radius={35}
                selectedKeys={[[2,1],[2,2], [0,2],[0,1],[1,1],[1,3]]} 
                openKeys={[]} 
                selectedNotes={[]}
                definedKeys={[[2,1],[2,2],[0,2],[0,1],[1,1],[1,3]]}/>
}