import './HKMap.css'
import { useEffect, useState } from "react"
import { HKMKeyComponent } from "./HKMKeyComponent"
import { HKMKeyData } from "./HKMKeyData"
import { HKMNoteComp } from "./HKMNoteComp"
import { HKMNoteData } from "./HKMNoteData"
import { HKMapComponentProps } from './HKMapComponentProps'


export function HKMapChordHelperComponent(props:HKMapComponentProps){
    let [keys, setKeys] = useState(props.visibleKeys)
    let [notes, setNotes] = useState(props.visibleNotes)
    let keyComps = keys.map((key, index)=>{
        return <HKMKeyComponent key={index} keyData={key}/>
    })
    let noteComps = notes.map((note, index)=>{
        return <HKMNoteComp key={index} noteData={note}/>
    })
    useEffect(()=>{
        setKeys(props.visibleKeys)
        setNotes(props.visibleNotes)
    })
    const getLine = () => {
        const radius:number = keys[0].radius
        const h:number = 4
        const w:number = radius * 2 * .866
        const xTransform:number = (radius * 2 * .866) - (h * 2)
        const yTransform:number = radius * .59
        const transform:string = 'translate(' + xTransform + ', ' + yTransform + ') rotate(120)'
        return <rect className='dividing-line' height={h} width={w} transform={transform}/>
    }
    const getMajorLabel = () => {
        const radius:number = keys[0].radius
        const xTransform:number = radius * -.75
        const yTransform:number = radius * .4
        const transform:string = 'translate(' + xTransform + ', ' + yTransform + ')'
             
        return <text className='chord-label major-label' transform={transform}>Majors</text>
    }
    const getMinorLabel = () => {
        const radius:number = keys[0].radius
        const xTransform:number = radius * 2
        const yTransform:number = radius * 2.4
        const transform:string = 'translate(' + xTransform + ', ' + yTransform + ')'
        return <text className='chord-label minor-label' transform={transform}>Minors</text>
    }
    const getDiminishedLabel = () => {
        const radius:number = keys[0].radius
        const xTransform:number = radius * 1.5
        const yTransform:number = radius * 1.3
        const transform:string = 'translate(' + xTransform + ', ' + yTransform + ')'
        return <text className='chord-label dim-label' transform={transform}> - Half-Diminished</text>
    }
    return <svg className='hkmap' width={props.compWidth} height={props.compHeight}>
        {keyComps}
        {getLine()}
        {noteComps}
        {getMajorLabel()}
        {getMinorLabel()}
    </svg>
}