import { useState } from "react"
import { HKMSingleKeySetTonic } from "../../HKMap/HKMSingleKeySetTonic"
import { NotesSelect } from "../KeyBuilderExercise/NotesSelect"

export interface HexKeyMemorizerHelpersProps{
    tonic:number
}

export function HexKeyMemorizerHelpers(props:HexKeyMemorizerHelpersProps){

    const [showHelpers, setShowHelpers] = useState(true)
    const getHideShowButton = () => {
        return  <p>
                    <button onClick={((e:React.MouseEvent)=>{e.stopPropagation(); setShowHelpers(!showHelpers)})} type="button">Hide/Show Helper Pattern</button>
                </p>
    }
    const getHelpers = () => {
        if(!showHelpers){
            return null
        }
        return  <div>
                    <HKMSingleKeySetTonic radius={60} tonic={props.tonic}/>
                </div>
    }
    return  <div>
                {getHideShowButton()}
                {getHelpers()}
            </div>
}