import { useState } from "react"
import { HexKeyCompass } from "../../Helpers/HexKeyCompass/HexKeyCompass"
import { HKMSingleKeySetTonic } from "../../HKMap/HKMSingleKeySetTonic"
import { MapBuilderKeyDirections } from "../../MapBuilderWalkThrough/MapBuilderKeyDirections"
import { NotesSelect } from "../KeyBuilderExercise/NotesSelect"

export function SimpleMapExerciseHelpers(){
    const [tonic, setTonic] = useState(0)
    const [show, setShow] = useState(true)
    const onChange = (note:number) => {
        setTonic(note)
    }
    const getHelpers = () => {
        if(!show) return null
        return  <div className='centered'>
                    <HexKeyCompass/>
                    <NotesSelect tonic={tonic} onChange={((note:string)=>{onChange(parseInt(note))})}/>
                    <HKMSingleKeySetTonic radius={60} tonic={tonic}/>
                </div>
    }
    const toggleHelpers = (e:React.MouseEvent) => {
        e.stopPropagation()
        setShow(!show)
    }
    const getToggleLabel = () => {
        return (show) ? 'hide' : 'show'
    }
    return  <div className='simple-map-helpers centered'>
                <button type='button' onClick={toggleHelpers}>{getToggleLabel()} helper patterns</button>
                {getHelpers()}
            </div>
        
        
}